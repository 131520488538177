import React from 'react'
import { Form, Modal, Button, Alert } from 'react-bootstrap'

function AbsencePopup({ 
  error,
  loading,
  HandleSubmit,
  setEnd,
  setNotes,
  setStart,
  show,
  setShow,
  status
}) {
  return (
    <Modal show={show} onHide={setShow}>
        <Modal.Header closeButton>
            <h3>Excused Absence Form</h3>
        </Modal.Header>
        <Modal.Body>
          {!status?
          <>
            <Form.Label>Start date<span style={{color: 'red'}}>*</span></Form.Label>
            <Form.Control onChange={(e)=>setStart(e.target.value)} type='date' />

            <Form.Label>End Date<span style={{color: 'red'}}>*</span></Form.Label>
            <Form.Control onChange={(e)=>setEnd(e.target.value)} type='date' />

            <Form.Label>Notes</Form.Label>
            <Form.Control className='margin-bottom' onChange={(e)=>setNotes(e.target.value)} placeholder='On vacation' />
            
            {error&&<Alert variant='warning'>{error}</Alert>}
          </>
          :
          (status===200)&&<Alert>Absence successfully posted!</Alert>
          }

        </Modal.Body>
        <Modal.Footer>
            <Button variant='secondary' onClick={(e)=>setShow(false)}>Close</Button>
            {!status&&
            <Button onClick={()=>HandleSubmit()}>{loading?'Submitting...':'Submit'}</Button>
            }
        </Modal.Footer>
    </Modal>
  )
}

export default AbsencePopup