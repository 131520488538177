import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import CustomerContext from '../../Context/CustomerContext';
import useFetchGet from '../../Fetch/useFetchGet';
import ReactSelect from 'react-select';

function Vendor({
    setShow, 
    selectedContact, 
    setSelectedContact,
    successMessage,
    setSuccessMessage
}) {
    const [selectedVendor, setSelectedVendor] = useState();
    const [getVendorAudit, setGetVendorAudit] = useState(false);
    const { REACT_APP_API_URL } = process.env;
    const { cookies } = useContext(CustomerContext)
    const { data: vendors, loading: vendorsLoading } = useFetchGet(`${REACT_APP_API_URL}/VMS/GetVendors`, null, true)
    const { data: vendorContacts, loading: vendorContactsLoading } = useFetchGet((selectedVendor&&`${REACT_APP_API_URL}/Utilities/GetVendorContacts`), {VendorID: selectedVendor&&selectedVendor.value.CompanyIdent}, true)
    const { data: newVendorAudit, loading: loadingVendorAudit, error: vendorError, setError: setVendorError, status: vendorAuditStatus } = useFetchGet((getVendorAudit&&`${REACT_APP_API_URL}/Utilities/RunAsVendor`),{'ContactID':selectedContact&&selectedContact.value.ContactID},true)

    useEffect(()=>{
        if(newVendorAudit&&!vendorError){
            if(vendorAuditStatus===204){
                setVendorError('Selected Contact is not a Web User, please select a different contact');
                setSelectedContact(null);
                setGetVendorAudit(false);
            }else{
                setSuccessMessage(true)
                cookies.remove('department')
                cookies.remove('worksites')
                cookies.set('customer', newVendorAudit, {path:'/'})  
                window.location.reload();
            }
        }
        // eslint-disable-next-line
    },[newVendorAudit])

    const handleContactChange = (e) => { 
        setSelectedContact(e)
        setVendorError(null)
     }

    return(
        <>
        <Modal.Body>
            <Form.Label>Vendor: {vendorsLoading&&<Spinner animation='border' size='sm' />}</Form.Label>
            {vendors&&
                <ReactSelect value={selectedVendor} options={vendors.map(vendor=>{return {value:vendor,label:vendor.FullCompanyName}})} onChange={(e)=>setSelectedVendor(e)} />
            }

            <Form.Label>Contact: {vendorContactsLoading&&<Spinner animation='border' size='sm' />}</Form.Label>
            {vendorContacts?
                <ReactSelect 
                    isDisabled={(selectedVendor&&!vendorContactsLoading)?false:true} 
                    value={selectedContact} options={vendorContacts.map(contact=>{
                        if(contact.IsUser){
                            return{value:contact,label:(contact.FirstName+' '+contact.LastName)}
                        }else{
                            return{value:contact,label:(contact.FirstName+' '+contact.LastName+' | NOTWEB'), isDisabled:true}
                        }
                    })} 
                    onChange={(e)=>handleContactChange(e)} 
                />
            :
                <ReactSelect disabled />
            }
        </Modal.Body>
        <Modal.Footer>
        {successMessage?
                <Alert>Reloading page to finalize impersonation, if page does not reload please refresh your web browser</Alert>
                :
                <Row className='full-width'>       
                    <Col>
                        <Button className='full-width' variant="secondary" onClick={()=>setShow(false)}>
                            Close
                        </Button>
                    </Col>
                    <Col>
                    {(selectedContact&&!vendorError&&(loadingVendorAudit===false))&&
                        <Button className='full-width' variant="primary" onClick={()=>setGetVendorAudit(true)}>
                            Impersonate {selectedContact?selectedContact.value.FirstName:''}
                        </Button>
                    }
                    {loadingVendorAudit&&
                        <Alert className='full-width' >Setting up impersonation <Spinner animation='border' size='sm'/></Alert>
                    }
                    {vendorError&&
                        <Alert variant='warning'>{vendorError}</Alert>
                    }
                    </Col>
                </Row>
            }
        </Modal.Footer>
        </>
    )
}

export default Vendor