import React, { useContext, useEffect, useState } from 'react'
import CustomerContext from '../../Context/CustomerContext'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import '../CustomComponents/Tabs/Tabs.css'
import WeeklyHeadCount from '../CustomComponents/Tabs/WeeklyTab'
import MonthlyHeadCount from '../CustomComponents/Tabs/MonthlyTab'
import QuarterlyHeadCount from '../CustomComponents/Tabs/QuarterlyTab'
import DataTab from '../CustomComponents/Tabs/DataTab'

function HeadcountDashboard() {
    const { REACT_APP_API_URL } = process.env;
    const { setCurrentPage } = useContext(CustomerContext)
    const [ selectedTab, setSelectedTab ] = useState('Weekly')
    const [ tabApiUrl ] = useState(`${REACT_APP_API_URL}/Reports/GetHeadCountActivityEmployeeLevel`);
    const [ tabRangeCharSeries ] = useState(['DateLabel','Active','Starts','Ends','StartEnds'])
    const [reportDescription] = useState(<div><p>This report is displayed as a column graph.</p><p> The left or 'y' axis shows the weekending dates selected. The bottom or 'x' axis displays the total number of employees.</p><p> This report shows you the number of employees who started, ended, started and ended (both in the same week), or are still on their assignment for each week .</p></div>)
    
    const roundedTotal = (params) =>{
        let total = 0;
        params.values.forEach(value => {
            if(value){
                total += value
            }
        });
        total = total.toFixed(2)
        return Number(total);
    }

    const [columnDefs] = useState([
        { field: 'DateLabel', headerName:'Date', rowGroup:true,  chartDataType: 'category' },
        { field: 'Active',chartDataType:'series', aggFunc: roundedTotal},
        { field: 'Starts',chartDataType:'series', aggFunc: roundedTotal},
        { field: 'Ends',chartDataType:'series', aggFunc: roundedTotal},
        { field: 'StartEnds',headerName:'Start&End',chartDataType:'series', aggFunc: roundedTotal},
        { field: 'EmployeeName', headerName:'Employee'},
        { field: 'EmployeeId', headerName: 'Employee ID', chartDataType: 'category', filter: 'agSetColumnFilter' },
        { field: 'AlternateID', chartDataType: 'category', filter: 'agSetColumnFilter' },
        { field: 'Status', chartDataType: 'category'},
        { field: 'Shift', chartDataType: 'category' },
        { field: 'VendorName', filter: 'agSetColumnFilter', chartDataType: 'category'},
        { field: 'Worksite',filter: 'agSetColumnFilter', chartDataType: 'category'},
        { field: 'OrderType',filter: 'agSetColumnFilter',chartDataType:'category'},
        { field: 'Department',filter: 'agSetColumnFilter',chartDataType:'category'},
        { field: 'Supervisor',chartDataType:'category'},

    ])

    useEffect(()=>{
        setCurrentPage('Headcount')
    },[setCurrentPage])

  return (
    <div>
        
        <Tabs
            activeKey={selectedTab}
            onSelect={(tab)=>setSelectedTab(tab)}
        >
        <Tab eventKey="Weekly" title="Weekly">
            {selectedTab===('Weekly')&&
                <WeeklyHeadCount columnDefs={columnDefs} apiUrl={tabApiUrl} rangeChartSeries={tabRangeCharSeries} chartTitle='Weekly Headcount' reportDescription={reportDescription} />
            }
        </Tab>
        <Tab eventKey="Monthly" title="Monthly">
            {selectedTab===('Monthly')&&
                <MonthlyHeadCount columnDefs={columnDefs} apiUrl={tabApiUrl} rangeChartSeries={tabRangeCharSeries} chartTitle='Monthly Headcount' reportDescription={reportDescription} />
            }
        </Tab>
        <Tab eventKey="Quarterly" title="Quarterly">
            {selectedTab===('Quarterly')&&
                <QuarterlyHeadCount columnDefs={columnDefs} apiUrl={tabApiUrl} rangeChartSeries={tabRangeCharSeries} chartTitle='Quarterly Headcount' reportDescription={reportDescription} />
            }
        </Tab>
        <Tab eventKey="Data" title="Data">
            {selectedTab==='Data'&&
                <DataTab columnDefs={columnDefs} apiUrl={tabApiUrl} chartTitle='Headcount' reportDescription={reportDescription} />
            }
        </Tab>
        </Tabs>
    </div>
  )
}

export default HeadcountDashboard