import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Modal from 'react-bootstrap/Modal';
import useFetchGet from '../../../Fetch/useFetchGet';
import Candidates from './Candidates';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import Spinner from 'react-bootstrap/Spinner';
import './Popup.css';
import Employees from './Employees';
import ApproveOrderButton from './ApproveOrderButton';

function Popup({ 
    show, 
    setShow,
    orderInfo, 
    isVMSCustomer, 
    isVendor 
    }) {
    const [activeKey, setActiveKey] = useState("JobInfo");
    const [reviewCount, setReviewCount] = useState();
    const { REACT_APP_API_URL } = process.env;
    const { data: orderDetail } = useFetchGet(`${REACT_APP_API_URL}/VMS/GetOrderDetails`,{OrderID: orderInfo.OrderID})
    let USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    useEffect(()=>{
        if(orderDetail&&(orderDetail.candidates[0]!==null)){
            let count = 0;
            orderDetail.candidates.forEach(candidate=>{
                if(candidate.NeedsReviewByUser){
                    count++;
                }
            })
            setReviewCount(count)
        }
    },[orderDetail])

  return (
    <Modal show={show} onHide={setShow} size='xl'>
        <Modal.Header closeButton>
            <h3>Order Detail {orderDetail&&('| '+orderDetail.detail.JobTitle+' | '+orderDetail.detail.OrderID)}</h3>
        </Modal.Header>
        <Modal.Body>
            <Accordion activeKey={activeKey} onSelect={(key)=>setActiveKey(key)}>
                <Accordion.Item eventKey="JobInfo">
                    <Accordion.Header>Job Info</Accordion.Header>
                    <Accordion.Body className='job-info-accordion'>
                        {orderDetail?
                        <>
                            <h3>{orderDetail.detail.JobTitle} | {orderDetail.detail.OrderID}</h3>
                            <h6 className='sub-header'>{orderDetail.detail.CustomerName+' | '+orderDetail.detail.DepartmentName+' | '+orderDetail.detail.Worksite}</h6>
                            <Row>
                                <Col><p><b>Shift:</b></p> <p>{orderDetail.detail.Shift}</p></Col>
                                <Col><p><b>Date Created:</b></p> <p>{orderDetail.detail.OrderCreateDate&&orderDetail.detail.OrderCreateDate.split('T')[0]}</p></Col>
                                <Col><p><b>Order Type:</b></p> <p>{orderDetail.detail.OrderType}</p></Col>
                            </Row>
                            <Row>
                                <Col><p><b>Duration:</b></p> <p>{orderDetail.detail.Duration}</p></Col>
                                <Col><p><b>Start Date:</b></p> <p>{orderDetail.detail.StartDate&&orderDetail.detail.StartDate.split('T')[0]}</p></Col>
                                <Col><p><b>Est End Date:</b></p> <p>{orderDetail.detail.EstimatedEndDate&&orderDetail.detail.EstimatedEndDate.split('T')[0]}</p></Col>
                            </Row>
                            <Row>
                                {(isVMSCustomer||isVendor)?
                                <Col><p><b>Vendor Rate:</b></p> <p>{USDollar.format(orderDetail.detail.VendorPayRate)}</p></Col>
                                :<Col></Col>}
                                
                                {!isVendor?
                                <Col><p><b>Customer Rate:</b></p> <p>{USDollar.format(orderDetail.detail.BillRate)}</p></Col>
                                :<Col></Col>}
    
                                <Col><p><b>Employee Rate:</b></p> <p>{USDollar.format(orderDetail.detail.EmployeePayRate)}</p></Col>
                            </Row>
                            <Row>
                                <Col><p><b>Start Time:</b></p> <p>{orderDetail.detail.StartTime}</p></Col>
                                <Col><p><b>End Time:</b></p> <p>{orderDetail.detail.EndTime}</p></Col>
                                <Col><p><b>Safety Notes:</b></p> <p>{orderDetail.detail.SafetyNotes}</p></Col>
                            </Row>
                            <Row>
                                <Col><p><b>Candidates Submitted:</b></p> <p>{orderDetail.detail.Submitted}</p></Col>
                                <Col><p><b>Assigned:</b></p> <p>{orderDetail.detail.Filled}</p></Col>
                                <Col><p><b>Candidates Required:</b></p> <p>{orderDetail.detail.Required}</p></Col>
                            </Row>
                            <Row>
                                <Col><p><b>Dress Code:</b></p> <p>{orderDetail.detail.DressCode}</p></Col>
                                <Col><p><b>Job Description:</b></p> <p>{orderDetail.detail.JobDescription}</p></Col>
                            </Row>
                            {orderInfo.NeedsApprovalByUser&&
                                <ApproveOrderButton OrderID={orderInfo.OrderID} />
                            }
                        </>
                        :
                            <Spinner animation='border' />
                        }
                        </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="Employees">
                    <Accordion.Header>Employees</Accordion.Header>
                    <Accordion.Body>
                        {(orderDetail&&orderDetail.employees[0])?
                            <Employees employees={orderDetail.employees} />
                        :
                            <Alert>No Employees on this Order</Alert>
                        }
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="Candidates">
                    <Accordion.Header>Candidates {reviewCount?<Badge bg='danger' className='candidates-badge'>{reviewCount}</Badge>:''}</Accordion.Header>
                    <Accordion.Body>
                        {(orderDetail&&orderDetail.candidates[0])?
                            <Candidates candidates={orderDetail.candidates} />
                        :
                            <Alert>No Candidates on this Order</Alert>
                        }
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </Modal.Body>
    </Modal>
  )
}

export default Popup