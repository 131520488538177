import { useState } from 'react'
import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import useFetchGet from '../../../Fetch/useFetchGet';
import UploadDocuments from './UploadDocuments';

function Documents({ employeeID }) {
    const {REACT_APP_API_URL} = process.env;
    const { data: documents, loading: loadingDocuments, error } = useFetchGet(`${REACT_APP_API_URL}/VMS/GetEmployeeDocumentList`, {EmployeeID:employeeID})
    const [showUploadDocumentPopup,setShowUploadDocumentPopup] = useState(false);

  return (
    <div className='vh20'>
        {showUploadDocumentPopup&&<UploadDocuments show={showUploadDocumentPopup} setShow={setShowUploadDocumentPopup} employeeID={employeeID} />}
            
        <Button className='margin-bottom' onClick={()=>setShowUploadDocumentPopup(true)}>Upload Documents</Button>

        {loadingDocuments&&
            <Spinner animation='border' />
        }

        {(documents&&documents.length>0)?
            documents.map(document=>{
                return <Card className='margin-bottom'>
                            <Card.Body>
                                <Row><Col><p><b>Document:</b></p> <p>{document.DocName}</p></Col><Col><p><b>Description:</b></p> <p>{document.DocDescription}</p></Col><Col><p><b>Document Date:</b></p> <p>{document.DocumentDate&&document.DocumentDate.split('T')[0]}</p></Col><Col><p><b>Expiration Date:</b></p> <p>{document.ExpirationDate&&document.ExpirationDate.split('T')[0]}</p></Col></Row>
                            </Card.Body>
                        </Card>
            })
        :
        error?
        <Alert variant='warning'>
            <p>There was an issue getting data, please try again</p>
            <p>{error}</p>
        </Alert>
        :
        <Alert>No document history</Alert>
        }
    </div>
  )
}

export default Documents