import React from 'react';
import { Form } from 'react-bootstrap';
import ReqSelect from '../ReqSelect/ReqSelect';
import CustomerTreeDropdown from './CustomerTreeDropdown/CustomerTreeDropdown';

function Department({ 
  onMenuOpen,
  customerID,
  HandleCustomerDepartmentChange, 
  formData,
  cookies,
  selectedDepartment,
  HandlePOChange,
  selectedPO,
  defaultFormData,
}) {
  const {REACT_APP_API_URL} = process.env;

  return (
      <>
      <Form.Label>Customer/Department<span>*</span></Form.Label>
      {(selectedDepartment&&defaultFormData)?
        <CustomerTreeDropdown onMenuOpen={()=>onMenuOpen('Department')} defaultValue={{value:selectedDepartment,label:selectedDepartment.DepartmentName}} onChange={(e)=>HandleCustomerDepartmentChange(e)} customerID={customerID} />
        :
        <CustomerTreeDropdown onMenuOpen={()=>onMenuOpen('Department')} onChange={(e)=>HandleCustomerDepartmentChange(e)} customerID={customerID} />
      }
      {selectedDepartment&&
        <ReqSelect defaultOption={defaultFormData&&defaultFormData.PO} HandleChange={(e)=>HandlePOChange(e)} selectLabel='POs' labelName='PONumber' apiUrl={`${REACT_APP_API_URL}/OrderRequest/getcustomerpo?token=${cookies.get('customer').runAsCustomer?cookies.get('customer').runAsCustomer.Token:cookies.get('customer').Token}&customerid=${selectedDepartment.CustomerID}`} />
      }
      {selectedPO&&
      <>
      <Form.Label>PO Value</Form.Label>
      <Form.Control disabled value={selectedPO.POValue?selectedPO.POValue:'None'}></Form.Control>

      <Form.Label>PO Amount Used</Form.Label>
      <Form.Control disabled value={formData.PO.AmountUsed?formData.PO.AmountUsed:'0'}></Form.Control>

      <Form.Label>PO End Date</Form.Label>
      <Form.Control disabled value={selectedPO.POEndDate?selectedPO.POEndDate:'No End Date'}></Form.Control>
      </>
      }
      </>
  )
}

export default Department