import React, { useContext, useState } from 'react'
import Department from './Department'
import CustomerContext from '../../../../../Context/CustomerContext';

function DepartmentLogic({ selectedDepartment, setSelectedDepartment, defaultFormData }) {
  const [selectedPO, setSelectedPO] = useState();
  const {
    cookies,
    formData, 
    setFormData
  } = useContext(CustomerContext)
  const [customerID, setCustomerID] = useState((cookies.get('customer').runAsCustomer?cookies.get('customer').runAsCustomer.CustomerId:cookies.get('customer').CustomerID));

  const HandlePOChange = (event) => {
      setSelectedPO(event.value) 
      formData.PO = event.value;
      setFormData(formData);
   }

   const HandleCustomerDepartmentChange = (event) => { 
      formData.Department = event.value;
      setFormData(formData);
      setSelectedDepartment(event.value);
    }

  const onMenuOpen = (e) => { 
    if(e==='Branches'){
      setCustomerID(null)
      setSelectedDepartment(null)
    }else{
      setSelectedDepartment(null)
    }
   }

  const handleBranchesChange = (e) => {
    setCustomerID(e.value.CustomerID)
 }
  
    
  return (
    <Department
      customerID={customerID}
      handleBranchesChange={handleBranchesChange}
      selectedDepartment={selectedDepartment}
      formData={formData}
      cookies={cookies}
      HandlePOChange={HandlePOChange}
      HandleCustomerDepartmentChange={HandleCustomerDepartmentChange}
      selectedPO={selectedPO}
      onMenuOpen={onMenuOpen}
      defaultFormData={defaultFormData}
    ></Department>
  )
}

export default DepartmentLogic