import { useState, useEffect, useContext } from "react";
import PropTypes from 'prop-types';
import CustomerContext from "../Context/CustomerContext";
import isEqual from 'lodash/isEqual'

const useFetchGet = (baseUrl, params, runAsUser, refreshTime) => {
    const { cookies } = useContext(CustomerContext)
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [status, setStatus] = useState();
    const [statefulParams, setStatefulParams] = useState(params)
    const abortController = new AbortController();

    const BuildURLAndFetch = () => { 
      let token = ''
      let stringifiedParams = ''
      if(cookies.get('customer')){
        if(!cookies.get('customer').runAsCustomer||runAsUser){
          token = `?Token=${cookies.get('customer').Token}`
        }else{
          token = `?Token=${cookies.get('customer').runAsCustomer.Token}`
        }
      }else{
        window.location.reload()
      }
      if(params){
        // change from key/value to an object with the key being the key and value being the value
        // turn that object into an array
        // iterate over the array using a map
        for (const [key, value] of Object.entries(params)) {
          stringifiedParams += (`&${key}=${value}`);
        }
      }
      fetchData((baseUrl+token+stringifiedParams));
     }

    useEffect(()=>{
      if(!isEqual(statefulParams,params)){
        setStatefulParams({...params})
      }
      //eslint-disable-next-line
    },[params])

    useEffect(() => {
      if(baseUrl&&isEqual(statefulParams,params)){
        BuildURLAndFetch()
      }

      return ()=>{
        abortController.abort();
      } 
   // eslint-disable-next-line
    }, [baseUrl,statefulParams]);

    useEffect(()=>{
        if(refreshTime){
            const timer = setTimeout(() => {
              BuildURLAndFetch()
            }, refreshTime);
            return () => clearTimeout(timer);
        }
   // eslint-disable-next-line
    },[data])

    const fetchData = (url) => {
        setLoading(true)
        fetch(url, { signal: abortController.signal })
        .then(res => {
          setStatus(res.status)
          if(res.status===200){
            const contentType = res.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
              return res.json()
            }
            return []
          }else if(res.status===204){
            setLoading(false)
            return []
          }else if(res.status===401){
            cookies.remove('customer', {path:'/'})
            window.location.reload();
          }else if(res.status===503||res.status===500){
            setTimeout(() => {
              fetchData(url)
            }, 2000);
            return [];
          }else if(!res.ok){
            res.text().then(text=>{
              if(text==="Database 'Employnet' cannot be opened. It is in the middle of a restore."){
                setTimeout(() => {
                  fetchData(url)
                }, 2000);
              }else{
                throw Error(res.statusText)
              }
            })
          }
        })
        .then(data=>{
            setLoading(false);
            setData(data);
        })
        .catch(error => {
            setLoading(false);
            setError(error);
        });
    };
  
    return { error, loading, data, status, setData, setError };
  };

  useFetchGet.propTypes = {
    url: PropTypes.string.isRequired,
    cookies: PropTypes.object.isRequired,
    cookieName: PropTypes.string,
    refreshTime: PropTypes.number,
  }

  export default useFetchGet;