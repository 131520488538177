import React, { useContext, useState } from 'react'
import Codes from './Codes'
import CustomerContext from '../../../../../Context/CustomerContext';

function CodesLogic({ defaultFormData }) {
    const { cookies, formData, setFormData } = useContext(CustomerContext)
    const [isVMSCustomer] = useState(cookies.get('customer').runAsCustomer?cookies.get('customer').runAsCustomer.hasVMS:cookies.get('customer').hasVMS)

    const [orderTypes] = useState(
      isVMSCustomer?[
      {
        "OrderTypeId": 1,
        "OrderType": "DH",
        "Description": "Direct Hire"
      },
      {
        "OrderTypeId": 10,
        "OrderType": "vTE",
        "Description": "Vendor Temp"
      },
      {
        "OrderTypeId": 11,
        "OrderType": "vPR",
        "Description": "Vendor Payrolled"
    }
    ]
    :
    [
      {
        "OrderTypeId": 1,
        "OrderType": "DH",
        "Description": "Direct Hire"
      },
      {
        "OrderTypeId": 5,
        "OrderType": "TE",
        "Description": "Temp"
      },
      {
        "OrderTypeId": 3,
        "OrderType": "PR",
        "Description": "Payrolled"
      }
    ])
    const HandleChange = (e, fieldName) => { 
        formData[fieldName] = e.value.OrderTypeId;
        setFormData(formData)
     }

  return (
      <Codes
        HandleChange={HandleChange}
        defaultFormData={defaultFormData}
        orderTypes={orderTypes}
      ></Codes>
  )
}

export default CodesLogic