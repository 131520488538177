import React, { useState } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import Modal from 'react-bootstrap/Modal';
import Info from './Info';
import Assignments from './Assignments';
import Documents from './Documents';
import Candidacy from './Candidacy';

function Popup({ 
    show, 
    setShow, 
    employeeID, 
    rowData 
}) {
    const [activeKey, setActiveKey] = useState('Info');
  return (
    <Modal show={show} onHide={setShow} size='xl'>
        <Modal.Header closeButton>
            <h3>Employee Detail | {rowData.FirstName+' '+rowData.LastName} | {rowData.EmployeeID}</h3>
        </Modal.Header>
        <Modal.Body>
            <Accordion activeKey={activeKey} onSelect={(key)=>setActiveKey(key)}>

                <Accordion.Item eventKey="Info">
                    <Accordion.Header>Info</Accordion.Header>
                    <Accordion.Body className='job-info-accordion'>
                        <Info employeeID={employeeID} />
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="Assignments">
                    <Accordion.Header>Assignments</Accordion.Header>
                    <Accordion.Body className='job-info-accordion'>
                        <Assignments employeeID={employeeID} />
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="Documents">
                    <Accordion.Header>Documents</Accordion.Header>
                    <Accordion.Body className='job-info-accordion'>
                        <Documents employeeID={employeeID} />
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="Candidacy">
                    <Accordion.Header>Candidacy</Accordion.Header>
                    <Accordion.Body>
                        <Candidacy employeeID={employeeID} />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </Modal.Body>
    </Modal>
  )
}

export default Popup