import React, { useState } from 'react';
import Cookies from 'universal-cookie';

export const CustomerContext = React.createContext();

export function CustomerProvider({ children }){

    const cookies = new Cookies();
    const [user, setUser] = useState(null);
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [currentPage, setCurrentPage] = useState();
    const [addCandidateOpen, setAddCandidateOpen] = useState();
    const [formData, setFormData] = useState({
        "OrderRequestID": 0,
        "CustomerName": null,
        "CustomerID": 0,
        "Requestor": '',
        "Department": null,
        "WorkSite": null,
        "WorkSiteID": 0,
        "JobTitle": 'this is the default',
        "JobDescription": null,
        "LiftingRequirement": false,
        "CostCenter": null,
        "ProjectCode": null,
        "PO": null,
        "PayRate": null,
        "MultiplierCode": null,
        "BillRate": null,
        "PayType": 'Hourly',
        "CPE": 'false',
        "Schedule": null,
        "OTCalculation": null,
        "Shift": null,
        "ShiftNotes": null,
        "Monday": false,
        "Tuesday": false,
        "Wednesday": false,
        "Thursday": false,
        "Friday": false,
        "Saturday": false,
        "Sunday": false,
        "StartDate": null,
        "AssignmentEnd": null,
        "Notes": null,
        "JobOrderID": 0,
        "JobTitleID": 0,
        "ShiftID": null,
        "POID": 0,
        "MultiplierCodeId": 0,
        "DepartmentID": 0,
        "SRIdent": 0,
        "WorkerCompCodeID": 0,
        "OrderTitle":'',
        "ContactRoles":[]
        
      });

    return(
        <CustomerContext.Provider value={
            {   
                user,
                setUser,
                cookies,
                selectedBranch,
                setSelectedBranch,
                currentPage,
                setCurrentPage,
                addCandidateOpen,
                setAddCandidateOpen,
                formData,
                setFormData
            }
        }>
        {children}
    </CustomerContext.Provider>
)
}

export default CustomerContext;