import React, { useContext, useEffect, useState } from 'react'
import CustomerContext from '../../Context/CustomerContext';
import useFetchGet from '../../Fetch/useFetchGet';
import TimeclockWeekly from './TimeclockWeekly';

function TimeclockWeeklyLogic() {
    const [today] = useState(new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toJSON().split('T')[0])
    const [date, setDate] = useState(today);
    const [gridApi, setGridApi] = useState();
    const [defaultColDef] = useState({
        resizable: true,
        sortable: true,
        filter: 'agSetColumnFilter', 
        wrapHeaderText: true, 
        autoHeaderHeight: true, 
        width: 125
      });
    const [columnDefs] = useState([
      {headerName:'Customer',field:'CustomerName'},
      {headerName:'Department',field:'Department'},
      {headerName:'Worksite',field:'WorkSite'},
      {headerName:'Employee',field:'EmployeeName'},
      {headerName:'Vendor',field:'VendorName'},
      {headerName:'Weekend Date',field:'WeekendDate',valueFormatter: (date)=>{return TransformDate(date)}, filter: 'agDateColumnFilter'},
      {headerName:'Work Day',field:'WorkDate',valueFormatter: (date)=>{return TransformDate(date)}, filter: 'agDateColumnFilter'},
      {headerName:'Approved', field:'IsApproved', valueFormatter: ({value})=>value?'Yes':'No'},
      {headerName:'Reg Hours',field:'RegHours',filter: 'agNumberColumnFilter'},
      {headerName:'OT Hours',field:'OTHours',filter: 'agNumberColumnFilter'},
      {headerName:'DT Hours',field:'DTHours',filter: 'agNumberColumnFilter'},
      {headerName:'Alternate ID',field:'AlternateID'},
      {headerName:'Employee ID',field:'EmployeeID'},
      {headerName:'Assignment ID',field:'AssignmentID'},
    ])
    const [pinnedBottomRowData, setPinnedBottomRowData] = useState();
    const { setCurrentPage} = useContext(CustomerContext)
    const {REACT_APP_API_URL} = process.env;
    const { data, error } = useFetchGet(`${REACT_APP_API_URL}/Reports/GetTotalHoursWork`,{date})

    useEffect(()=>{
      setCurrentPage('Timeclock Weekly')
    })

    function TransformDate(date){
      if(date.value){
          return new Date(date.value).toLocaleDateString();
      }else{
          return ''
      }
    }

    useEffect(()=>{
      if(data){
        let uniqueIDCount = data.filter((element, index) => index===data.findIndex(elem => elem.EmployeeID===element.EmployeeID))
        setPinnedBottomRowData([{
          EmployeeName: `Headcount: ${uniqueIDCount.length}`,
          RegHours: `${data.reduce((last,current)=>last+current.RegHours,0).toFixed(2)}`,
          OTHours: `${data.reduce((last,current)=>last+current.OTHours,0).toFixed(2)}`,
          DTHours: `${data.reduce((last,current)=>last+current.DTHours,0).toFixed(2)}`,
          
        }])
      }
      },[data])

    const onGridReady = (params) => { 
        setGridApi(params.api)
        params.columnApi.autoSizeAllColumns();
    }

     const onBtnExport = () => {
        gridApi.exportDataAsCsv();
    };

    const onFilterChanged = (e) => { 
      let data = [];
      gridApi.forEachNodeAfterFilter(node=>{
         data.push(node.data);
      })
      let uniqueIDCount = data.filter((element, index) => index===data.findIndex(elem => elem.EmployeeID===element.EmployeeID))
      setPinnedBottomRowData([{
        EmployeeName: `Headcount: ${uniqueIDCount.length}`,
        RegHours: `${data.reduce((last,current)=>last+current.RegHours,0).toFixed(2)}`,
        OTHours: `${data.reduce((last,current)=>last+current.OTHours,0).toFixed(2)}`,
        DTHours: `${data.reduce((last,current)=>last+current.DTHours,0).toFixed(2)}`,
      }])
     }

  return (
    <TimeclockWeekly
        setDate={setDate}
        defaultColDef={defaultColDef} 
        rowData={data}
        columnDefs={columnDefs} 
        onGridReady={onGridReady}
        onBtnExport={onBtnExport}
        error={error}
        today={today}
        pinnedBottomRowData={pinnedBottomRowData}
        onFilterChanged={onFilterChanged}
    ></TimeclockWeekly>
  )
}

export default TimeclockWeeklyLogic