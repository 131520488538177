import React, { useContext, useState } from 'react'
import CustomerContext from '../../../Context/CustomerContext';
import AddSickLatePopup from './AddSickLatePopup'

function AddSickLatePopupLogic({ show, setShow }) {
    const [latePopupIsOpen, setLatePopupIsOpen] = useState(false);
    const [absencePopupIsOpen, setAbsencePopupIsOpen] = useState(false);
    const [employeeID, setEmployeeID] = useState();
    const [employee, setEmployee] = useState();
    const [sickRecorded, setSickRecorded] = useState();
    const [loading, setLoading] = useState(false);
    const {cookies} = useContext(CustomerContext)
    const {REACT_APP_API_URL} = process.env;

    const GetEmployeeByID = (e) => { 
        e.preventDefault()
        setSickRecorded(null)
        fetch(`${REACT_APP_API_URL}/Reports/GetEmployeByID?token=${cookies.get('customer').runAsCustomer?cookies.get('customer').runAsCustomer.Token:cookies.get('customer').Token}&ID=${employeeID}`)
        .then(res=>{
            if(res.status===200){
                return res.json()
            }else if(res.status===401){
                cookies.remove('customer', {path:'/'})
                window.location.reload()
            }else if(res.status===204){
                setEmployee(false);
            }else if(res.status===503||res.status===500){
                setTimeout(() => {
                    GetEmployeeByID()
                }, 1000);
            }else if(!res.ok){
                throw Error(res.statusText)
            }
        })
        .then(data=>{
            if(data){
                setEmployee(data)
            }
        })
        .catch(error=>{
            console.error(error)
            window.alert(error)
        })
     }

    const RecordSickNotice = () => { 
        setLoading(true);
        fetch(`${REACT_APP_API_URL}/Reports/recordsicknotice?token=${cookies.get('customer').runAsCustomer?cookies.get('customer').runAsCustomer.Token:cookies.get('customer').Token}&employeeid=${employee.EmployeeID}`,{
        method: 'POST',
        headers: {
            'Content-Type':'application/json'
        }
        })
        .then(res=>{
            if(res.status===200){
                return res
            }else if(res.status===401){
                cookies.remove('customer', {path:'/'})
                window.location.reload()
            }else if(!res.ok){
                throw Error(res.statusText)
            }
        })
        .then(data=>{
            setLoading(false)
            setSickRecorded('Sick day successfully recorded')
        })
        .catch(error=>{
            setSickRecorded('There was an issue recording the sick day, please try again')
            setLoading(false)
            console.error(error)
            window.alert(error)
        })
     }

    const HandleChange = (event) => { 
        setEmployeeID(event.target.value)
        setEmployee(null);
     }

  return (
    <AddSickLatePopup
        show={show}
        setShow={setShow}
        employee={employee}
        setEmployeeID={setEmployeeID}
        GetEmployeeByID={GetEmployeeByID}
        HandleChange={HandleChange}
        latePopupIsOpen={latePopupIsOpen}
        setLatePopupIsOpen={setLatePopupIsOpen}
        RecordSickNotice={RecordSickNotice}
        sickRecorded={sickRecorded}
        loading={loading}
        absencePopupIsOpen={absencePopupIsOpen}
        setAbsencePopupIsOpen={setAbsencePopupIsOpen}
    ></AddSickLatePopup>
  )
}

export default AddSickLatePopupLogic;