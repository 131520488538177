import React, { useState, useContext, useEffect } from 'react'
import { Alert, Button, Form, Modal } from 'react-bootstrap';
import CustomerContext from '../../../Context/CustomerContext';
import AutoSearchSelectLogic from './AutoSearchSelect/AutoSearchSelectLogic';
import useFetchPost from '../../../Fetch/useFetchPost';
import TWDelayConfirmation from '../../../Fetch/TWDelayConfirmation';

function AddEmployeeForm({ show, setShow}) {
  const { REACT_APP_API_URL } = process.env;
  const [error, setError] = useState();
  const [showConfiramtion, setShowConfirmation] = useState();
  const [postObj, setPostObj] = useState();
  const { status: postStatus , error: postError, loading, setLoading } = useFetchPost((postObj&&`${REACT_APP_API_URL}/VMS/PostNewVendorEmployee`),null,postObj)
 
  useEffect(()=>{
    if((postStatus===200)&&!postError){
      setShowConfirmation(true)
    }else if(postError){
      setError(postError)
    }
    //eslint-disable-next-line
  },[postStatus])

  useEffect(()=>{
    if(cookies.get('customer').runAsCustomer){
      setEmployee({
        'branchID':cookies.get('customer').runAsCustomer.BranchId,
        'firstName':null,
        'lastName':null,
        'emailAddress':null,
        'cellPhoneNumber':null,
        'homePhoneNumber':null,
        'streetAddress':null,
        'city':null,
        'zip':null,
      })
    }else if(cookies.get('customer')){
      setEmployee({
        'branchID':cookies.get('customer').BranchID,
        'firstName':null,
        'lastName':null,
        'emailAddress':null,
        'cellPhoneNumber':null,
        'homePhoneNumber':null,
        'streetAddress':null,
        'city':null,
        'zip':null,
      })
    }else{
      window.location.reload(false);
    }
    // eslint-disable-next-line
  },[])

  const { cookies } = useContext(CustomerContext)
  const [ employee, setEmployee ] = useState();

  const HandleSubmit = (e) =>{
      setLoading(true);
      e.preventDefault();
      if(ReadyToSubmit(employee)){
        setPostObj(employee)
      }
  }

  const ReadyToSubmit = (employee) => { 
    let canPost = true;
      if(!employee.firstName){
        setError('Please add a first name')
        setLoading(false)
        return false;
      }if(!employee.lastName){
        setError('Please add a last name')
        setLoading(false)
        return false;
      }if(!employee.emailAddress){
        setError('Please add an email address')
        setLoading(false)
        return false;
      }if(employee.emailAddress){
        if(ValidateEmail(employee.emailAddress)){
          canPost = true;
        }else{
          setError('Please enter valid email')
          setLoading(false)
          return false;
        }
      }if(!employee.cellPhoneNumber){
        setError('Please add a cell phone number')
        setLoading(false)
        return false;
      }if(employee.cellPhoneNumber){
        if(ValidatePhoneNumber(employee.cellPhoneNumber)){
          canPost =  true;
        }else{
          setError('Please add a valid cell phone number')
          setLoading(false)
          return false;
        }
      }if(employee.homePhoneNumber){
        if(ValidatePhoneNumber(employee.homePhoneNumber)){
          canPost =  true;
        }else{
          setError('Please add a valid home phone number')
          setLoading(false)
          return false;
        }
      }if(!employee.zip){
        setError('Please select a city/zip')
        setLoading(false)
        return false;
      }if(!employee.city){
        setError('Please select  city/zip')
        setLoading(false)
        return false;
      }if(!employee.branchID){
        setError('Please select a branch')
        setLoading(false)
        return false;
      }
      return canPost;
   }

  const ValidateEmail = (email) => { 
    // eslint-disable-next-line
    return (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/).test(email)
   }

  const ValidatePhoneNumber = (phone) => {
    // eslint-disable-next-line
    return (/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im).test(phone)
  }

  function HandleChange(fieldName, e){
    setError(null);
    if(fieldName==='cellPhoneNumber'){
        employee.cellPhoneNumber = e.target.value
        setEmployee(employee)
    }else if(fieldName==='zip'){
        employee.city = e.value.City;
        employee.zip = e.value.Zip;
        employee.state = e.value.State.trim();
        setEmployee(employee)
    }else{
        employee[fieldName] = e.target.value
        setEmployee(employee)
    }
  }

  const handleClose = () => { 
    setShow(false);
    setShowConfirmation(false)
   }
  
    return (
      <>
      {showConfiramtion&&<TWDelayConfirmation show={showConfiramtion} handleClose={handleClose} />}
        <Modal show={show} onHide={setShow}>
          <Modal.Header closeButton>
            <Modal.Title>Add Employee</Modal.Title>
          </Modal.Header>
            <Form onSubmit={HandleSubmit}>
              <Form.Group className='padding'> 
                    <Form.Label>First Name<span>*</span></Form.Label>
                    <Form.Control placeholder='Bob' onBlur={(e)=>HandleChange('firstName',e)}></Form.Control>
                    
                    <Form.Label>Last Name<span>*</span></Form.Label>
                    <Form.Control placeholder='Ross' onBlur={(e)=>HandleChange('lastName',e)}></Form.Control>
                
                    <Form.Label>Email<span>*</span></Form.Label>
                    <Form.Control placeholder='bob@ross.com' onBlur={(e)=>HandleChange('emailAddress',e)} ></Form.Control>

                    <Form.Label>Cell Phone<span>*</span></Form.Label>
                    <Form.Control placeholder='111-222-3333' type="tel" onChange={(e)=>HandleChange('cellPhoneNumber',e)} ></Form.Control>

                    <Form.Label>Home Phone</Form.Label>
                    <Form.Control placeholder='444-555-7777' type="tel" onChange={(e)=>HandleChange('homePhoneNumber',e)} ></Form.Control>
                    
                    <Form.Label>Street Address</Form.Label>
                    <Form.Control placeholder='123 Example Street' onChange={(e)=>HandleChange('streetAddress',e)} ></Form.Control>

                    <Form.Label>City/Zip<span>*</span></Form.Label>
                    <AutoSearchSelectLogic apiUrl={`${REACT_APP_API_URL}/Candidates/GetCityZips`} OnChange={(e)=>HandleChange('zip',e)} isMulti={false} labelName={'Zip'} />

                    {/* <Form.Label>Branch<span>*</span></Form.Label>
                    <AutoSearchSelectLogic isMulti={false} apiUrl={`${REACT_APP_API_URL}/Candidates/GetBranchList`} OnChange={(e)=>HandleOptionChange(e,'branchID')} labelName={'BranchName'} /> */}

                    {error&&
                      <Alert variant='warning'>{error}</Alert>
                    }
                    <Button className='full-width margin-top' disabled={loading} type='submit'>{loading ? 'Adding...' : 'Add Employee'}</Button>
              </Form.Group>
            </Form>
        </Modal>
      </>
    );
  }

  export default AddEmployeeForm;