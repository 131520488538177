import React from 'react'
import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import useFetchGet from '../../../Fetch/useFetchGet';

function Candidacy({ employeeID, setReviewCount }) {
    const {REACT_APP_API_URL} = process.env;
    const { data: candidacies, loading, error } = useFetchGet(`${REACT_APP_API_URL}/VMS/GetEmployeeCandidacy`, {EmployeeID:employeeID})

  return (
    <div className='vh20'>
        {loading&&
            <Spinner animation='border' />
        }
        {(candidacies&&candidacies.length>0)?
            candidacies.map(candidacy=>{
                return <Card key={candidacy.OrderID+candidacy.Status} className='margin-bottom'>
                            <Card.Header>
                                {candidacy.CustomerName+' | '+candidacy.DepartmentName+' | '+candidacy.Status+': '+candidacy.Description}
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col><b>Order ID:</b> {candidacy.OrderID}</Col>
                                    <Col><b>Active:</b> {candidacy.ImpliesActive?'Active':'Inactive'}</Col>
                                </Row>
                                <Row>
                                    <Col><b>Message:</b> {candidacy.Message}</Col>
                                    <Col><b>Message Date:</b> {candidacy.MessageDate&&candidacy.MessageDate.split('T')[0]}</Col>
                                </Row>
                            </Card.Body>
                        </Card>
            })
        :
        error?
        <Alert variant='warning'>
            <p>There was an issue getting data, please try again</p>
            <p>{error}</p>
        </Alert>
        :
        <Alert>No candidacy history</Alert>
        }
    </div>
  )
}

export default Candidacy