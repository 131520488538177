import React from 'react';
import { Button, Form, Alert } from 'react-bootstrap';
import { AgGridReact } from 'ag-grid-react';
import { BsDownload, BsBugFill } from "react-icons/bs";
import ButtonGroup from 'react-bootstrap/ButtonGroup'

function TimeclockWeekly({ onFilterChanged, pinnedBottomRowData, today, error, setDate, defaultColDef, rowData, columnDefs, onGridReady, onBtnExport }) {

  return (
    <>
        {today&&
        <div className='hours-worked-div'>
            <div className='hours-worked-date'>
              <Form.Control defaultValue={today} type='date' onChange={(e)=>setDate(e.target.value)} />
            </div>
            <div className='grid-download'>
            <ButtonGroup>
              <Button variant='dark' onClick={() => onBtnExport()}><BsDownload/></Button>
              <Button variant="dark" title='Report a bug' onClick={(e) => {
                window.location.href = `mailto:${'Bug Reporting - DevMo! <01bd0080.employnet.com@amer.teams.ms>'}` 
                e.preventDefault()
              }}><BsBugFill /></Button>
            </ButtonGroup>
            </div>
            {error&&<Alert variant="warning" >{error}</Alert>}
        </div>
        }
            
        <div id="myGrid" className="ag-theme-alpine grid filter-grid">
            <AgGridReact
                multiSortKey={'ctrl'}
                suppressExcelExport={true}
                popupParent={document.body}
                defaultColDef={defaultColDef}
                rowData={rowData}
                columnDefs={columnDefs}
                onGridReady={onGridReady}
                onFilterChanged={onFilterChanged}
                pinnedBottomRowData={pinnedBottomRowData}
            ></AgGridReact>
        </div>
    </>
  )
}

export default TimeclockWeekly