import React from 'react';
import { NavMenu } from './NavMenu';
import Footer from './Footer'
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';

const Layout = ({children}) => {


    return (
      <div>
        <NavMenu />
        <div className='content'>
        <ErrorBoundary>
          {children}
        </ErrorBoundary>
        </div>
        <Footer />
      </div>
    );
}

export default Layout;