import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import CustomerContext from '../../Context/CustomerContext';
import useFetchGet from '../../Fetch/useFetchGet';
import ReactSelect from 'react-select';

function Customer({ 
    setShow, 
    selectedContact, 
    setSelectedContact,
    successMessage,
    setSuccessMessage
}) {
    const { cookies } = useContext(CustomerContext)
    const { REACT_APP_API_URL } = process.env;
    const [customerOptions, setCustomerOptions] = useState();
    const [selectedCustomer, setSelectedCustomer] = useState();
    const [contactOptions, setContactOptions] = useState();
    const [getAudit, setGetAudit] = useState(false);
    const { data: unformattedCustomers, loading: customersLoading } = useFetchGet(`${REACT_APP_API_URL}/Reports/GetCustomerRootsFromToken`, null, true)
    const { data: unformattedContacts, loading } = useFetchGet((selectedCustomer&&`${REACT_APP_API_URL}/Reports/GetCustomerContactTree`),{'CustomerID':selectedCustomer&&selectedCustomer.value.CustomerID}, true)
    const { data: newAudit, loading: loadingAudit, error, setError: setAuditError, status: auditStatus } = useFetchGet((getAudit&&`${REACT_APP_API_URL}/Utilities/RunAsCustomer`),{'ContactID':selectedContact&&selectedContact.value.ContactID},true)

    useEffect(()=>{
        if(unformattedCustomers){
            setCustomerOptions(unformattedCustomers.map(cust=>{return{value:cust,label:(cust.CustomerName+' | '+cust.DepartmentName)}}))
        }
    },[unformattedCustomers])

    useEffect(()=>{
        if(unformattedContacts){
            let formattedContacts = []
            buildDropdown(unformattedContacts, '', formattedContacts)
            setContactOptions(formattedContacts)
        }
    // eslint-disable-next-line
    },[unformattedContacts])



    useEffect(()=>{
        if(newAudit&&!error){
            if(auditStatus===204){
                setAuditError('Selected Contact is not a Web User, please select a different contact');
                setSelectedContact(null);
                setGetAudit(false);
            }else{
                setSuccessMessage(true)
                cookies.remove('department')
                cookies.remove('worksites')
                cookies.set('customer', newAudit, {path:'/'})  
                window.location.reload();
            }
        }
        // eslint-disable-next-line
    },[newAudit])

    function buildDropdown(data, baseString, optionsArray){
        data.contacts.forEach(contact=>{
            if(contact.IsUser&&contact.IsVendor){
                optionsArray.push({isDisabled:true, value: contact, label:baseString+contact.Departmentname+' | '+contact.FirstName+' '+contact.LastName+' - VENDOR'})
            }else if(contact.IsUser&&(!contact.IsVendor)){
                optionsArray.push({value: contact, label:baseString+contact.Departmentname+' | '+contact.FirstName+' '+contact.LastName})
            }else if((!contact.IsUser)&&contact.IsVendor){
                optionsArray.push({isDisabled:true, value: contact, label:baseString+contact.Departmentname+' | '+contact.FirstName+' '+contact.LastName+' - VENDOR - NOTWEBUSER'})
            }else{
                optionsArray.push({isDisabled:true, value: contact, label:baseString+contact.Departmentname+' | '+contact.FirstName+' '+contact.LastName+' - NOTWEBUSER'})
            }   
            
        })
        data.customerContactsTrees.forEach(cust=>{
          buildDropdown(cust, (baseString+data.DepartmentName+' | '), optionsArray)
        })
        return;
      }

    const SetImpersonatedUser = () => { 
        setGetAudit(true);
     }

    const HandleCustomerChange = (e) => { 
        setSelectedContact(null)
        setSelectedCustomer(e)
     }
    
     const handleContactChange = (e) => { 
        setSelectedContact(e)
        setAuditError(null)
     }

    return(
        <>
        <Modal.Body>
            <Form.Label>Customer:{customersLoading&&<Spinner animation='border' size='sm' />}</Form.Label>
            {customerOptions&&
                <ReactSelect value={selectedCustomer} options={customerOptions} onChange={(e)=>HandleCustomerChange(e)} />
            }

            <Form.Label>Contact:{loading&&<Spinner animation='border' size='sm' />}</Form.Label>
            {contactOptions?
                <ReactSelect isDisabled={(selectedCustomer&&!loading)?false:true} value={selectedContact} options={contactOptions} onChange={(e)=>handleContactChange(e)} />
            :
                <ReactSelect disabled />
            }
        </Modal.Body>
        <Modal.Footer>
            {successMessage?
                <Alert>Reloading page to finalize impersonation, if page does not reload please refresh your web browser</Alert>
                :
                <Row className='full-width'>       
                    <Col>
                        <Button className='full-width' variant="secondary" onClick={()=>setShow(false)}>
                            Close
                        </Button>
                    </Col>
                    <Col>
                    {(selectedContact&&!error&&(loadingAudit===false))&&
                        <Button className='full-width' variant="primary" onClick={()=>SetImpersonatedUser()}>
                            Impersonate {selectedContact?selectedContact.value.FirstName:''}
                        </Button>
                    }
                    {loadingAudit&&
                        <Alert className='full-width' >Setting up impersonation <Spinner animation='border' size='sm'/></Alert>
                    }
                    {error&&
                        <Alert variant='warning'>{error}</Alert>
                    }
                    </Col>
                </Row>
            }
        </Modal.Footer>
        </>
    )
}

export default Customer