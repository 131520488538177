import React, { useContext, useEffect, useState } from 'react'
import CustomerContext from '../../Context/CustomerContext'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import '../CustomComponents/Tabs/Tabs.css'
import WeeklyTab from './WeeklyTab'
import DataTab from '../CustomComponents/Tabs/DataTab'

function LengthOfAssignment() {
    const { REACT_APP_API_URL } = process.env;
    const { setCurrentPage } = useContext(CustomerContext)
    const [ selectedTab, setSelectedTab ] = useState('Weekly')
    const [ tabApiUrl ] = useState(`${REACT_APP_API_URL}/Reports/GetCustomerLengthOfAssignment`);
    const [ tabRangeChartSeries ] = useState(['LOAMonths'])
    const [reportDescription] = useState(<div><p>This report is displayed as a histogram.</p><p> The bottom or 'x' axis displays the employees total days on assignment, represented as months to make it more digestible. The left or 'y' axis is the number of employees.</p><p> This report groups the total number of employees who are active for the selected weekending date based on their length of assignment at that point in time.</p></div>)
    const FormatDate = (params) => { 
        if(params.value) return params.value.split('T')[0]
     }
    const [columnDefs] = useState([
        { field: 'DateLabel', headerName:'Date', chartDataType: 'category' },
        { field: 'LOAMonths', headerName: 'LOA in Months',chartDataType:'series', filter:'agNumberColumnFilter'},
        { field: 'ROAMonths', headerName: 'ROA in Months',chartDataType:'series', filter:'agNumberColumnFilter'},
        { field: 'EmployeeName', headerName:'Employee'},
        { field: 'EmployeeId', headerName: 'Employee ID', chartDataType: 'category', filter: 'agSetColumnFilter' },
        { field: 'AlternateID', chartDataType: 'category', filter: 'agSetColumnFilter' },
        { field: 'Shift', chartDataType: 'category' },
        { field: 'VendorName', filter: 'agSetColumnFilter', chartDataType: 'category'},
        { field: 'Worksite',filter: 'agSetColumnFilter', chartDataType: 'category',},
        { field: 'OrderType',filter: 'agSetColumnFilter',chartDataType:'category'},
        { field: 'Department',filter: 'agSetColumnFilter',chartDataType:'category'},
        { field: 'Supervisor',chartDataType:'category'},
        { field: 'StartDate', filter: 'agSetColumnFilter', chartDataType: 'category', valueFormatter: FormatDate},
        { field: 'ExpectedEndDate', filter: 'agSetColumnFilter', chartDataType: 'category', valueFormatter: FormatDate},
    ])

    useEffect(()=>{
        setCurrentPage('LOA Active')
    },[setCurrentPage])
  

  return (
    <div>
        
        <Tabs
            activeKey={selectedTab}
            onSelect={(tab)=>setSelectedTab(tab)}
        >
        <Tab eventKey="Weekly" title="Weekly">
            {selectedTab===('Weekly')&&
                <WeeklyTab aggFunc={'avg'} columnDefs={columnDefs} apiUrl={tabApiUrl} rangeChartSeries={tabRangeChartSeries} chartTitle='Length of Assignment in Months - Active' chartType={'histogram'} reportDescription={reportDescription} />
            }
        </Tab>
        <Tab eventKey="Data" title="Data">
            {selectedTab==='Data'&&
                <DataTab columnDefs={columnDefs} apiUrl={tabApiUrl} chartTitle={'LOA  Active'} reportDescription={reportDescription} />
            }
        </Tab>
        </Tabs>
    </div>
  )
}

export default LengthOfAssignment