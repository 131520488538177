import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import CustomerContext from '../../../../../Context/CustomerContext';
import useFetchGet from '../../../../../Fetch/useFetchGet';
import Job from './Job'

function JobLogic({ defaultFormData }) {
  const [currentJobTitle, setCurrentJobTitle] = useState();
  const [jobDescription, setJobDescription] = useState((defaultFormData?defaultFormData.JobDescription:''))
  const {
    cookies,
    formData,
    setFormData
  } = useContext(CustomerContext)
  const {REACT_APP_API_URL} = process.env
  const {data: jobTitles} = useFetchGet((formData.Department&&`${REACT_APP_API_URL}/OrderRequest/getallordefaultskillcodes`),{CustomerID:formData.Department.CustomerID})
  const [formattedJobTitles, setFormattedJobTitles] = useState();

  useEffect(()=>{
    const FormatJobTitles = (jobTitles) => { 
      if(jobTitles.data.length>0){
        setFormattedJobTitles(jobTitles.data.map(jobTitle=>{
          return {value:jobTitle, label:jobTitle.JobTitle}
        }))
      }else{
        setFormattedJobTitles(jobTitles.alldata.map(jobTitle=>{
          jobTitle.jobDescription = jobTitle.SkillCodeDescription
          return {value:jobTitle, label:jobTitle.SkillCodeDescription}
        }))
      }
     }

     if(jobTitles){
      FormatJobTitles(jobTitles)
     }

  },[jobTitles])

  const HandleJobTitleChange = (e) => { 
    let newFormData = {...formData}
    newFormData.JobTitle = e.value
    newFormData.JobDescription = e.value.jobDescription
    setJobDescription(e.value.jobDescription)
    setFormData(newFormData)
    setCurrentJobTitle(e.value)
   }

  const HandleDateChange = (e, fieldName) =>{
    formData[fieldName] = e.target.value;
    setFormData(formData)
  }

  const HandleJobDescChange = (event) => { 
    formData.JobDescription = event.target.value;
    setFormData(formData)
   }

  return (
    <Job
      HandleJobTitleChange={HandleJobTitleChange}
      cookies={cookies}
      formData={formData}
      currentJobTitle={currentJobTitle}
      HandleDateChange={HandleDateChange}
      HandleJobDescChange={HandleJobDescChange}
      defaultFormData={defaultFormData}
      jobDescription={jobDescription}
      formattedJobTitles={formattedJobTitles}
    ></Job>
  )
}

export default JobLogic