import React, { useContext } from 'react';
import CustomerContext from '../Context/CustomerContext';
import { Navigate } from 'react-router-dom';

function PrivateRoute({ children }) {
  const {
    cookies
  } = useContext(CustomerContext)
  const authed = (!(cookies.get('customer')==null))

  return authed === true
    ? children
    : <Navigate to="/login" replace />;
}

export default PrivateRoute;