import React, { useContext, useState, useEffect } from 'react'
import CustomerContext from '../../Context/CustomerContext'
import useFetchGet from '../../Fetch/useFetchGet';
import HoursWorked from './HoursWorked'

function HoursWorkedLogic() {
    const {setCurrentPage} = useContext(CustomerContext);
    const {REACT_APP_API_URL} = process.env;
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [pinnedBottomRowData, setPinnedBottomRowData] = useState();
    const [gridApi, setGridApi] = useState();
    const [onlyActive, setOnlyActive] = useState(false);
    const [defaultColDef] = useState({
        resizable: true,
        sortable: true,
        filter: true,
        enableValue: true,
        // allow every column to be grouped
        enableRowGroup: true,
        // allow every column to be pivoted
        enablePivot: true,
      });
    const [columnDefs] = useState([
    {headerName:'Customer',field:'CustomerName'},
    {headerName:'Department',field:'DepartmentName'},
    {headerName:'Employee',field:'EmployeeName'},
    {field: 'EmployeeID', chartDataType: 'category', filter: 'agSetColumnFilter' },
    {field: 'AlternateID', chartDataType: 'category', filter: 'agSetColumnFilter' },
    {field:'TotalHours',filter: 'agNumberColumnFilter', valueFormatter: (count)=>{return MakeDouble(count)}, cellClass: 'align-right'},
    {field:'RegHours',filter: 'agNumberColumnFilter', valueFormatter: (count)=>{return MakeDouble(count)}, cellClass: 'align-right'},
    {field:'OTHours',filter: 'agNumberColumnFilter', valueFormatter: (count)=>{return MakeDouble(count)}, cellClass: 'align-right'},
    {field:'DTHours',filter: 'agNumberColumnFilter', valueFormatter: (count)=>{return MakeDouble(count)}, cellClass: 'align-right'},
    ])
    const { data, loading } = useFetchGet(((startDate&&endDate)&&`${REACT_APP_API_URL}/Reports/GetHoursWorked`),{startDate:(startDate&&startDate.value.TheDate),endDate:(endDate&&endDate.value.TheDate),CurrentlyActive:onlyActive})
    const { data: sundays } = useFetchGet(`${REACT_APP_API_URL}/Reports/Getsundays`,{weeks:52})

    useEffect(()=>{
      if(sundays){
        setStartDate({value:sundays[0], label:sundays[0].DateValue})
        setEndDate({value:sundays[0], label:sundays[0].DateValue})
      }
    // eslint-disable-next-line
    },[sundays])

    const MakeDouble = (int) => { 
      if(int.value||int.value===0){
        return Number(int.value).toLocaleString(undefined, {minimumFractionDigits: 2})
      }else{
        return ''
      }
     }

    useEffect(()=>{
        if(data){
          setPinnedBottomRowData([{
            TotalHours: `${Reduce(data, 'TotalHours')}`,
            RegHours: `${Reduce(data, 'RegHours')}`,
            OTHours: `${Reduce(data, 'OTHours')}`,
            DTHours: `${Reduce(data, 'DTHours')}`,
          }])
        }
    // eslint-disable-next-line
    },[data])

    useEffect(()=>{
      setCurrentPage('Hours Worked')
    },[setCurrentPage])

    const Reduce = (data, dataName) => { 
       let total = data.reduce((last,current)=>last+current[dataName],0).toFixed(2)
       return total
     }

    const onGridReady = (params) => { 
        setGridApi(params.api)
        params.api.sizeColumnsToFit();
    }

    const onFilterChanged = (e) => { 
        let data = [];
        gridApi.forEachNodeAfterFilter(node=>{
           data.push(node.data);
        })
        setPinnedBottomRowData([{
          TotalHours: `${Reduce(data, 'TotalHours')}`,
          RegHours: `${Reduce(data, 'RegHours')}`,
          OTHours: `${Reduce(data, 'OTHours')}`,
          DTHours: `${Reduce(data, 'DTHours')}`,
        }])
    }

    const onBtnExport = () => {
      gridApi.exportDataAsCsv();
  };

  return (
    <HoursWorked
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        data={data}
        onGridReady={onGridReady}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        pinnedBottomRowData={pinnedBottomRowData}
        onFilterChanged={onFilterChanged}
        startDate={startDate}
        endDate={endDate}
        sundays={sundays}
        onBtnExport={onBtnExport}
        onlyActive={onlyActive}
        setOnlyActive={setOnlyActive}
        loading={loading}
    ></HoursWorked>
  )
}

export default HoursWorkedLogic