import React, { useState } from 'react'
import useFetchGet from '../../../Fetch/useFetchGet';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { AgGridReact } from 'ag-grid-react';
import useFetchPost from '../../../Fetch/useFetchPost';
import { useEffect } from 'react';
import TWDelayConfirmation from '../../../Fetch/TWDelayConfirmation';

function AddCandidatePopup({ show, setShow, employees}) {
    const [employeeIDs] = useState(employees.map(employee=>employee.data.EmployeeID));
    const [selectedOrderID, setSelectedOrderID] = useState();
    const [showTWDelayConfirmation, setShowTWDelayConfirmation] = useState(false);
    const [canPost, setCanPost] = useState();
    const { REACT_APP_API_URL } = process.env;
    const { data: addedEmployees, loading: loadingAddedEmployees, status } = useFetchPost((canPost&&`${REACT_APP_API_URL}/VMS/SubmitEmployeeToCustomer`),{OrderID:selectedOrderID},employeeIDs);
    const { data: orders } = useFetchGet(`${REACT_APP_API_URL}/VMS/GetVMSOrderDetail`,{VendorStatus: 'All'});
    const [defaultColDef] = useState({ resizable: true, sortable: true, filter: true, width: 125 });
    const formatDate = (params) => params.value? params.value.split('T')[0]:'';
    const [columnDefs] = useState([
        {checkboxSelection: true, width: 50},
        {field:'OrderID',headerName:'Order ID'},
        {field:'RequestName',headerName:'Request Name'},
        {field:'JobTitle',headerName:'Job Title'},
        {field:'StartDate',headerName:'Start Date', valueFormatter: formatDate},
        {field:'Worksite',headerName:'WorkSite'},
        {field:'CustomerName',headerName:'Customer'},
        {field:'Shift',headerName:'Shift'},
        {field:'Candidates',headerName:'Candidates'},
        {field:'Submitted',headerName:'Submitted?'},
        {field:'Required',headerName:'Required'},
        {field:'Filled',headerName:'Filled'},
        {field:'Unfilled',headerName:'Unfilled'},
        {field:'JobDescription',headerName:'Job Description'},
    ]);

    useEffect(()=>{
        if(status&&status===200){
            setShowTWDelayConfirmation(true);
        }
    },[status])

    useEffect(()=>{
        if(addedEmployees){
            setCanPost(false);
        }
    },[addedEmployees])

    const handleClose = () => { 
        setShowTWDelayConfirmation(false);
        setShow(false);
     }

    const onRowSelected = (params) => { 
        setSelectedOrderID(params.data.OrderID)
     }

  return (
    <>
    {showTWDelayConfirmation&&<TWDelayConfirmation show={showTWDelayConfirmation} handleClose={handleClose} />}
    <Modal show={show} onHide={setShow} size='xl'>
        <Modal.Header closeButton>{(employees.length===1)?'Add Employee To Order':'Add Employees To Order'}</Modal.Header>
        <Modal.Body>
            <h6>Select one order form the grid below:</h6>
            <div className="ag-theme-alpine vh-40 margin-top">
                <AgGridReact
                    multiSortKey={'ctrl'}
                    rowData={orders}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    rowSelction='single'
                    onRowSelected={onRowSelected}
                ></AgGridReact>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={()=>setCanPost(true)}>{loadingAddedEmployees?<Spinner animation='border' size='sm'/>:(employees.length===1)?'Add Employee To Order':'Add Employees To Order'}</Button>
            <Button variant='secondary' onClick={()=>setShow(false)}>Close</Button>
        </Modal.Footer>
    </Modal>
    </>
  )
}

export default AddCandidatePopup