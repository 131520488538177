import React from 'react'
import { AgGridReact } from 'ag-grid-react'
import { useState } from 'react'

function Employees({ employees }){
  const [defaultColDef] = useState({ resizable: true, sortable: true, filter: true, width: 125 })
  const [columnDefs] = useState([
    {field:'FirstName'},
    {field:'LastName'},
    {field:'PayRate'},
    {field:'StartDate'},
    {field:'EndDate'},
    {field:'AssignmentID'},
    {field:'VendorPayRate'},
  ])
  
  return (
    <div id="myGrid" className="ag-theme-alpine vh-40">
    <AgGridReact
        multiSortKey={'ctrl'}
        defaultColDef={defaultColDef}
        rowData={employees}
        columnDefs={columnDefs}
    ></AgGridReact>
</div>
  )
}

export default Employees;