import React from 'react'
import DepartmentLogic from './RequestFormComponents/Department/DepartmentLogic'
import WorkSiteLogic from './RequestFormComponents/WorkSite/WorkSiteLogic'
import PayLogic from './RequestFormComponents/Pay/PayLogic'
import JobLogic from './RequestFormComponents/Job/JobLogic'
import CodesLogic from './RequestFormComponents/Codes/CodesLogic'
import PostToTWConfirmation from './PostToTWConfirmation/PostToTWConfirmation'
import ErrorBoundary from '../../../ErrorBoundary/ErrorBoundary'
import Accordion from 'react-bootstrap/Accordion';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';  
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import ContactRoles from './RequestFormComponents/ContactRoles/ContactRoles'

function AddJobOrder({ 
    defaultFormData, 
    confirmationPageIsOpen, 
    handlePopupAndFormClose,
    jobOrderID, 
    numberOfPositions, 
    HandleNumberOfPositionsChange, 
    codes, 
    codesError, 
    workSite, 
    workSiteError, 
    job, 
    jobError, 
    pay, 
    payError, 
    show, 
    setShow, 
    loading, 
    requestTitleError, 
    jobRequestTitle, 
    setReqTitle, 
    setGeneralNotes, 
    HandleSubmit, 
    selectedDepartment, 
    setSelectedDepartment 
}) {
  return (
    <>
    {confirmationPageIsOpen&&<PostToTWConfirmation show={confirmationPageIsOpen} setShow={handlePopupAndFormClose} jobOrderID={jobOrderID} />}
    <Modal size='lg' show={show} onHide={setShow}>
        <Modal.Header closeButton>
            <h5>{defaultFormData?'EditJobOrder':'Add Job Order'}</h5>
        </Modal.Header>

        <Modal.Body>

        <ErrorBoundary>
        <Form onSubmit={HandleSubmit}>
        <Card className='padding'>

            <Card className='padding margin-bottom'>
            <DepartmentLogic defaultFormData={defaultFormData} selectedDepartment={selectedDepartment} setSelectedDepartment={setSelectedDepartment} />
            </Card>

            {(selectedDepartment&&!loading&&!jobOrderID)&&
            <>
            <Card ref={jobRequestTitle} className='padding margin-bottom'>
                <Form.Label>Request Name</Form.Label>
                <Form.Control defaultValue={defaultFormData&&defaultFormData.OrderTitle} onChange={(e)=>setReqTitle(e.target.value)} className='margin-bottom' ></Form.Control>
                {requestTitleError&&
                <Alert variant='warning'>A Request Name is required</Alert>
                }
                <Form.Label>Number of Positions<span>*</span></Form.Label>
                <Form.Control value={numberOfPositions} onChange={HandleNumberOfPositionsChange} className='margin-bottom' />
                
                <div ref={codes}>
                <CodesLogic defaultFormData={defaultFormData} />
                {codesError&&<Alert variant='warning'>{codesError}</Alert>}
                </div>
            </Card>

            <Accordion className='margin-bottom'>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Contact Roles</Accordion.Header>
                    <Accordion.Body>
                        <ContactRoles />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

            <Card ref={workSite} className='padding margin-bottom'>
                <WorkSiteLogic defaultFormData={defaultFormData}  />
                {workSiteError&&<Alert variant='warning'>{workSiteError}</Alert>}
            </Card>

            <Card ref={pay} className='padding margin-bottom'>
                <PayLogic defaultFormData={defaultFormData} />
                {payError&&<Alert variant='warning'>{payError}</Alert>}
            </Card>

            <Card ref={job} className='padding margin-bottom'>
                <JobLogic defaultFormData={defaultFormData} />
                {jobError&&<Alert variant='warning'>{jobError}</Alert>}
            </Card>

            <Form.Label>General Job Notes</Form.Label>
            <Form.Control onChange={(e)=>setGeneralNotes(e.target.value)} as='textarea' className='text-area margin-bottom'></Form.Control>
            
            </>}
            <Row>
                <Col><Button className='full-width' variant='secondary' onClick={()=>setShow(false)}>Close</Button></Col>
                <Col><Button className='full-width' disabled={loading} type='submit' >{loading ? <>Submitting <Spinner animation='border' size='sm'/></> : 'Submit'}</Button></Col>
            </Row>
        </Card>
        </Form>
        </ErrorBoundary>
        </Modal.Body>
    </Modal>
    </>
  )
}

export default AddJobOrder