const setSelectedItems = (params, instanceName, defaultArray) => {
  let instance = params.api.getFilterInstance(instanceName)
  if(instance.getModel()){
      let selectedOptionsFromGrid = instance.getModel().values
      let formattedOptions = selectedOptionsFromGrid.map(string=>{return{value:string,label:string}})
      return formattedOptions;
  }else{
      return defaultArray
  } 
}

const buildFilterOptions = (params, filterInstance) => { 
  // grab all the columns from the grid
  // pull out the ones that have the chart type of category
  let siteValues = params.api.getFilterInstance(filterInstance).getFilterValues().map(site=>{return{value:site.trim(),label:site}})
  return siteValues
}

const formatDouble = (params) => { 
    if(params.value||params.value===0){
      return Number.parseFloat(params.value).toFixed(2);
    }else{
      return ''
    }
}

const formatDate = (params) => { 
    if(params.value){
        return params.value.split('T')[0]
    }else{
        return ''
    }
 }

let USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

const formatDollar = (params) =>{
    if(params.value||params.value===0){
        return USDollar.format(params.value)
    }
}

const formatPercentage = (params) => { 
    if(params.value){
        return (params.value*100).toFixed(2)+'%'
    }else{
        return ''
    }
 }

const formatBool = (params) => {
    if(params.value){
        return 'Yes'
    }else if(params.value===false){
        return 'No'
    }else{
        return ''
    }
}

const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}

const onBtnExport = (gridApi) => {
    gridApi.exportDataAsCsv();
};

  export {
    buildFilterOptions,
    setSelectedItems,
    formatDouble,
    formatDate,
    formatDollar,
    formatPercentage,
    formatBool,
    openInNewTab,
    onBtnExport
  }
