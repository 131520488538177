import React, { useContext, useEffect, useState } from 'react';
import CustomerContext from '../../Context/CustomerContext'
import Absence from './Absence'
import useFetchGet from '../../Fetch/useFetchGet';

function AbsenceLogic() {
  const [today] = useState(new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toJSON().split('T')[0])
  const [gridApi,setGridApi] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [employeeLateSickPopupIsOpen,setEmployeeLateSickPopupIsOpen] = useState(false);
  const [defaultColDef] = useState({
      resizable: true,
      sortable: true,
      filter: true,
      width: 125
    });
    const [columnDefs] = useState([
      {headerName:'Customer', field:'CustomerName'},
      {headerName:'Department',field:'DepartmentName'},
      {headerName:'Employee ID',field:'EmployeeID'},
      {field:'AlternateID'},
      {headerName:'First Name',field:'FirstName'},
      {headerName:'Last Name',field:'LastName'},
      {headerName:'Absence',field:'Absence'},
      {field:'Notes'},
      {headerName:'Work Site',field:'City'},
      {headerName:'Notice Date',field:'NoticeDay',valueFormatter: (date)=>{return TransformDate(date)}, filter: 'agDateColumnFilter'},
      {headerName:'Notice Time',field:'NoticeDateTime',valueFormatter: (date)=>{return TransformTime(date)}},
      {headerName:'Shift',field:'Shift'},
      {headerName:'Shift Start',field:'ShiftStartTime',valueFormatter: (date)=>{return TransformTime(date)}},
      {headerName:'Shift End',field:'ShiftEndTime',valueFormatter: (date)=>{return TransformTime(date)}}
    ]);
    const { setCurrentPage } = useContext(CustomerContext);
    const {REACT_APP_API_URL} = process.env;
    const { data } = useFetchGet(`${REACT_APP_API_URL}/Reports/GetAbsence`, {'day':(selectedDate?selectedDate:today)}, false, 60000)
    
    useEffect(()=>{
      setCurrentPage('Absence')
    })
  
      const TransformTime = (date) => { 
        if(date.value){
          let d = new Date(date.value);
          var hh = d.getHours();
          var m = d.getMinutes();
          var dd = "AM";
          var h = hh;
          if (h >= 12) {
            h = hh - 12;
            dd = "PM";
          }
          if (h === 0) {
            h = 12;
          }
          m = m < 10 ? "0" + m : m;
          return `${h}:${m}${dd}`
        }else{
          return 'None'
        }
      }
  
      function TransformDate(date){
        if(date.value){
            return new Date(date.value).toLocaleDateString();
        }else{
            return 'None'
        }
    }
      
    const onGridReady = (params) => { 
      setGridApi(params.api)
      params.columnApi.autoSizeAllColumns();
   }

   const onBtnExport = () => {
      gridApi.exportDataAsCsv();
  };
  

  return (
    <>
    {today&&
    <Absence
      today={today}
      defaultColDef={defaultColDef} 
      rowData={data}
      columnDefs={columnDefs} 
      onGridReady={onGridReady}
      setSelectedDate={setSelectedDate}
      onBtnExport={onBtnExport}
      employeeLateSickPopupIsOpen={employeeLateSickPopupIsOpen}
      setEmployeeLateSickPopupIsOpen={setEmployeeLateSickPopupIsOpen}
    ></Absence>
    }
    </>
  )
}

export default AbsenceLogic