import { AgGridReact } from 'ag-grid-react'
import React from 'react'
import { BsDownload, BsBugFill } from "react-icons/bs";
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import { Button, Form, Row, Col, Spinner } from 'react-bootstrap'
import Select from 'react-select';
 
function HoursWorkedFinancialDetail({ 
  sundays, 
  onBtnExport, 
  defaultColDef, 
  data, 
  columnDefs, 
  onGridReady, 
  onFilterChanged, 
  pinnedBottomRowData, 
  startDate, 
  endDate, 
  setStartDate, 
  setEndDate,
  onlyActive,
  setOnlyActive,
  loading,
  onFirstDataRendered,
  groupByOptions,
  gridApi,
  HandleGroupByDropdownChange,
}) {
  return (
    <>
        <Row>
            <Col>
            <Form.Label>Include work week ending dates from:</Form.Label>
            {startDate?
            <Select isDisabled={onlyActive} options={sundays.map(sunday=>{return{value:sunday, label:sunday.DateValue}})} defaultValue={startDate} onChange={(e)=>setStartDate(e)} />
            :
            <Spinner animation='border' />
            }
            </Col>
            <Col>
            <Form.Label>To:{loading?<Spinner size='sm' animation='border' />:''}</Form.Label>
            {endDate?
            <Select isDisabled={onlyActive} options={sundays.map(sunday=>{return{value:sunday, label:sunday.DateValue}})} defaultValue={endDate} onChange={(e)=>setEndDate(e)} />
            :
            <Spinner animation='border' />
            }
            </Col>
              <Col>
                  <Form.Label>Group By:</Form.Label>
                  {groupByOptions?
                    <Select defaultValue={groupByOptions[0]} options={groupByOptions} onChange={(e)=>HandleGroupByDropdownChange(e, gridApi)} />
                    :
                    <Select isDisabled />
                  }
              </Col>
            <Col className='onlyActiveWrapper' sm={2}>
            <Form.Check 
              type="switch"
              id="custom-switch"
              onChange={(e)=>setOnlyActive(!onlyActive)}
              value={onlyActive}
              label="Only Active"
            />
            </Col>
            <Col sm={1} style={{'alignItems': 'end','display': 'flex'}}>
            <ButtonGroup>
              <Button variant='dark' onClick={() => onBtnExport()}><BsDownload/></Button>
              <Button variant="dark" title='Report a bug' onClick={(e) => {
                window.location.href = `mailto:${'Bug Reporting - DevMo! <01bd0080.employnet.com@amer.teams.ms>'}` 
                e.preventDefault()
              }}><BsBugFill /></Button>
            </ButtonGroup>
            </Col>
        </Row>
        <div id="myGrid" className="ag-theme-alpine grid margin-top filter-grid">
            <AgGridReact
                multiSortKey={'ctrl'}
                suppressExcelExport={true}
                popupParent={document.body}
                defaultColDef={defaultColDef}
                rowData={data}
                columnDefs={columnDefs}
                onGridReady={onGridReady}
                onFirstDataRendered={onFirstDataRendered}
                onFilterChanged={onFilterChanged}
                pinnedBottomRowData={pinnedBottomRowData}
                sideBar={true}
                groupIncludeFooter={true} 
                groupIncludeTotalFooter={true}
                suppressAggFuncInHeader={true}
            ></AgGridReact>
        </div>
        </>
  )
}

export default HoursWorkedFinancialDetail