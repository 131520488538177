import React from 'react'
import { useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';  
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import useFetchPost from '../../../../Fetch/useFetchPost';
import './CandidateCard.css'
import Popup from '../../../Employees/Popup/Popup';
import TWDelayConfirmation from '../../../../Fetch/TWDelayConfirmation';

function CandidateCard({ candidate, statusOptions }){
  const [note, setNote] = useState(candidate.Message);
  const [selectedStatus, setSelectedStatus] = useState();
  const [showUpdateStatusForm, setShowUpdateStatusForm] = useState(false);
  const [openEmployeeInfoPopup, setOpenEmployeeInfoPopup] = useState(false);
  const [postObj, setPostObj] = useState();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const { REACT_APP_API_URL } = process.env;
  const { loading, error, status } = useFetchPost((postObj&&`${REACT_APP_API_URL}/Candidates/PostTWCandidateStatus`), null, (postObj&&postObj));

  useEffect(()=>{
    if(statusOptions){
      let defaultStatus = statusOptions.find(status=>status.value.status===candidate.Status)
      setSelectedStatus(defaultStatus)
    }
  },[statusOptions,candidate.Status])

  useEffect(()=>{
    if(status===200){
      setShowSuccessMessage(true)
      setSelectedStatus(null)
      setNote(candidate.Message)
    }
    // eslint-disable-next-line
  },[status])

  const handlePost = () => { 
    let newPostObj = {
      candidateid: candidate.CandidateID,
      orderid: candidate.OrderID,
      statuscodeid: selectedStatus.value.CandidateStatusID,
      notes: note?note:''
    };
    setPostObj(newPostObj);
   }

  const handleCloseSuccessMessage = () => { 
    setShowSuccessMessage(false)
    setShowUpdateStatusForm(false)
   }

  return (
    <>
    {openEmployeeInfoPopup&&<Popup show={openEmployeeInfoPopup} setShow={setOpenEmployeeInfoPopup} employeeID={candidate.Aident} rowData={candidate} />}
    {showSuccessMessage&&<TWDelayConfirmation show={showSuccessMessage} handleClose={handleCloseSuccessMessage} />}
    <Card className='margin-bottom' key={candidate.Aident}>
      <Card.Header>
          <h5>{candidate.FirstName+' '+candidate.LastName+' | '+candidate.Status}</h5>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col><p><b>Status: </b>{candidate.Status}</p></Col>
          <Col><p><b>Employee ID: </b>{candidate.Aident}</p></Col>
          <Col><p><b>Order ID: </b>{candidate.OrderID}</p></Col>
          <Col><p><b>Date Added: </b>{candidate.DateAdded.split('T')[0]}</p></Col>
          <Col sm={2}><Button variant='secondary' className='full-width' onClick={()=>setOpenEmployeeInfoPopup(true)}>Info</Button></Col>
        </Row>
        <Row>
          {!showUpdateStatusForm&&
          <Col sm={2}><Button className='full-width' onClick={()=>setShowUpdateStatusForm(true)}>Update Status</Button></Col>
          }
        </Row>

        {showUpdateStatusForm&&
        <Row>
          <Col sm={5}>
            <Form.Label>Status:</Form.Label>
            <Select 
              options={statusOptions} 
              value={selectedStatus} 
              onChange={(e)=>setSelectedStatus(e)} 
            />
          </Col>
          <Col sm={5}>
            <Form.Label>Notes</Form.Label>
            <Form.Control 
                type='textarea'
                className={'simple-input-editor'}
                value={note}
                onChange={(event) => setNote(event.target.value)}
              />
          </Col>
          <Col sm={2} className='submit-button-wrapper'>
            <Button variant='success' className='full-width' disabled={loading} onClick={()=>handlePost()}>{loading?<Spinner animation='border' size='sm' /> :'Submit'}</Button>
            {(status!==200&&error)&&
            <Alert>There was an error, please try again.</Alert>
            }

          </Col>
        </Row>
        }

      </Card.Body>
    </Card>
    </>
  )
}

export default CandidateCard