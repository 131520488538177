import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

function TooltipPopup({ show, setShow, reportTitle, reportDescription}) {
  return (
    <Modal show={show} onHide={setShow}>
    <Modal.Header closeButton>
      <Modal.Title>{reportTitle}</Modal.Title>
    </Modal.Header>
    <Modal.Body>{reportDescription}</Modal.Body>
    <Modal.Footer>
      <Button className='full-width' variant="secondary" onClick={()=>setShow(false)}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>
  )
}

export default TooltipPopup