import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Customer from './Customer';
import Vendor from './Vendor';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import './ImpersonatePopup.css'

function ImpersonatePopup({ show, setShow }) {
    const [selectedContact, setSelectedContact] = useState();
    const [successMessage, setSuccessMessage] = useState(false);
    
  return (
    <Modal show={show} onHide={setShow} size='lg'>
        <Modal.Header closeButton style={{width:'100%',display:'flex',flexDirection:'row', alignContent:'space-between'}}>
            <h4>Select Contact to Impersonate</h4>
        </Modal.Header>
        <Tabs defaultActiveKey="customer">
            <Tab eventKey="customer" title="Customer">
                <Customer 
                    setShow={setShow} 
                    selectedContact={selectedContact} 
                    setSelectedContact={setSelectedContact} 
                    successMessage={successMessage}
                    setSuccessMessage={setSuccessMessage}
                />
            </Tab>
            <Tab eventKey="vendor" title="Vendor">
                <Vendor  
                    selectedContact={selectedContact} 
                    setSelectedContact={setSelectedContact} 
                    successMessage={successMessage}
                    setSuccessMessage={setSuccessMessage}
                    />
            </Tab>
    </Tabs>
    </Modal>
  )
}

export default ImpersonatePopup