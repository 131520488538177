import React from 'react'
import { Alert, Button, Card, Form } from 'react-bootstrap'
import './Login.css'

const Login = ({ setUsername, setPassword, ValidateUser, error, loading }) =>{

    return(
        <Card className="padding">
            <Card.Title className="padding" as="h3">Login</Card.Title>
            <Card.Body>
            <Form className="margin-top" onSubmit={ValidateUser}>
                <Form.Label>Username <span style={{color: 'red'}}>*</span></Form.Label>
                <Form.Control onChange={(e)=>setUsername(e.target.value)}></Form.Control>
                <Form.Label>Password <span style={{color: 'red'}}>*</span></Form.Label>
                <Form.Control type='password' onChange={(e)=>setPassword(e.target.value)}></Form.Control>
                {loading?
                <Button disabled type='submit' className="login-button">Loading...</Button>
                :
                <Button type='submit' className="login-button">Login</Button>
                }
                {error?
                <Alert className='login-alert' variant={error.variant}>{error.message}</Alert>
                :null}
            </Form>
            </Card.Body>
        </Card>
    )
}

export default Login;