import React, { useState, useContext, useRef, useEffect } from 'react'
import CustomerContext from '../../../Context/CustomerContext';
import useFetchGet from '../../../Fetch/useFetchGet';
import AddJobOrder from './AddJobOrder'

function AddJobOrderLogic({ show, setShow, defaultJobOrderID}) {
  const [generalNotes, setGeneralNotes] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState();
  const [reqTitle, setReqTitle] = useState();
  const jobRequestTitle = useRef();
  const workSite = useRef();
  const pay = useRef();
  const [payError, setPayError] = useState();
  const job = useRef();
  const [jobError, setJobError] = useState();
  const codes = useRef();
  const [numberOfPositions, setNumberOfPositions] = useState('');
  const [confirmationPageIsOpen, setConfirmationPageIsOpen] = useState(false);
  const [jobOrderID, setJobOrderID] = useState();
  const { REACT_APP_API_URL } =process.env;
  const { cookies, formData, setFormData } = useContext(CustomerContext)
  const { data: defaultFormData } = useFetchGet((defaultJobOrderID?`${REACT_APP_API_URL}/Candidates/GetOrderRequestTemplate`:null),(defaultJobOrderID&&{OrderID:defaultJobOrderID}))

  useEffect(()=>{
    if(defaultFormData){
      let newDepartment = {
        CustomerID: defaultFormData.CustomerID,
        CustomerName:defaultFormData.CustomerName,
        DepartmentName:defaultFormData.Department,
        WorkSite:defaultFormData.WorkSite,
      }
      formData.Department = newDepartment;
      setFormData(formData)
      setSelectedDepartment(newDepartment)
    }
  // eslint-disable-next-line
  },[defaultFormData])
  
  const HandleSelectChange = (event, fieldName) => { 
      formData[fieldName] = event.value
      setFormData(formData)
  }

  const HandleSubmit = (event) => { 
    setLoading(true);
    let canPost = true;
    event.preventDefault();
    if(!formData.PayType||!formData.PayRate){
        setPayError('Lifting Requirement, Pay Type, and Pay Rate are required')
        canPost=false;
        pay.current.scrollIntoView()
        setLoading(false)
    }else if(!formData.StartDate||!formData.AssignmentEnd){
        setJobError('Start and End date are required')
        canPost=false;
        job.current.scrollIntoView()
        setLoading(false)
    }
    if(canPost){
      let postObj = BuildPostObj(formData)
      PostToTW(postObj)
    }
   }

   const BuildPostObj = (postObj) => {
    postObj.SubmitterID = cookies.get('customer').UserID;
    postObj.CustomerID = formData.Department.CustomerID;
    postObj.CustomerName = formData.Department.CustomerName;
    postObj.DepartmentID = formData.Department.CustomerID;
    postObj.Department = formData.Department.DepartmentName;
    postObj.OrderTitle = reqTitle;
    postObj.totalPositions = numberOfPositions;
    if(formData.Shift){
      postObj.ShiftStartTime = formData.Shift.shiftstarttime;
      postObj.ShiftEndTime = formData.Shift.shiftendtime;
      postObj.ShiftID = formData.Shift.ShiftID;
      postObj.ShiftNotes = formData.Shift.shiftNotes;
      postObj.Monday = formData.Shift.monday;
      postObj.Tuesday = formData.Shift.tuesday;
      postObj.Wednesday = formData.Shift.wednesday;
      postObj.Thursday = formData.Shift.thursday;
      postObj.Friday = formData.Shift.friday;
      postObj.Saturday = formData.Shift.saturday;
      postObj.Sunday = formData.Shift.sunday;
      postObj.Shift = formData.Shift.shift;
    }
    if(formData.JobTitle){
      postObj.JobTitleID = formData.JobTitle.skillCodeId;
      postObj.JobTitle = formData.JobTitle.jobDescription;
    }
    if(generalNotes){
      postObj.Notes = generalNotes;
    }
    if(formData.PO){
      postObj.POID = formData.PO.PoID;
      postObj.PO = formData.PO.PONumber;
    }
    return postObj;
   }


    const PostToTW = (postObj) => { 
      let token = cookies.get('customer').runAsCustomer?cookies.get('customer').runAsCustomer.Token:cookies.get('customer').Token
      fetch(`${REACT_APP_API_URL}/VMS/PostOrderToTW?token=${token}`,{
        method: 'POST',
        headers: {
            'Content-Type':'application/json',
          },
        body: JSON.stringify(postObj)
        })
        .then(res=>{
            if(res.status===200){
                return res.json()
            }else if(res.status===401){
             cookies.remove('customer', {path:'/'})
             window.location.reload();
           }else if(res.status===503||res.status===500){
             setTimeout(() => {
              PostToTW(postObj)
             }, 1000);
           }else if(!res.ok){
             return res.text().then(text=>{
               if(text==="Database 'Employnet' cannot be opened. It is in the middle of a restore."){
                 setTimeout(() => {
                  PostToTW(postObj)
                 }, 1000);
               }else{
                 throw Error(res.json())
               }
             })
           }
        })
        .then(data=>{
          setSelectedDepartment(null)
          setJobOrderID(data)
          setConfirmationPageIsOpen(true)
          setLoading(false);      
       })
       .catch(error=>{
          setLoading(false);
          console.error(error)
          window.alert(error)
       })
     }

    const HandleNumberOfPositionsChange = (e) => { 
      let value = parseInt(e.target.value)
        if(Number.isInteger(value)){
          setNumberOfPositions(value)
        }else if(e.target.value===""){
          setNumberOfPositions("")
        }
     }

    const handlePopupAndFormClose = () =>{
      setShow(false)
      setConfirmationPageIsOpen(false)
    }

  return (
      <AddJobOrder
          HandleSelectChange={HandleSelectChange}
          selectedDepartment={selectedDepartment}
          setSelectedDepartment={setSelectedDepartment}
          HandleSubmit={HandleSubmit}
          setGeneralNotes={setGeneralNotes}
          setReqTitle={setReqTitle}
          jobRequestTitle={jobRequestTitle}
          loading={loading}
          show={show}
          setShow={setShow}
          workSite={workSite}
          job={job}
          jobError={jobError}
          pay={pay}
          payError={payError}
          codes={codes}
          HandleNumberOfPositionsChange={HandleNumberOfPositionsChange}
          numberOfPositions={numberOfPositions}
          confirmationPageIsOpen={confirmationPageIsOpen}
          handlePopupAndFormClose={handlePopupAndFormClose}
          jobOrderID={jobOrderID}
          defaultFormData={defaultFormData}
      ></AddJobOrder>
  )
}

export default AddJobOrderLogic