import React, { useContext, useEffect, useState } from 'react'
import CustomerContext from '../../Context/CustomerContext'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import '../CustomComponents/Tabs/Tabs.css'
import WeeklyTab from '../CustomComponents/Tabs/WeeklyTab'
import MonthlyTab from '../CustomComponents/Tabs/MonthlyTab'
import QuarterlyTab from '../CustomComponents/Tabs/QuarterlyTab'
import DataTab from '../CustomComponents/Tabs/DataTab'

function StartsEndsDashboard() {
    const { REACT_APP_API_URL } = process.env;
    const { setCurrentPage } = useContext(CustomerContext)
    const [ selectedTab, setSelectedTab ] = useState('Weekly')
    const [ tabApiUrl ] = useState(`${REACT_APP_API_URL}/Reports/GetStartsEndsNetEmployeeLevel`);
    const [ tabRangeCharSeries ] = useState(['DateLabel','Starts','Ends','Net'])
    const [chartType] = useState('bar')
    const [reportDescription] = useState(<div><p>This report is displayed as a column graph.</p><p> The bottom or 'x' axis shows the weekending dates selected. The left or 'y' axis displays three data points, Starts, Ends, and Net Starts.</p><p> This report shows you the number of employees who started or ended their assignment during the selected week ending dates. The takeaway from this chart is Net Starts, which shows you whether your total employee count went up or down.</p></div>)
    const [seriesChartTypes] = useState([
        { colId: 'Starts', chartType: 'stackedColumn', secondaryAxis: false },
        { colId: 'Ends', chartType: 'stackedColumn', secondaryAxis: false },
        { colId: 'Net', chartType: 'line', secondaryAxis: false }
      ])
    
    const roundedTotal = (params) =>{
        let total = 0;
        params.values.forEach(value => {
            if(value){
                total += value
            }
        });
        total = total.toFixed(2)
        return Number(total);
    }

    const customThemes = {
        myCustomTheme: {
            baseTheme: 'ag-pastel',
            palette: {
              fills: ['#9ef57f', '#ff5543', '#3b3838'],
              strokes: ['#7b7b7b'],
            },
            overrides: {
                common: {
                    title: {
                        fontSize: 22,
                        fontFamily: 'Arial, sans-serif'
                    }
                }
            }
        }
    };

    const [columnDefs] = useState([
        { field: 'DateLabel', headerName:'Date', rowGroup:true,  chartDataType: 'category' },
        { field: 'Starts',chartDataType:'series', aggFunc: roundedTotal},
        { field: 'Ends',chartDataType:'series', aggFunc: roundedTotal},
        { field: 'Net',headerName:'Net Starts',chartDataType:'series', aggFunc: roundedTotal},
        { field: 'EmployeeName', headerName:'Employee'},
        { field: 'EmployeeId', headerName: 'Employee ID', chartDataType: 'category', filter: 'agSetColumnFilter' },
        { field: 'AlternateID', chartDataType: 'category', filter: 'agSetColumnFilter' },
        { field: 'Status', chartDataType: 'category'},
        { field: 'Shift', chartDataType: 'category' },
        { field: 'VendorName', filter: 'agSetColumnFilter', chartDataType: 'category'},
        { field: 'Worksite',filter: 'agSetColumnFilter', chartDataType: 'category',},
        { field: 'OrderType',filter: 'agSetColumnFilter',chartDataType:'category'},
        { field: 'Department',filter: 'agSetColumnFilter',chartDataType:'category'},
        { field: 'Supervisor',chartDataType:'category'},

    ])

    useEffect(()=>{
        setCurrentPage('Net Starts')
    },[setCurrentPage])

  return (
    <div>
        
        <Tabs
            activeKey={selectedTab}
            onSelect={(tab)=>setSelectedTab(tab)}
        >
        <Tab eventKey="Weekly" title="Weekly">
            {selectedTab===('Weekly')&&
                <WeeklyTab customThemes={customThemes} seriesChartTypes={seriesChartTypes} chartType={chartType} columnDefs={columnDefs} apiUrl={tabApiUrl} rangeChartSeries={tabRangeCharSeries} chartTitle='Weekly Net Starts' reportDescription={reportDescription} />
            }
        </Tab>
        <Tab eventKey="Monthly" title="Monthly">
            {selectedTab===('Monthly')&&
                <MonthlyTab customThemes={customThemes} seriesChartTypes={seriesChartTypes} chartType={chartType} columnDefs={columnDefs} apiUrl={tabApiUrl} rangeChartSeries={tabRangeCharSeries} chartTitle='Monthly Net Starts' reportDescription={reportDescription} />
            }
        </Tab>
        <Tab eventKey="Quarterly" title="Quarterly">
            {selectedTab===('Quarterly')&&
                <QuarterlyTab customThemes={customThemes} seriesChartTypes={seriesChartTypes} chartType={chartType} columnDefs={columnDefs} apiUrl={tabApiUrl} rangeChartSeries={tabRangeCharSeries} chartTitle='Quarterly Net Starts' reportDescription={reportDescription} />
            }
        </Tab>
        <Tab eventKey="Data" title="Data">
            {selectedTab==='Data'&&
                <DataTab columnDefs={columnDefs} apiUrl={tabApiUrl} chartTitle={'Net Starts'} reportDescription={reportDescription} />
            }
        </Tab>
        </Tabs>
    </div>
  )
}

export default StartsEndsDashboard