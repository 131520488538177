import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup'; 
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import useFetchPost from '../../Fetch/useFetchPost';
import { useState } from 'react';
import { useContext } from 'react';
import CustomerContext from '../../Context/CustomerContext';
import { useEffect } from 'react';
import TWDelayConfirmation from '../../Fetch/TWDelayConfirmation';

function AcceptRefuseButton({ orderIDs }) {
    const { cookies } = useContext(CustomerContext)
    const [vendorID] = useState(cookies.get('customer').VendorID?cookies.get('customer').VendorID:cookies.get('customer').runAsCustomer.VendorId);
    const [orderStatusParams, setOrderStatusParams] = useState();
    const [showDelayConfirmation, setShowDelayConfirmation] = useState(false);
    const { REACT_APP_API_URL } = process.env;
    const { status, loading, error } = useFetchPost((orderStatusParams&&`${REACT_APP_API_URL}/VMS/PostVendorOrderStatus`),orderStatusParams, orderIDs);

    useEffect(()=>{
        if(status===200){
            setShowDelayConfirmation(true)
        }
    // eslint-disable-next-line
    },[status])

    const handleClose = () => { setShowDelayConfirmation(false) }
    
  return (
    <>
    <ButtonGroup className='margin-top'>
        <Button variant='success' onClick={()=>setOrderStatusParams({vendorID, status:'A1'})}>{loading?<Spinner animation='border' size='sm'/>:'Accept'}</Button>
        
        <Dropdown className='refuse-button'>
            <Dropdown.Toggle className='refuse-button' variant="success" id="dropdown-basic">
                {loading?<Spinner animation='border' size='sm'/>:'Refuse'}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Item onClick={()=>setOrderStatusParams({vendorID, status:'R1'})}>Low Bill</Dropdown.Item>
                <Dropdown.Item onClick={()=>setOrderStatusParams({vendorID, status:'R2'})}>No Candidate</Dropdown.Item>
                <Dropdown.Item onClick={()=>setOrderStatusParams({vendorID, status:'R3'})}>Insufficient Information</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    </ButtonGroup>

    {showDelayConfirmation&&
        <TWDelayConfirmation show={showDelayConfirmation} handleClose={handleClose}  />
    }

    {(error&&status!==200)&&
        <Alert variant='warning'>There was an error, please try again</Alert>
    }
    </>
  )
}

export default AcceptRefuseButton