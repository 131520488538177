import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import useFetchGet from '../../../Fetch/useFetchGet';
import useFetchPost from '../../../Fetch/useFetchPost';
import TWDelayConfirmation from '../../../Fetch/TWDelayConfirmation';
import { useContext } from 'react';
import CustomerContext from '../../../Context/CustomerContext';

function UploadDocuments({ show, setShow, employeeID }) {
    const { cookies } = useContext(CustomerContext)
    const [customerID] = useState(cookies.get('customer').runAsCustomer?cookies.get('customer').runAsCustomer.CustomerId:cookies.get('customer').CustomerID)
    const [documentByteArray, setDocumentByteArray] = useState();
    const [selectedDocType, setSelectedDocType] = useState();
    const [fileName, setFileName] = useState();
    const [canPost, setCanPost] = useState(false);
    const [showTWDelayConfirmation, setShowTWDelayConfirmation] = useState(false);
    const {REACT_APP_API_URL} = process.env;
    const { data: documentTypes, loading: loadingDocumentTypes} = useFetchGet(`${REACT_APP_API_URL}/Utilities/GetCustomerRequiredDoc`,{CustomerID: customerID})
    const { status, loading, error } = useFetchPost((canPost&&`${REACT_APP_API_URL}/Utilities/UploadEmployeeDoc`),(selectedDocType&&{filename:fileName,doctypeid:selectedDocType.value.DocTypeID,employeeid:employeeID}),documentByteArray)

    useEffect(()=>{
        if(status&&status===200){
            setShowTWDelayConfirmation(true);
        }
    },[status])

    const handleSubmit = () => { 
        if(selectedDocType&&fileName&&documentByteArray){
            setCanPost(true)
        }
     }

    const handleClose = () => { 
        setShowTWDelayConfirmation(false);
        setShow(false)
     }

    const handleFileUpload = (e) =>{
        readFileDataAsBase64(e)
        .then(byteArray=>{
            setDocumentByteArray(byteArray.split(',')[1])
        })
    }

    const readFileDataAsBase64 = (e) => {
        const file = e.target.files[0];
        if(!selectedDocType.value.CustomerID){
            setFileName(file.name)
        }
    
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
    
            reader.onload = (event) => {
                resolve(event.target.result);
            };
    
            reader.onerror = (err) => {
                reject(err);
            };
    
            reader.readAsDataURL(file);
        });
    }

    const handleFormChange = (e) => { 
        setSelectedDocType(e)
        if(e.value.CustomerID){
            setFileName(e.value.Description)
        }
     }

  return (
    <>
    {showTWDelayConfirmation&&<TWDelayConfirmation show={showTWDelayConfirmation} handleClose={handleClose} />}
    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>Upload Documents</Modal.Header>
        <Modal.Body>
            <Form.Group className='margin-bottom' controlId="docType">
                <Form.Label>Form <span className="required">*</span></Form.Label>
                {loadingDocumentTypes?
                <Spinner animation='border' size='sm' />
                :
                <Select options={documentTypes&&documentTypes.map(docType=>{return{value:docType,label:docType.Description}})} value={selectedDocType} onChange={(e)=>handleFormChange(e)} />
                }
            </Form.Group>

            {selectedDocType&&
            <>
            <Form.Group className='margin-bottom' controlId="documentType">
                <Form.Label>Document Type</Form.Label>
                <Form.Control disabled value={selectedDocType&&selectedDocType.value.DocType} />
            </Form.Group>
            <Form.Group className='margin-bottom' controlId="documentName">
                <Form.Label>Document Name</Form.Label>
                <Form.Control disabled value={fileName} />
            </Form.Group>
            <Form.Group className='margin-bottom' controlId="document">
                <Form.Label>Document <span className="required">*</span></Form.Label>
                <Form.Control type="file" onInput={(e)=>handleFileUpload(e)} />
            </Form.Group>
            </>
            }

            {error&&
            <Alert>{error}</Alert>
            }
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={()=>handleSubmit()} disabled={!(selectedDocType&&fileName&&documentByteArray)}>{loading?<Spinner animation='border' size='sm'/>:'Submit'}</Button>
            <Button variant='secondary' onClick={()=>handleClose()}>Close</Button>
        </Modal.Footer>
    </Modal>
    </>
  )
}

export default UploadDocuments