import React, { useContext, useMemo, useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import CustomerContext from '../../Context/CustomerContext';
import useFetchPost from '../../Fetch/useFetchPost';
import useFetchGet from '../../Fetch/useFetchGet';
import ReactSelect from 'react-select';
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Spinner from 'react-bootstrap/Spinner'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import { BsDownload, BsQuestionCircle } from 'react-icons/bs'
import { chartToolPanelsDef } from '../CustomComponents/GraphGridTools'
import DepartmentDropdown from '../CustomComponents/Tabs/Dropdowns/DepartmentDropdown';
import OrderTypesDropdown from '../CustomComponents/Tabs/Dropdowns/OrderTypesDropdown';
import TooltipPopup from '../CustomComponents/Tabs/TooltipPopup';

const createCustomRangeChart = (gridApi, columns, querySelector, title, subTitle, type, seriesChartTypes, aggFunc) => {
    gridApi.createRangeChart({
      chartType: 'histogram',
      cellRange: {
        columns: columns,
      },      
      chartThemeOverrides: {
        histogram: {
          title: {
            enabled: true,
            text: title,
          },
          subtitle:{
            enabled: subTitle?true:false,
            text: subTitle
          },
          series: {
            bins: [
              [0,1],[1,2],[2,3],[3,4],[4,5],[5,6],[6,7],[7,8],[8,9],[9,10],[10,11],[11,12],[12,13],[13,14],[14,15],[15,16],[16,17],[17,18],[18,19],[19,20],[20,21],[21,22],[22,23],[23,24],[24,25],[25,26],[26,27],[27,28],[28,29],[29,30],[30,31],[31,32],[32,33],[33,34],[34,35],[35,36],[36,37],[37,38],[38,39],[39,40],[40,41],[41,42],[42,43],[43,44],[44,45],[45,46],[46,47],[47,48],[48,49],[49,50],[50,51],[51,52],[52,53],[53,54],[54,55],[55,56],[56,57],[57,58],[58,59],[59,60],[60,61],[61,62],[62,63],[63,64],[64,65],[65,66],[66,67],[67,68],[68,69],[69,70],[70,71],[71,72],[72,73],[73,74],[74,75],[75,76],[76,77],[77,78],[78,79],[79,80],[80,81],[81,82],[82,83],[83,84],[84,85],[85,86],[86,87],[87,88],[88,89],[89,90],[90,91],[91,92],[92,93],[93,94],[94,95],[95,96],[96,97],[97,98],[98,99]
            ],
            tooltip:{
              renderer: (params) => {
                return {
                  content: ('Number of Employees: '+params.yValue),
                  title: params.title
                }
              }
            }
          },
          axes:{
            number: {
              title: {
                enabled: true
              },
              bottom: {
                title: { 
                  text: 'Remaining on Assignment in Months' 
                },
              },
              left: {
                title: { 
                  text: 'Number of Employees' 
                },                  
              }
            },
          },
        },
      },
      chartContainer: document.querySelector(querySelector),
    });
  };


function WeeklyTab({ columnDefs, apiUrl, rangeChartSeries, chartTitle, chartType, seriesChartTypes, aggFunc, reportDescription }) {
    const { REACT_APP_API_URL } = process.env;
    const [dates, setDates] = useState();
    const { data: unformattedWeekendDates } = useFetchGet(`${REACT_APP_API_URL}/Reports/GetSundays`, {'weeks':'52'})
    const {cookies} = useContext(CustomerContext)
    const [orderTypeOptions, setOrderTypeOptions] = useState([]);
    const [vendorOptions, setVendorOptions] = useState([]);
    const [worksiteOptions, setWorksiteOptions] = useState([]);
    const [selectedDepartments, setSelectedDepartments] = useState();
    const [selectedWorksites, setSelectedWorksites] = useState();
    const [selectedOrderTypes, setSelectedOrderTypes] = useState();
    const [selectedVendors, setSelectedVendors] = useState();
    const [selectedBranches] = useState([(cookies.get('customer').runAsCustomer?cookies.get('customer').runAsCustomer.CustomerId:cookies.get('customer').CustomerID)])
    const [subTitle, setSubTitle] = useState();
    const [gridApi, setGridApi] = useState();
    const [endDate, setEndDate] = useState();
    const [postObj, setPostObj] = useState()
    const [showFilters, setShowFilters] = useState(false);
    const [tooltipPopupOpen, setTooltipPopupOpen] = useState(false);
    const { data: weeklyData, loading } = useFetchPost(((postObj&&(selectedDepartments||selectedWorksites))&&apiUrl), null, postObj)
    const [chartThemes] = useState(['myCustomTheme', 'ag-vivid'])
    const defaultColDef = useMemo(() => {
        return {
          flex: 1,
          editable: true,
          sortable: true,
          filter: 'agMultiColumnFilter',
          floatingFilter: true,
          resizable: true,
        };
      }, []);


      const customThemes = {
        myCustomTheme: {
            baseTheme: 'ag-pastel',
            palette: {
              fills: ['#9dc3e6', '#ed7d31', '#4472c4'],
              strokes: ['#7b7b7b'],
            },
            overrides: {
                common: {
                    legend: {
                      enabled: false,
                    },
                    series: {
                      xName: 'Luke',
                      yName: 'Romberg',
                    },
                    title: {
                        fontSize: 22,
                        fontFamily: 'Arial, sans-serif'
                    },
                }
            }
        }
    };

      useEffect(()=>{
        if(unformattedWeekendDates){
          let formattedWeekendDates = unformattedWeekendDates.map(obj=>{return {value:obj.TheDate,label:obj.DateValue}})
          setEndDate(formattedWeekendDates[0])
          setDates(formattedWeekendDates)
        }
      // eslint-disable-next-line
      },[unformattedWeekendDates])
  
      useEffect(()=>{
        if(dates&&selectedDepartments){
          let baseSubTitleString = (`Weekend Date: ${dates[0].value.split('T')[0]} | Department: ${selectedDepartments.value.DepartmentName}`)
          if(selectedWorksites&&(selectedWorksites.length>0)){
            let siteNames = selectedWorksites.map(site=>site.value.AddressDesc)
            siteNames.join(' ')
            baseSubTitleString += (' | Worksites: '+siteNames)
          }
          if(selectedOrderTypes&&(selectedOrderTypes.length>0)){
            let orderNames = selectedOrderTypes.map(site=>site.value)
            orderNames.join(' ')
            baseSubTitleString += (' | Order Types: '+orderNames)
          }
          setSubTitle(baseSubTitleString)
          if(!postObj){
            setPostObj({
              EndDate:dates[0].value,
              DateType: 'week',
              CustomerId: selectedDepartments?[selectedDepartments.value.CustomerID]:[],
              AddrId: selectedWorksites?selectedWorksites.map(obj=>obj.value.AddrID):[],
            })
          }
        }
      // eslint-disable-next-line
      },[dates,selectedDepartments, selectedOrderTypes, selectedWorksites])
  
      const buildFilterOptions = (params, filterInstance) => { 
        // grab all the columns from the grid
        // pull out the ones that have the chart type of category
        let siteValues = params.api.getFilterInstance(filterInstance).getFilterValues().map(site=>{return{value:site.trim(),label:site}})
        return siteValues
      }
  
      const setSelectedItems = (params, instanceName, defaultArray) => {
        let instance = params.api.getFilterInstance(instanceName)
        if(instance.getModel()){
            let selectedOptionsFromGrid = instance.getModel().values
            let formattedOptions = selectedOptionsFromGrid.map(string=>{return{value:string,label:string}})
            return formattedOptions;
        }else{
            return defaultArray
        }
      }
  
      const onFirstDataRendered = (params) => {
        createCustomRangeChart(params.api, rangeChartSeries,'#weeklyChart', chartTitle, subTitle, chartType, seriesChartTypes, aggFunc)

        let orderTypeOptions = buildFilterOptions(params, 'OrderType')
        setOrderTypeOptions(orderTypeOptions)
        setSelectedOrderTypes(orderTypeOptions)
  
        let vendorOptions = buildFilterOptions(params, 'VendorName')
        setVendorOptions(vendorOptions)
        setSelectedVendors(vendorOptions)
  
        let worksiteOptions = buildFilterOptions(params, 'Worksite')
        setWorksiteOptions(worksiteOptions)
        setSelectedWorksites(worksiteOptions)

        setGridApi(params.api)
        params.columnApi.autoSizeAllColumns();
      }
  
      
      const HandleDateChange = (e) => { 
        if(postObj){
            setEndDate(e)
            let newPostObj = {...postObj}
            newPostObj.EndDate = e.value;
            setPostObj(newPostObj)
        }
       }
  
      
      const HandleSelectChange = (e,selectName) => { 
          if(selectName==='department'){
            if(postObj){
              let newPostObj = {...postObj}
              newPostObj.CustomerId = [e.value.CustomerID]
              setPostObj(newPostObj)
            }
            setSelectedDepartments(e)
  
          }else if(selectName==='worksite'){
            setSelectedWorksites(e)
            updateFilterModel(e,'Worksite')
  
          }else if(selectName==='ordertype'){
            setSelectedOrderTypes(e)
            updateFilterModel(e,'OrderType')
  
          }else if(selectName==='vendor'){
            setSelectedVendors(e)
            updateFilterModel(e,'VendorName')
          }
       }

       const updateFilterModel = (event, filterInstance) => {
        let newModel = event.map(source=>source.label)
        let sourceInstance = gridApi.getFilterInstance(filterInstance)
        sourceInstance.setModel({values: newModel})
        sourceInstance.applyModel()
        gridApi.onFilterChanged();
       }
  
       const onBtnExport = () => {
          gridApi.exportDataAsCsv();
      };
  
      const onFilterChanged = (params) => {
        setSelectedOrderTypes(setSelectedItems(params, 'OrderType', orderTypeOptions))
        setSelectedVendors(setSelectedItems(params,'VendorName',vendorOptions))
        setSelectedWorksites(setSelectedItems(params,'Worksite',worksiteOptions))
      }

      return (
        <>
        {tooltipPopupOpen&&<TooltipPopup show={tooltipPopupOpen} setShow={setTooltipPopupOpen} reportTitle={chartTitle} reportDescription={reportDescription} />}
        <div>
            <div>
              <Row className='margin-bottom'>
                <Col>
                {loading&&
                <Alert>Loading data <Spinner animation='border' size='sm' /></Alert>
                }
                </Col>
                <Col sm={3}>
                    <ButtonGroup className='full-width'>
                      <Button title='Show all filters related to chart' variant="dark" onClick={()=>setShowFilters(!showFilters)}>
                        {showFilters?'Hide Filters':'Show Filters'}
                      </Button>
                    {weeklyData&&
                      <Button variant="dark" onClick={()=>onBtnExport()} title='Download grid data'><BsDownload /></Button>
                    }
                      <Button variant="dark" title='What is this chart?' onClick={()=>setTooltipPopupOpen(true)}><BsQuestionCircle/></Button>
                    </ButtonGroup>
                </Col>
              </Row>
              <Row className={showFilters?'show':'hide'}>
                  <Col>
                      <Form.Label>Weekend Date:</Form.Label>
                      {dates?
                        <ReactSelect disabled={loading} options={dates} value={endDate} onChange={(e)=>HandleDateChange(e,'end')} />
                      :
                        <Spinner animation='border' size='sm'/>
                      }
                  </Col>
                  <Col>
                      <Form.Label>Department:</Form.Label>
                      <DepartmentDropdown isMulti={false} selectedDepartments={selectedDepartments} onChange={(e)=>HandleSelectChange(e,'department')} branchIDs={selectedBranches}/>
                  </Col>
              </Row>
              <Row className={showFilters?'show':'hide'}>
                  <Col>
                      <Form.Label>Work Sites:</Form.Label>
                      <ReactSelect isMulti value={selectedWorksites} options={worksiteOptions} onChange={(e)=>HandleSelectChange(e,'worksite')} />
                  </Col>
                  <Col>
                      <Form.Label>Order Type:</Form.Label>
                      <OrderTypesDropdown isMulti={true} selectedOrderTypes={selectedOrderTypes} orderTypeOptions={orderTypeOptions} onChange={(e)=>HandleSelectChange(e,'ordertype')} />
                  </Col>
                  <Col>
                    <Form.Label>Vendor:</Form.Label>
                    <ReactSelect isMulti={true} value={selectedVendors} options={vendorOptions} onChange={(e)=>HandleSelectChange(e,'vendor')} />
                </Col>
              </Row>
            </div>
          <>
          {(selectedDepartments||selectedWorksites)?
            <>
            <div >
              <div id={'weeklyChart'} className="chart"></div>
            </div>
            <div className="ag-theme-alpine grid">
              <AgGridReact
                multiSortKey={'ctrl'}
                rowData={weeklyData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                chartThemes={chartThemes}
                customChartThemes={customThemes}
                enableRangeSelection={true}
                enableCharts={true}
                suppressAggFuncInHeader={true}
                onFirstDataRendered={onFirstDataRendered}
                chartToolPanelsDef={chartToolPanelsDef}
                onFilterChanged={onFilterChanged}
                ></AgGridReact>
              </div>
            </>
          :
          <>
            {cookies.get('customer').runAsCustomer?
            <Alert className='text-center'>
              <Alert.Heading>Please select a Department or WorkSite</Alert.Heading>
            </Alert>
            :
            <>
            <Alert className='text-center'>
              <Alert.Heading>You must impersonate a customer in order to see these reports</Alert.Heading>
              <p>In order to access the impersonation menu, please click the dropdown next to your name in the header</p>
            </Alert>
            </>
            }
          </>
          }
          </>
        </div>
        </>
      );
}

export default WeeklyTab