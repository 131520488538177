import React, { useContext, useEffect, useState } from 'react'
import CustomerContext from '../../Context/CustomerContext'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import '../CustomComponents/Tabs/Tabs.css'
import DataTab from '../CustomComponents/Tabs/DataTab'
import WeeklyTab from '../CustomComponents/Tabs/WeeklyTab'
import MonthlyTab from '../CustomComponents/Tabs/MonthlyTab'
import QuarterlyTab from '../CustomComponents/Tabs/QuarterlyTab'

function EndCategories() {
    const { REACT_APP_API_URL } = process.env;
    const { setCurrentPage } = useContext(CustomerContext)
    const [ selectedTab, setSelectedTab ] = useState('Weekly')
    const [ tabApiUrl ] = useState(`${REACT_APP_API_URL}/Reports/GetCustomerEndCategories`);
    const [ tabRangeChartSeries ] = useState(['DateLabel','Completed','Converted','Resigned','Terminated'])
    const [reportDescription] = useState(<div><p>This report is displayed as a column graph.</p><p> The left or 'y' axis shows the weekending dates selected. The bottom or 'x' axis displays the total number of employees.</p><p> This report shows you the number of employees who started, ended, started and ended (both in the same week), or are still on their assignment for each week .</p></div>)
    const [chartType] = useState('normalizedBar')
    
    const roundedTotal = (params) =>{
        let total = 0;
        params.values.forEach(value => {
            if(value){
                total += value
            }
        });
        total = total.toFixed(2)
        return Number(total);
    }

    
    const [columnDefs] = useState([
        { field: 'Completed',chartDataType:'series', aggFunc: roundedTotal},
        { field: 'Converted',chartDataType:'series', aggFunc: roundedTotal},
        { field: 'Resigned',chartDataType:'series', aggFunc: roundedTotal},
        { field: 'Terminated',chartDataType:'series', aggFunc: roundedTotal},
        { field: 'EndCategory', chartDataType: 'series' },
        { field: 'EndAttrition', chartDataType: 'series' },
        { field: 'EndReason', chartDataType: 'series' },
        { field: 'EmployeeId', headerName: 'Employee ID', chartDataType: 'category', filter: 'agSetColumnFilter' },
        { field: 'AlternateID', filter: 'agSetColumnFilter' },
        { field: 'EmployeeName', headerName:'Employee' },
        { field: 'DateLabel', headerName:'Date', rowGroup: true, chartDataType: 'category' },
        { field: 'Status', chartDataType: 'category' },
        { field: 'Shift', chartDataType: 'category' },
        { field: 'VendorName', filter: 'agSetColumnFilter', chartDataType: 'category' },
        { field: 'Worksite',filter: 'agSetColumnFilter', chartDataType: 'category' },
        { field: 'OrderType',filter: 'agSetColumnFilter',chartDataType:'category' },
        { field: 'Department',filter: 'agSetColumnFilter',chartDataType:'category' },
        { field: 'Supervisor',chartDataType:'category' }
    ])

    useEffect(()=>{
        setCurrentPage('End Categories')
    },[setCurrentPage])

  return (
    <div>
        
        <Tabs
            activeKey={selectedTab}
            onSelect={(tab)=>setSelectedTab(tab)}
        >
        <Tab eventKey="Weekly" title="Weekly">
            {selectedTab===('Weekly')&&
                <WeeklyTab columnDefs={columnDefs} apiUrl={tabApiUrl} rangeChartSeries={tabRangeChartSeries} chartTitle='Weekly End Categories' reportDescription={reportDescription} chartType={chartType} />
            }
        </Tab>
        <Tab eventKey="Monthly" title="Monthly">
            {selectedTab===('Monthly')&&
                <MonthlyTab columnDefs={columnDefs} apiUrl={tabApiUrl} rangeChartSeries={tabRangeChartSeries} chartTitle='Monthly End Categories' reportDescription={reportDescription} chartType={chartType} />
            }
        </Tab>
        <Tab eventKey="Quarterly" title="Quarterly">
            {selectedTab===('Quarterly')&&
                <QuarterlyTab columnDefs={columnDefs} apiUrl={tabApiUrl} rangeChartSeries={tabRangeChartSeries} chartTitle='Quarterly End Categories' reportDescription={reportDescription} chartType={chartType} />
            }
        </Tab>
        <Tab eventKey="Data" title="Data">
            {selectedTab==='Data'&&
                <DataTab columnDefs={columnDefs} apiUrl={tabApiUrl} chartTitle='End Categories' reportDescription={reportDescription} />
            }
        </Tab>
        </Tabs>
    </div>
  )
}

export default EndCategories