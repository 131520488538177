import React, { useContext, useState, useEffect } from 'react'
import CustomerContext from '../../Context/CustomerContext'
import useFetchGet from '../../Fetch/useFetchGet';
import HoursWorkedFinancialDetail from './HoursWorkedFinancialDetail'
import { buildGroupByDropdownOptions } from '../../SupplierReview/CustomComponents/GraphGridTools'

function HoursWorkedFinancialDetailLogic() {
    const { setCurrentPage, cookies } = useContext(CustomerContext);
    const {REACT_APP_API_URL} = process.env;
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [pinnedBottomRowData, setPinnedBottomRowData] = useState();
    const [gridApi, setGridApi] = useState();
    const [onlyActive, setOnlyActive] = useState(false);
    const [groupByOptions, setGroupByOptions] = useState();

    const [isVendor] = useState((cookies.get('customer').runAsCustomer&&cookies.get('customer').runAsCustomer.VendorId?true:false)||cookies.get('customer').IsVendor)
    const [isVMSCustomer] = useState(cookies.get('customer').runAsCustomer?cookies.get('customer').runAsCustomer.hasVMS:cookies.get('customer').hasVMS)

    const [defaultColDef] = useState({
      resizable: true,
      sortable: true,
      filter: 'agSetColumnFilter', 
      wrapHeaderText: true, 
      autoHeaderHeight: true, 
      width: 125,
      enableValue: true,
      enableRowGroup: true,
      enablePivot: true,
    });

    const FormatDate = (params) => { 
        if(params.value) return params.value.split('T')[0]
    }

    let USDollar = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
  });
  
  const dollarFormat = (params) =>{
      if(params.value||params.value===0){
          return USDollar.format(params.value)
      }
  }
    const [columnDefs] = useState([
    {headerName:'Customer',field:'CustomerName', chartDataType: 'category', rowGroup: true},
    {headerName:'Department',field:'DepartmentName', chartDataType: 'category'},
    {headerName:'Employee',field:'EmployeeName', chartDataType: 'category'},
    {field:'EmployeeID', chartDataType: 'category', filter: 'agSetColumnFilter' },
    {field:'AlternateID', chartDataType: 'category', filter: 'agSetColumnFilter' },
    {field:'WeekendDate', valueFormatter: FormatDate},
    {field:'Shift', chartDataType: 'category'},
    {field:'Supervisor', chartDataType: 'category'},
    {field:'EmployeePayRate',headerName:'EE Rate', valueFormatter: dollarFormat, filter: 'agNumberColumnFilter', cellClass: 'align-right'},
    {field:'VendorPayRate',headerName:'Vendor Rate', valueFormatter: dollarFormat, filter: 'agNumberColumnFilter', hide: (!isVMSCustomer&&!isVendor), cellClass: 'align-right'},
    {field:'CustomerPayRate',headerName:'Customer Rate', valueFormatter: dollarFormat, filter: 'agNumberColumnFilter', hide: isVendor, cellClass: 'align-right'},
    {field:'TotalHours',filter: 'agNumberColumnFilter', valueFormatter: (count)=>{return MakeDouble(count)}, cellClass: 'align-right', aggFunc: 'sum'},
    {field:'RegHours',filter: 'agNumberColumnFilter', valueFormatter: (count)=>{return MakeDouble(count)}, cellClass: 'align-right', aggFunc: 'sum'},
    {field:'OTHours',filter: 'agNumberColumnFilter', valueFormatter: (count)=>{return MakeDouble(count)}, cellClass: 'align-right', aggFunc: 'sum'},
    {field:'DTHours',filter: 'agNumberColumnFilter', valueFormatter: (count)=>{return MakeDouble(count)}, cellClass: 'align-right', aggFunc: 'sum'},
    {field:'TotalEmployeeRegPay', headerName:'Employee Reg', filter: 'agNumberColumnFilter', valueFormatter: dollarFormat, cellClass: 'align-right', aggFunc: 'sum'},
    {field:'TotalEmployeeOTPay', headerName:'Employee OT', filter: 'agNumberColumnFilter', valueFormatter: dollarFormat, cellClass: 'align-right', aggFunc: 'sum'},
    {field:'TotalEmployeeDTPay', headerName:'Employee DT', filter: 'agNumberColumnFilter', valueFormatter: dollarFormat, cellClass: 'align-right', aggFunc: 'sum'},
    {field:'TotalEmployeeOtherPay', headerName:'Employee Other', filter: 'agNumberColumnFilter', valueFormatter: dollarFormat, cellClass: 'align-right', aggFunc: 'sum'},
    {field:'TotalEmployeePay', headerName:'Employee Total', filter: 'agNumberColumnFilter', valueFormatter: dollarFormat, cellClass: 'align-right', aggFunc: 'sum'},
    {field:'TotalVendorRegPay', headerName:'Vendor Reg', filter: 'agNumberColumnFilter', valueFormatter: dollarFormat, cellClass: 'align-right', aggFunc: 'sum', hide: (!isVMSCustomer&&!isVendor)},
    {field:'TotalVendorOTPay', headerName:'Vendor OT', filter: 'agNumberColumnFilter', valueFormatter: dollarFormat, cellClass: 'align-right', aggFunc: 'sum', hide: (!isVMSCustomer&&!isVendor)},
    {field:'TotalVendorDTPay', headerName:'Vendor DT', filter: 'agNumberColumnFilter', valueFormatter: dollarFormat, cellClass: 'align-right', aggFunc: 'sum', hide: (!isVMSCustomer&&!isVendor)},
    {field:'TotalVendorOtherPay', headerName:'Vendor Other', filter: 'agNumberColumnFilter', valueFormatter: dollarFormat, cellClass: 'align-right', aggFunc: 'sum', hide: (!isVMSCustomer&&!isVendor)},
    {field:'TotalVendorPay', headerName:'Vendor Total', filter: 'agNumberColumnFilter', valueFormatter: dollarFormat, cellClass: 'align-right', aggFunc: 'sum', hide: (!isVMSCustomer&&!isVendor)},
    {field:'TotalRegBill', headerName:'Customer Reg', filter: 'agNumberColumnFilter', valueFormatter: dollarFormat, cellClass: 'align-right', aggFunc: 'sum', hide: isVendor},
    {field:'TotalOTBill', headerName:'Customer OT', filter: 'agNumberColumnFilter', valueFormatter: dollarFormat, cellClass: 'align-right', aggFunc: 'sum', hide: isVendor},
    {field:'TotalDTBill', headerName:'Customer DT', filter: 'agNumberColumnFilter', valueFormatter: dollarFormat, cellClass: 'align-right', aggFunc: 'sum', hide: isVendor},
    {field:'TotalOtherBill', headerName:'Customer Other', filter: 'agNumberColumnFilter', valueFormatter: dollarFormat, cellClass: 'align-right', aggFunc: 'sum', hide: isVendor},
    {field:'TotalBill', headerName:'Customer Total', filter: 'agNumberColumnFilter', valueFormatter: dollarFormat, cellClass: 'align-right', aggFunc: 'sum', hide: isVendor},
    {field:'VendorCheckNumber'},
    {field:'InvoiceNumber'},
    ])
    
    

    const { data, loading } = useFetchGet(((startDate&&endDate)&&`${REACT_APP_API_URL}/Reports/GetHoursWorkedDetailFinancial`),{startDate:(startDate&&startDate.value.TheDate),endDate:(endDate&&endDate.value.TheDate),CurrentlyActive:onlyActive})
    const { data: sundays } = useFetchGet(`${REACT_APP_API_URL}/Reports/Getsundays`,{weeks:52})

    useEffect(()=>{
      if(sundays){
        setStartDate({value:sundays[0], label:sundays[0].DateValue})
        setEndDate({value:sundays[0], label:sundays[0].DateValue})
      }
    // eslint-disable-next-line
    },[sundays])


    const MakeDouble = (int) => { 
      if(int.value||int.value===0){
        return Number(int.value).toLocaleString(undefined, {minimumFractionDigits: 2})
      }else{
        return ''
      }
     }

    useEffect(()=>{
        if(data){
          setPinnedBottomRowData([{
            TotalHours: `${Reduce(data, 'TotalHours')}`,
            RegHours: `${Reduce(data, 'RegHours')}`,
            OTHours: `${Reduce(data, 'OTHours')}`,
            DTHours: `${Reduce(data, 'DTHours')}`,
          }])
        }
    // eslint-disable-next-line
    },[data])

    useEffect(()=>{
      setCurrentPage('Hours Worked Financial Detail')
    },[setCurrentPage])

    const HandleGroupByDropdownChange = (e, gridApi) => { 
      // get all the column defs
      // find the one that matches the dropdown
      // add the row group attribute
      // remove it from all others
      // setColumnDefs to the updated column defs
      let columnDefs = gridApi.getColumnDefs();
      columnDefs.forEach(column=>{
        if(column.field===e.label||column.headerName===e.label){
          column.rowGroup = true;
          column.sort = 'desc';
        }else{
          column.rowGroup = false;
          column.sort = null;
        }
      })
      gridApi.setColumnDefs(columnDefs)
    }

    const Reduce = (data, dataName) => { 
       let total = data.reduce((last,current)=>last+current[dataName],0).toFixed(2)
       return total
     }

    const onGridReady = (params) => { 
        setGridApi(params.api)
    }

    const onFilterChanged = (e) => { 
        let data = [];
        gridApi.forEachNodeAfterFilter(node=>{
           data.push(node.data);
        })
        setPinnedBottomRowData([{
          TotalHours: `${Reduce(data, 'TotalHours')}`,
          RegHours: `${Reduce(data, 'RegHours')}`,
          OTHours: `${Reduce(data, 'OTHours')}`,
          DTHours: `${Reduce(data, 'DTHours')}`,
        }])
    }

    const onBtnExport = () => {
      gridApi.exportDataAsCsv();
  };

  const onFirstDataRendered = (params) => {
    setGroupByOptions(buildGroupByDropdownOptions(params))
    setGridApi(params.api)
  }

  return (
    <HoursWorkedFinancialDetail
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        data={data}
        onGridReady={onGridReady}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        pinnedBottomRowData={pinnedBottomRowData}
        onFilterChanged={onFilterChanged}
        startDate={startDate}
        endDate={endDate}
        sundays={sundays}
        onBtnExport={onBtnExport}
        onlyActive={onlyActive}
        setOnlyActive={setOnlyActive}
        loading={loading}
        onFirstDataRendered={onFirstDataRendered}
        groupByOptions={groupByOptions}
        gridApi={gridApi}
        HandleGroupByDropdownChange={HandleGroupByDropdownChange}
    ></HoursWorkedFinancialDetail>
  )
}

export default HoursWorkedFinancialDetailLogic