import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import Select from 'react-select'
import ShiftDaysLogic from '../ShiftDays/ShiftDaysLogic'

function Pay({ HandleSelectChange, multiplierCodes, compCodes, defaultFormData, HandlePayRateChange, HandleShiftChange, shifts, selectedShift, onMenuOpen }) {
  return (
    <>
    <Form.Label>Lifting Requirement<span>*</span> (20 pounds and above)</Form.Label>
    <Select defaultValue={defaultFormData&&{value:defaultFormData.LiftingRequirement,label:(defaultFormData.LiftingRequirement?'Yes':'No')}} onChange={(e)=>HandleSelectChange(e, 'LiftingRequirement')} options={[{value:true,label:'Yes'},{value:false,label:'No'}]} className='margin-bottom'/>

    <Form.Label>CPE (Computer Professional Exemption)</Form.Label>
    <Select defaultValue={defaultFormData?{value:defaultFormData.CPE,label:((defaultFormData.CPE==='true')?'Yes':'No')}:{value:'false',label:'No'}} onChange={(e)=>HandleSelectChange(e, 'CPE')} options={[{value:'true',label:'Yes'},{value:'false',label:'No'}]} className='margin-bottom'/>

    {/* <Form.Label>Multiplier Code:</Form.Label>
    {multiplierCodes?
    <>
    <Select onChange={(e)=>HandleSelectChange(e,'MultiplierCodeID')} options={multiplierCodes.map(obj=>{return{value:obj,label:obj.multiplierCode}})} />
    </>
    :
    <Spinner animation='border' />
    }
    
    <Form.Label>Comp Code:</Form.Label>
    {compCodes?
    <>
      <Select onChange={(e)=>HandleSelectChange(e,'WorkerCompCodeID')} options={compCodes.map(obj=>{return{value:obj,label:(obj.WorkerCompCode+' - '+obj.Description)}})} />
    </>
      :
      <Spinner animation='border' />
    } */}

    <Form.Label>Pay Type<span>*</span></Form.Label>
    <Select defaultValue={defaultFormData?{label:defaultFormData.PayType,value:defaultFormData.PayType}:{value:'Hourly',label:'Hourly'}} onChange={(e)=>HandleSelectChange(e, 'PayType')} options={[{value:'Hourly',label:'Hourly'},{value:'Salary',label:'Salary'},{value:'Daily',label:'Daily'}]} className='margin-bottom'/>

    <Row className='margin-bottom'>
      <Col><Form.Label>Pay Rate<span>*</span></Form.Label></Col>
      <Col><Form.Control defaultValue={defaultFormData&&defaultFormData.PayRate} type='number' max='5000' onChange={HandlePayRateChange} /></Col>
    </Row>
    {shifts&&
    <>
      <h6 className='text-center'>Shift</h6>
      <Select defaultValue={defaultFormData&&defaultFormData.Shift} onMenuOpen={onMenuOpen} onChange={HandleShiftChange} options={shifts} className='margin-bottom' />
      {selectedShift&&
      <>
        <ShiftDaysLogic selectedShift={selectedShift} />
        <Form.Label>Shift Notes</Form.Label>
        <Form.Control defaultValue={defaultFormData&&defaultFormData.ShiftNotes} readOnly value={selectedShift.shiftNotes} />
      </>}
    </>
    }
    </>
  )
}

export default Pay