import React from 'react'
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';  
import Col from 'react-bootstrap/Col';
import useFetchGet from '../../../Fetch/useFetchGet'
import { useEffect, useState } from 'react';

function Info({ employeeID }) {
    const { REACT_APP_API_URL } = process.env;
    const { data: resume, loading: loadingResume, status: resumeStatus, error: resumeError } = useFetchGet(`${REACT_APP_API_URL}/VMS/GetEmployeeResume`,{EmployeeID:employeeID})
    const { data: rawEmployeeInfo, loading: loadingEmployeeInfo, error: employeeInfoError} = useFetchGet(`${REACT_APP_API_URL}/VMS/GetEmployeeInfo`, {EmployeeID:employeeID})
    const [employeeInfo, setEmployeeInfo] = useState();

    useEffect(()=>{
        if(rawEmployeeInfo){
            setEmployeeInfo(rawEmployeeInfo[0])
        }
    },[rawEmployeeInfo])

    function base64ToArrayBuffer(base64) {
        const binaryString = window.atob(base64); // Comment this if not using base64
        const bytes = new Uint8Array(binaryString.length);
        return bytes.map(( byte, i) => binaryString.charCodeAt(i));
    }

    function createAndDownloadBlobFile(body, filename, extension) {
        const blob = new Blob([body]);
        const fileName = `${filename}.${extension}`;
        if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(blob, fileName);
        } else {
            const link = document.createElement('a');
            // Browsers that support HTML5 download attribute
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', fileName);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }
    
    const HandleDownloadResume = () => { 
        createAndDownloadBlobFile(base64ToArrayBuffer(resume.DocObject), resume.DocName, resume.FileType)
    }


  return (
    <Card>  
        <Card.Body>
            {employeeInfo&&
                <>
                    <h3>{employeeInfo.FirstName+' '+employeeInfo.LastName+' | '+employeeInfo.EmployeeID}</h3>
                    <Row>
                        <Col><p><b>Phone:</b></p> <p>{employeeInfo.CellPhoneNumber}</p></Col>
                        <Col><p><b>Email:</b></p> <p>{employeeInfo.EmailAddress}</p></Col>
                        <Col><p><b>Date Created:</b></p> <p>{employeeInfo.DateCreated&&employeeInfo.DateCreated.split('T')[0]}</p></Col>
                    </Row>
                    <Row>
                        <Col><p><b>City:</b></p> <p>{employeeInfo.City}</p></Col>
                        <Col><p><b>Zip:</b></p> <p>{employeeInfo.Zip}</p></Col>
                        <Col><p><b>Distance:</b></p> <p>{employeeInfo.Distance}</p></Col>
                    </Row>
                    <Row>
                        <Col><p><b>Assigned:</b></p> <p>{employeeInfo.Assigned}</p></Col>
                        <Col><p><b>Washed Status:</b></p> <p>{employeeInfo.WashedStatus}</p></Col>
                        <Col><p><b>Active:</b></p> <p>{employeeInfo.ImpliesActive?'Active':'Not Active'}</p></Col>
                    </Row>
                    <Row>
                        <Col><p><b>Last Pay Date:</b></p> <p>{employeeInfo.LastPayDate&&employeeInfo.LastPayDate.split('T')[0]}</p></Col>
                        <Col><p><b>Last Pay Count:</b></p> <p>{employeeInfo.LastPayCount}</p></Col>
                        <Col><p><b>Last Status:</b></p> <p>{employeeInfo.LastStatus}</p></Col>
                    </Row>
                    <Row>
                        <Col><p><b>Branch:</b></p> <p>{employeeInfo.BranchName}</p></Col>
                        <Col><p><b>Rep:</b></p> <p>{employeeInfo.RepFullName}</p></Col>
                        <Col><p><b>Can Call:</b></p> <p>{employeeInfo.DoNotCall?'Do not call':'Okay to call'}</p></Col>
                    </Row>
                </>
            }
            {loadingEmployeeInfo&&
                <Spinner animation='border'/>
            }
            {employeeInfoError&&
                <Alert variant='warning'>
                    <p>There was an error, please try again</p>
                    <p>{employeeInfoError}</p>
                </Alert>
            }
            <Row>
                <Col sm={2}>
                    <Button disabled={(loadingResume||(resumeStatus===204))} onClick={()=>HandleDownloadResume()} className='full-width'>{loadingResume?<Spinner animation='border'size='sm'/>:'Download Resume'}</Button>
                </Col>
                <Col>
                    {(resumeStatus===204)&&
                    <Alert>Employee has no resume available for download</Alert>}
                    {resumeError&&
                        <Alert variant='warning'>
                            <p>There was an error, please try again</p>
                            <p>{resumeError}</p>
                        </Alert>
                    }
                </Col>
            </Row>
        </Card.Body>
    </Card>
  )
}

export default Info