import React, { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Badge from 'react-bootstrap/Badge';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import CustomerContext from '../../Context/CustomerContext';
import Popup from './Popup/Popup';
import Popover from 'react-bootstrap/Popover';  
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import './Employees.css';
import useFetchGet from '../../Fetch/useFetchGet';
import AddEmployeeForm from './AddEmployeeForm/AddEmployeeForm';
import AddCandidatePopup from './AddCandidatePopup/AddCandidatePopup';
import { BsBugFill, BsDownload, BsQuestionCircleFill } from 'react-icons/bs';

function Employees() {
    const { cookies, setCurrentPage } = useContext(CustomerContext)
    const { REACT_APP_API_URL } = process.env;
    const [defaultColDef] = useState({ resizable: true, sortable: true, filter: 'agSetColumnFilter' })
    const [openAddEmployee, setOpenAddEmployee] = useState(false);
    const [openAddCandidate, setOpenAddCandidate] = useState(false);
    const [totalAssigned, setTotalAssigned] = useState();
    const [totalAvailable, setTotalAvailable] = useState();
    const [gridApi, setGridApi] = useState();
    const [statusOptions, setStatusOptions] = useState();
    const [showPopup, setShowPopup] = useState();
    const [clickedRowData, setClickedRowData] = useState();
    const [selectedRows, setSelectedRows] = useState([]);
    const [customerID] = useState(cookies.get('customer').runAsCustomer?cookies.get('customer').runAsCustomer.CustomerId:cookies.get('customer').CustomerID)
    const [isVendor] = useState((cookies.get('customer').runAsCustomer&&cookies.get('customer').runAsCustomer.VendorId)||cookies.get('customer').IsVendor)
    const { data: employees } = useFetchGet(`${REACT_APP_API_URL}/VMS/GetVMSEmployees`,{CustomerID:isVendor?0:customerID})
    const [columnDefs] = useState([
        isVendor?{checkboxSelection:true, headerCheckboxSelection: true, width: 50}:{hide: true},
        {field:'VendorName',headerName:'Vendor Name'},
        {field:'LastName',headerName:'Last Name'},
        {field:'FirstName',headerName:'First Name'},
        {field:'EmployeeID',headerName:'Employee ID'},
        {field:'City',headerName:'City'},
        {field:'Phone',headerName:'Phone'},
        {field:'Email',headerName:'Email'},
        {field:'EmployeeCreated',headerName:'Employee Created', filter: 'agDateColumnFilter', valueFormatter: (params) => {
            return params.value?params.value.split('T')[0]:''
          }, 
        },
        {field:'AssignStatus',headerName:'Status'},
    ]);

    useEffect(()=>{
        setCurrentPage('Employees')
    },[setCurrentPage])

    const onFirstDataRendered = (params) => { 
        // Build the array of options
        // When a filter button is selected, push only that option to the filter 
        // When the All button is selected, push the whole array to the filter
        // This means we need an All Filter Options array and a Selected Options array
        let orderTypeOptions = params.api.getFilterInstance('AssignStatus').getFilterValues()
        setStatusOptions(orderTypeOptions)

        setGridApi(params.api)
        params.api.sizeColumnsToFit();
     }

     const onRowDataUpdated = (params) => { 
        CalculateStatusAmounts(params)
      }

    const onFilterChanged = (params) => { 
        CalculateStatusAmounts(params)
     }

    const CalculateStatusAmounts = (params) => { 
        let newCount = 0;
        let acceptedCount = 0;
        params.api.forEachNode(node=>{
            if(node.data.AssignStatus==='Assigned'){
                newCount+=1
            // }else if(node.data.AssignStatus==='Candidate'){
            }else{
                acceptedCount+=1
            }
        })
        setTotalAssigned(newCount)
        setTotalAvailable(acceptedCount)
     }

    const HandleFilterChange = (statusName) => { 
        if(statusName==='All'){
            let newModel = statusOptions
            let sourceInstance = gridApi.getFilterInstance('AssignStatus')
            sourceInstance.setModel({values: newModel})
            sourceInstance.applyModel()
            gridApi.onFilterChanged();
        }else{
            let newModel = [statusName]
            let sourceInstance = gridApi.getFilterInstance('AssignStatus')
            sourceInstance.setModel({values: newModel})
            sourceInstance.applyModel()
            gridApi.onFilterChanged();
        }
    }
    
    const onCellClicked = (params) => { 
        if(params.colDef.field){
            setClickedRowData(params.data)
            setShowPopup(true);
        }
     }

     const onRowSelected = (params) => { 
        // this stops the row from being selected if you click a cell other than the checkbox
        if(!params.colDef){
            if(params.node.selected){
                let newSelectedRows = [...selectedRows, params.node]
                setSelectedRows(newSelectedRows)
            }else{
                if(selectedRows.length===1){
                    setSelectedRows([])
                }else{
                    let newSelectedRows = selectedRows.splice(selectedRows.indexOf(params.node),1)
                    setSelectedRows(newSelectedRows)
                }
            }
        }
     }

     const onBtnExport = () => {
        gridApi.exportDataAsCsv();
    };

    const simplePopover = (text)=>{
        return <Popover id="popover-basic">
                    <Popover.Body>
                        {text}
                    </Popover.Body>
                </Popover>
      };

  return (
    <>
    {showPopup&&<Popup show={showPopup} setShow={setShowPopup} employeeID={clickedRowData.EmployeeID} rowData={clickedRowData} />} 
    {openAddEmployee&&<AddEmployeeForm show={openAddEmployee} setShow={setOpenAddEmployee} />}
    {openAddCandidate&&<AddCandidatePopup show={openAddCandidate} setShow={setOpenAddCandidate} employees={selectedRows} />}
    <div className='filter-wrapper'>
        <Nav variant="pills" defaultActiveKey="All">
            <Nav.Item>
                <Nav.Link eventKey="All" onClick={()=>HandleFilterChange('All')}>All</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey="Assigned" onClick={()=>HandleFilterChange('Assigned')}>Assigned <Badge bg='secondary'>{totalAssigned}</Badge></Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link eventKey="Available" onClick={()=>HandleFilterChange('Candidate')}>Unassigned <Badge bg='secondary'>{totalAvailable}</Badge></Nav.Link>
            </Nav.Item>
            <OverlayTrigger delay={500} placement="right" overlay={simplePopover("Unassigned includes both 'Candidate' and 'Available' status")}>
                <div className='status-tooltip-wrapper'>
                    <BsQuestionCircleFill />
                </div>
            </OverlayTrigger>
        </Nav>
        {/* Only allow Vendors, or people impersonating vendors, to create new employees */}
        <ButtonGroup className='filter-group-new-order-button'>
            {isVendor&&
                <Button onClick={()=>setOpenAddEmployee(true)}>Add Employee</Button>
            }
            {employees&&
                <Button variant="dark" onClick={()=>onBtnExport()} title='Download grid data'><BsDownload /></Button>
            }
            <Button variant="dark" title='Report a bug' onClick={(e) => {
                window.location.href = `mailto:${'Bug Reporting - DevMo! <01bd0080.employnet.com@amer.teams.ms>'}` 
                e.preventDefault()
            }}><BsBugFill /></Button>
        </ButtonGroup>
    </div>
    {(isVendor&&(selectedRows&&selectedRows.length>0))&&
        <Button onClick={()=>setOpenAddCandidate(true)}>{(selectedRows.length===1)?'Add Employee To Order':'Add Employees To Order'}</Button>
    }
    <div id="myGrid" className="ag-theme-alpine vh-60 margin-top">
        <AgGridReact
            multiSortKey={'ctrl'}
            suppressExcelExport={true}
            popupParent={document.body}
            defaultColDef={defaultColDef}
            rowData={employees}
            columnDefs={columnDefs}
            onRowDataUpdated={onRowDataUpdated}
            onFilterChanged={onFilterChanged}
            onFirstDataRendered={onFirstDataRendered}
            onCellClicked={onCellClicked}
            rowSelection='multiple'
            onRowSelected={onRowSelected}
            suppressRowClickSelection={true}
        ></AgGridReact>
    </div>
    </>
  )
}

export default Employees