import React, { useContext, useEffect, useState } from 'react'
import CustomerContext from '../../Context/CustomerContext'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import '../CustomComponents/Tabs/Tabs.css'
import WeeklyTab from '../CustomComponents/Tabs/WeeklyTab'
import MonthlyTab from '../CustomComponents/Tabs/MonthlyTab'
import QuarterlyTab from '../CustomComponents/Tabs/QuarterlyTab'
import DataTab from '../CustomComponents/Tabs/DataTab'

function LengthOfAssignmentEnded() {
    const { REACT_APP_API_URL } = process.env;
    const { setCurrentPage } = useContext(CustomerContext)
    const [ selectedTab, setSelectedTab ] = useState('Weekly')
    const [ tabApiUrl ] = useState(`${REACT_APP_API_URL}/Reports/GetCustomerTenure`);
    const [ tabRangeCharSeries ] = useState(['DateLabel','TenureMonths'])
    const [reportDescription] = useState(<div><p>This report is displayed as a bar graph.</p><p>The bottom or 'x' axis displays the employees average total days on assignment, represented as months to make it more digestible. The left or 'y' axis shows the weekending dates selected.</p><p> This report shows the average number of months worked for each employee whose assignment ended during the selected weekend dates.</p></div>)
    
    const FormatDate = (params) => { 
        if(params.value) return params.value.split('T')[0]
     }

    const [columnDefs] = useState([
        { field: 'DateLabel', headerName:'Date', rowGroup:true,  chartDataType: 'category' },
        { field: 'TenureMonths', headerName: 'Average LOA Ended in Months',chartDataType:'series', aggFunc: 'avg', valueFormatter: (params)=>{return params.value.value?params.value.value.toFixed(2):''}},
        { field: 'EmployeeName', headerName:'Employee'},
        { field: 'EmployeeId', headerName: 'Employee ID', chartDataType: 'category', filter: 'agSetColumnFilter' },
        { field: 'AlternateID', chartDataType: 'category', filter: 'agSetColumnFilter' },
        { field: 'Shift', chartDataType: 'category' },
        { field: 'VendorName', filter: 'agSetColumnFilter', chartDataType: 'category'},
        { field: 'Worksite',filter: 'agSetColumnFilter', chartDataType: 'category',},
        { field: 'OrderType',filter: 'agSetColumnFilter',chartDataType:'category'},
        { field: 'Department',filter: 'agSetColumnFilter',chartDataType:'category'},
        { field: 'Supervisor',chartDataType:'category'},
        { field: 'StartDate', filter: 'agSetColumnFilter', chartDataType: 'category', valueFormatter: FormatDate},
        { field: 'EndDate', filter: 'agSetColumnFilter', chartDataType: 'category', valueFormatter: FormatDate},
    ])

    useEffect(()=>{
        setCurrentPage('LOA Ended')
    },[setCurrentPage])


  return (
    <div>
        
        <Tabs
            activeKey={selectedTab}
            onSelect={(tab)=>setSelectedTab(tab)}
        >
        <Tab eventKey="Weekly" title="Weekly">
            {selectedTab===('Weekly')&&
                <WeeklyTab aggFunc={'avg'} columnDefs={columnDefs} apiUrl={tabApiUrl} rangeChartSeries={tabRangeCharSeries} chartTitle='Average Length Of Assignment - Ended' reportDescription={reportDescription} />
            }
        </Tab>
        <Tab eventKey="Monthly" title="Monthly">
            {selectedTab===('Monthly')&&
                <MonthlyTab columnDefs={columnDefs} apiUrl={tabApiUrl} rangeChartSeries={tabRangeCharSeries} chartTitle='Average Length Of Assignment - Ended' reportDescription={reportDescription} />
            }
        </Tab>
        <Tab eventKey="Quarterly" title="Quarterly">
            {selectedTab===('Quarterly')&&
                <QuarterlyTab columnDefs={columnDefs} apiUrl={tabApiUrl} rangeChartSeries={tabRangeCharSeries} chartTitle='Average Length Of Assignment - Ended' reportDescription={reportDescription} />
            }
        </Tab>
        <Tab eventKey="Data" title="Data">
            {selectedTab==='Data'&&
                <DataTab columnDefs={columnDefs} apiUrl={tabApiUrl} chartTitle={'LOA Ended'} reportDescription={reportDescription} />
            }
        </Tab>
        </Tabs>
    </div>
  )
}

export default LengthOfAssignmentEnded