import React, { useContext, useEffect, useState } from 'react'
import CustomerContext from '../../Context/CustomerContext';
import useFetchGet from '../../Fetch/useFetchGet';
import TimeclockDaily from './TimeclockDaily';
import { formatBool, formatDate, onBtnExport } from '../../GridTools/GridTools';

function TimeclockDailyLogic() {
    const [today] = useState(new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toJSON().split('T')[0])
    const [date, setDate] = useState(today);
    const [gridApi, setGridApi] = useState();
    const [pinnedBottomRowData, setPinnedBottomRowData] = useState([{WeekendDate:"2022-07-24T00:00:00",WorkDate:"2022-07-21T00:00:00",CustomerName:"Bay Photo"}]);
    const [defaultColDef] = useState({
        resizable: true,
        sortable: true,
        filter: 'agSetColumnFilter', 
        wrapHeaderText: true, 
        autoHeaderHeight: true, 
        width: 125
      });
    const [columnDefs] = useState([
      {headerName:'Customer',field:'CustomerName'},
      {headerName:'Department',field:'DepartmentName'},
      {headerName:'Worksite',field:'WorkSite'},
      {headerName:'Employee',field:'EmployeeName'},
      {headerName:'Vendor',field:'VendorName'},
      {headerName:'Weekend Date',field:'WeekendDate',valueFormatter: formatDate},
      {headerName:'Work Day',field:'WorkDate',valueFormatter: formatDate},
      {headerName:'Approved', field:'IsApproved', valueFormatter: formatBool},
      {headerName:'Start',field:'StartTime'},
      {headerName:'End',field:'EndTime'},
      {headerName:'Total Hours',field:'TotalHours',filter: 'agNumberColumnFilter'},
      {headerName:'Reg Hours',field:'RegHours',filter: 'agNumberColumnFilter'},
      {headerName:'OT Hours',field:'OTHours',filter: 'agNumberColumnFilter'},
      {headerName:'DT Hours',field:'DTHours',filter: 'agNumberColumnFilter'},
      {headerName:'Alternate ID',field:'Alternate ID'},
      {headerName:'Employee ID',field:'EmployeeID'},
      {headerName:'Assignment ID',field:'AssignmentID'},
    ])
    const {setCurrentPage} = useContext(CustomerContext)
    const {REACT_APP_API_URL} = process.env;
    const { data, error } = useFetchGet(`${REACT_APP_API_URL}/Reports/GetEmployeeDailyHours`,{'date':date})

    useEffect(()=>{
      setCurrentPage('Timeclock Daily')
    })

    useEffect(()=>{
      if(data){
        let totalHours = data.reduce((last, current)=>{return last + current.TotalHours},0)        
        let uniqueIDCount = data.filter((element, index) => index===data.findIndex(elem => element.EmployeeID===elem.EmployeeID))
        setPinnedBottomRowData([{
          EmployeeName: `Headcount: ${uniqueIDCount.length}`,
          TotalHours: `${totalHours.toFixed(2)}`,
          RegHours: `${data.reduce((last,current)=>last+current.RegHours,0).toFixed(2)}`,
          OTHours: `${data.reduce((last,current)=>last+current.OTHours,0).toFixed(2)}`,
          DTHours: `${data.reduce((last,current)=>last+current.DTHours,0).toFixed(2)}`
        }])
      }
      },[data])

    const onGridReady = (params) => { 
        setGridApi(params.api)
        params.columnApi.autoSizeAllColumns();
    }

    const onFilterChanged = (e) => { 
      let data = [];
      gridApi.forEachNodeAfterFilter(node=>{
         data.push(node.data);
      })
      let totalHours = data.reduce((last, current)=>{return last + current.TotalHours},0)        
      let uniqueIDCount = data.filter((element, index) => index===data.findIndex(elem => elem.EmployeeID===element.EmployeeID))
      setPinnedBottomRowData([{
        EmployeeName: `Headcount: ${uniqueIDCount.length}`,
        TotalHours: `${totalHours.toFixed(2)}`,
        RegHours: `${data.reduce((last,current)=>{
          if(current.RegHours){
            return last+current.RegHours
          }
          return last+0;
        },0).toFixed(2)}`,
        OTHours: `${data.reduce((last,current)=>last+current.OTHours,0).toFixed(2)}`,
        DTHours: `${data.reduce((last,current)=>last+current.DTHours,0).toFixed(2)}`
      }])
     }

  return (
    <TimeclockDaily
        setDate={setDate}
        defaultColDef={defaultColDef} 
        rowData={data}
        columnDefs={columnDefs} 
        onGridReady={onGridReady}
        onBtnExport={onBtnExport}
        gridApi={gridApi}
        error={error}
        today={today}
        pinnedBottomRowData={pinnedBottomRowData}
        onFilterChanged={onFilterChanged}
    ></TimeclockDaily>
  )
}

export default TimeclockDailyLogic