import React, { useEffect, useState } from 'react';
import NavDropdown from 'react-bootstrap/NavDropdown'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import './Header.css';

export default function Header ({ appName, currentPage, loggedInUser, HandleLogout, MenuOptions, OpenImpersonatePopup, StopImpersonating }) {
  const [parsedMenuOptions, setParsedMenuOptions] = useState();

    useEffect(()=>{
      if(MenuOptions){
        ParseMenuOptions(MenuOptions)
      }
      // eslint-disable-next-line
    },[MenuOptions])

    const ParseMenuOptions = (options) => { 
      let parsed = [];
      options.forEach((option,index)=>{
        if(option.IsDropDown===1){
          parsed.push(ParseDropdownMenuOption(option,index))
        }else{
          parsed.push(<Nav.Link key={index} href={option.LinkAddress}>{option.DisplayName}</Nav.Link>)
        }
      })
      setParsedMenuOptions(parsed)
     }

    const ParseDropdownMenuOption = (option, index) => { 
        let dropdownOptions = option.Tree.map((option,index)=>{
          if(option.IsDropDown===1){
            let listItem = ParseNestedMenuOptions(option, index)
            return listItem
          }else{
            return <NavDropdown.Item className='dropdown-option' key={index} href={option.LinkAddress}>{option.DisplayName}</NavDropdown.Item>
          }
        })  

          return <NavDropdown key={index} title={option.DisplayName}>{dropdownOptions}</NavDropdown> 
    }

    const ParseNestedMenuOptions = (option,index) => { 
      let dropdownOptions = option.Tree.map((option,index)=>{
        return <NavDropdown.Item className='nested-dropdown-option' key={'nested'+index} href={option.LinkAddress}>{option.DisplayName}</NavDropdown.Item>
      })
      return <div><h6 className='nested-dropdown-wrapper'>{option.DisplayName}</h6><ul key={index} className='nested-dropdown'>{dropdownOptions}</ul></div>
     }

    return (
      <header>
        <Navbar bg="light" expand="md" className='navbar-container'>
            <Navbar.Brand href="/">
              <h5>{appName} {currentPage ? (' > '+currentPage) : null}</h5>            
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />

            <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                {loggedInUser?
                  <Nav className="me-auto navbar-nav">
                    {parsedMenuOptions}
                    <NavDropdown.Divider />
                    <Nav.Link onClick={HandleLogout}>Logout</Nav.Link>
                  </Nav>
                :
                  <Nav className="me-auto navbar-nav">
                    <Nav.Link href="/login">Login</Nav.Link>
                  </Nav>
                }
                {loggedInUser&&
                <>
                  {loggedInUser.IsServiceRep?
                  <DropdownButton variant={loggedInUser.runAsCustomer?'dark':'secondary'} className='full-name' title={loggedInUser.runAsCustomer?`Impersonating ${loggedInUser.runAsCustomer.ContactName}`:loggedInUser.FullName}>
                    {loggedInUser.runAsCustomer?
                    <>
                    <Dropdown.Item onClick={()=>OpenImpersonatePopup(true)}>Change Impersonation</Dropdown.Item>
                    <Dropdown.Item onClick={()=>StopImpersonating()}>Stop Impersonating</Dropdown.Item>
                    </>
                    :
                    <Dropdown.Item onClick={()=>OpenImpersonatePopup(true)}>Impersonate</Dropdown.Item>
                    }
                  </DropdownButton>
                  :
                    <h6 className='full-name'>{loggedInUser.FullName}</h6>
                  }
                </>
                }
            </Navbar.Collapse>
        </Navbar>
      </header>
    );
}
