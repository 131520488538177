import React, { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Badge from 'react-bootstrap/Badge';
import Nav from 'react-bootstrap/Nav';
import Popover from 'react-bootstrap/Popover';
import CustomerContext from '../../Context/CustomerContext';
import Popup from './Popup/Popup';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';  
import useFetchGet from '../../Fetch/useFetchGet';
import AddJobOrderLogic from './AddJobOrder/AddJobOrderLogic';
import AcceptRefuseButton from './AcceptRefuseButton';
import './Orders.css';
import { BsBugFill, BsDownload, BsQuestionCircleFill } from 'react-icons/bs';
import { formatPercentage } from '../../GridTools/GridTools';

function Orders() {
    const { cookies, setCurrentPage } = useContext(CustomerContext)
    //TW Order Status counts
    const [totalUnfilled, setTotalUnfilled] = useState();
    const [totalFilled, setTotalFilled] = useState();
    const [totalClosed, setTotalClosed] = useState();
    const [statusOptions, setStatusOptions] = useState();
    const [statusActiveKey, setStatusActiveKey] = useState('All');
    //Vendor Offer Status counts
    const [totalDistributed, setTotalDistributed] = useState();
    const [totalAccepted, setTotalAccepted] = useState();
    const [totalRefused, setTotalRefused] = useState();
    const [totalUndistributed, setTotalUndistributed] = useState();
    const [vendorStatusOptions, setVendorStatusOptions] = useState();
    const [vendorActiveKey, setVendorActiveKey] = useState('All');

    const [isVendor] = useState((cookies.get('customer').runAsCustomer&&cookies.get('customer').runAsCustomer.VendorId?true:false)||cookies.get('customer').IsVendor)
    const [IsCustomerAdmin] = useState((cookies.get('customer').runAsCustomer&&cookies.get('customer').runAsCustomer.IsCustomerAdmin)||cookies.get('customer').IsCustomerAdmin)
    const [isVMSCustomer] = useState(cookies.get('customer').runAsCustomer?cookies.get('customer').runAsCustomer.hasVMS:cookies.get('customer').hasVMS)

    const [gridApi, setGridApi] = useState();

    const [showPopup, setShowPopup] = useState(false);
    const [showAddNewOrder, setShowAddNewOrder] = useState(false);

    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState();

    const { REACT_APP_API_URL } = process.env;
    const { data: orders } = useFetchGet(`${REACT_APP_API_URL}/VMS/GetVMSOrderDetail`,{VendorStatus: 'All'})

    let USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    const dollarFormat = (params) =>{
        if(params.value||params.value===0){
            return USDollar.format(params.value)
        }
    }
    const formatDate = (params) => params.value? params.value.split('T')[0]:''
    const [defaultColDef] = useState({ resizable: true, sortable: true, filter: 'agSetColumnFilter', wrapHeaderText: true, autoHeaderHeight: true, width: 125, aggFunc: 'first'})
    const [columnDefs] = useState([
        isVendor?{checkboxSelection:true, headerCheckboxSelection: true, width: 50}:{hide: true},
        {field:'OrderID',headerName:'Order ID', rowGroup: true, cellRenderer: 'agGroupCellRenderer', showRowGroup: true, width: 150},
        {field:'JobTitle',headerName:'Job Title'},
        {field:'DepartmentName',headerName:'Department'},
        {field:'OrderStatus',headerName:'TW Order Status'},
        isVMSCustomer?{field:'VendorName',headerName:'Vendor'}:{hide:true},
        isVMSCustomer?{field:'VendorOfferStatus',headerName:'Vendor Offer Status'}:{hide:true},
        isVMSCustomer?{field:'VendorRefuseReason'}:{hide:true},
        {field:'StartDate',headerName:'Start Date', valueFormatter: formatDate, filter: 'agDateColumnFilter'},
        {field:'EstimatedEndDate',headerName:'Estimated End Date', valueFormatter: formatDate, filter: 'agDateColumnFilter'},
        {field:'Worksite',headerName:'WorkSite'},
        {field:'CustomerName',headerName:'Customer'},
        {field:'EmployeePayRate',headerName:'EE Rate', valueFormatter: dollarFormat, filter: 'agNumberColumnFilter'},
        {field:'VendorPayRate',headerName:'Vendor Rate', valueFormatter: dollarFormat, filter: 'agNumberColumnFilter', hide: (!isVMSCustomer&&!isVendor)},
        {field:'BillRate',headerName:'Customer Rate', valueFormatter: dollarFormat, filter: 'agNumberColumnFilter', hide: isVendor},
        {field:'Shift',headerName:'Shift'},
        {field:'StartTime',headerName:'Start Time'},
        {field:'EndTime',headerName:'End Time'},
        {field:'OpenOrderAge',headerName:'Open Order Age', filter: 'agNumberColumnFilter'},
        isVMSCustomer?{field:'VendorSubmitted',filter: 'agNumberColumnFilter', aggFunc: 'sum'}:{hide:true},
        isVMSCustomer?{field:'VendorCandidates',filter: 'agNumberColumnFilter', aggFunc: 'sum'}:{hide:true},
        {field:'Candidates',headerName:'Candidates', filter: 'agNumberColumnFilter'},
        {field:'Required',headerName:'Required', filter: 'agNumberColumnFilter'},
        {field:'Filled',headerName:'Filled', filter: 'agNumberColumnFilter'},
        {field:'Unfilled',headerName:'Unfilled', filter: 'agNumberColumnFilter'},
        {field:'JobDescription',headerName:'Job Description'},
        {field:'OrderType',headerName:'Order Type'},
        {field:'PercentFilled',headerName:'Percent Filled', valueFormatter: formatPercentage},
        {field:'City',headerName:'City'},
        {field:'State',headerName:'State'},
        {field:'OrderCreateDate',headerName:'Order Created Date', valueFormatter: formatDate},
        {field:'TimeToFill',headerName:'Time to Fill'},
        {field:'JobType',headerName:'Job Type'},
        {field:'LastAssignment',headerName:'Last Assignment'},
        {field:'DressCode',headerName:'Dress Code'},
        {field:'SafetyNotes',headerName:'Safety Notes'},
        {field:'RequestName',headerName:'Request Name'},
        {field:'NeedsApprovalByUser', hide: true},
        {field:'CandidatesNeedReviewByUser', hide: true},
    ]);

    useEffect(()=>{
        setCurrentPage('Orders')
    },[setCurrentPage])

    const getRowClass = params => {
        if(params.data){
            if (params.data.NeedsApprovalByUser||params.data.CandidatesNeedReviewByUser) {
                return 'highlight-row';
            }
        }else if(params.node.aggData){
            if (params.node.aggData.NeedsApprovalByUser||params.node.aggData.CandidatesNeedReviewByUser) {
                return 'highlight-row';
            }
        }
    };

    const onFirstDataRendered = (params) => { 
        // Build the array of options
        // When a filter button is selected, push only that option to the filter 
        // When the All button is selected, push the whole array to the filter
        // This means we need an All Filter Options array and a Selected Options array
        setGridApi(params.api)

        let orderTypeOptions = params.api.getFilterInstance('OrderStatus').getFilterValues()
        setStatusOptions(orderTypeOptions)
        
        if(isVMSCustomer){
            let vendorOptions = params.api.getFilterInstance('VendorOfferStatus').getFilterValues()
            setVendorStatusOptions(vendorOptions)
        }
     }

     const onRowDataUpdated = (params) => { 
        CalculateStatusAmounts(params)
      }

    const onFilterChanged = (params) => { 
        CalculateStatusAmounts(params)
     }

    const CalculateStatusAmounts = (params) => { 
        let totalUnfilledCount = 0;
        let totalFilledCount = 0;
        let totalClosedCount = 0;
        
        let totalAcceptedCount = 0;
        let totalDistributedCount = 0;
        let totalRefusedCount = 0;
        let totalUndistributedCount = 0;

        params.api.forEachNode(node=>{
            if(node.data&&node.data.OrderStatus==='Unfilled'){
                totalUnfilledCount+=1
            }else if(node.data&&node.data.OrderStatus==='Filled'){
                totalFilledCount+=1
            }else if(node.data&&node.data.OrderStatus==='Closed'){
                totalClosedCount+=1
            }
            if(node.data&&node.data.VendorOfferStatus==='Accepted'){
                totalDistributedCount+=1
            }else if(node.data&&node.data.VendorOfferStatus==='Distributed'){
                totalAcceptedCount+=1
            }else if(node.data&&node.data.VendorOfferStatus==='Refused'){
                totalRefusedCount+=1
            }else if(node.data&&node.data.VendorOfferStatus==='Undistributed'){
                totalUndistributedCount+=1
            }
        })
        setTotalUnfilled(totalUnfilledCount);
        setTotalFilled(totalFilledCount);
        setTotalClosed(totalClosedCount);

        setTotalDistributed(totalDistributedCount);
        setTotalAccepted(totalAcceptedCount);
        setTotalRefused(totalRefusedCount);
        setTotalUndistributed(totalUndistributedCount);
     }

    const HandleFilterChange = (statusName,filterName) => { 
        if(statusName==='All'||statusName===false){
            let newModel;
            if(filterName==='VendorOfferStatus'){
                newModel = vendorStatusOptions
            }else if(filterName==='NeedsApprovalByUser'||filterName==='CandidatesNeedReviewByUser'){
                newModel = ['true','false']
            }else{
                newModel = statusOptions
            }
            let sourceInstance = gridApi.getFilterInstance(filterName)
            sourceInstance.setModel({values: newModel})
            sourceInstance.applyModel()
            gridApi.onFilterChanged();
        }else{
            if(filterName==='NeedsApprovalByUser'||filterName==='CandidatesNeedReviewByUser'){
                statusName = 'true'
            }
            let newModel = [statusName]
            let sourceInstance = gridApi.getFilterInstance(filterName)
            sourceInstance.setModel({values: newModel})
            sourceInstance.applyModel()
            gridApi.onFilterChanged();
        }
    }

    const onBtnExport = () => {
        gridApi.exportDataAsCsv();
    };
    
    const onRowSelected = (params) => { 
        if(params.data){
            handleSetSelectedRows(params.node)
        }
     }

    const handleSetSelectedRows = (node) => { 
        if(node.isSelected()){
            let newSelectedRows = [...selectedRows, node]
            setSelectedRows(newSelectedRows)
        }else{
            if(selectedRows.length===1){
                setSelectedRows([])
            }else{
                let newSelectedRows = selectedRows.splice(selectedRows.indexOf(node),1)
                setSelectedRows([...newSelectedRows])
            }
        }
     }

    const onCellClicked = (params) => { 
        if(params.node.aggData){
            setSelectedOrder(params.node.aggData)
            setShowPopup(true)
        }else if(params.colDef.headerName||params.colDef.field){
            setSelectedOrder(params.data)
            setShowPopup(true);
        }
     }

     const simplePopover = (text)=>{
        return <Popover id="popover-basic">
                    <Popover.Body>
                        {text}
                    </Popover.Body>
                </Popover>
      };

  return (
    <>
    {showAddNewOrder&&<AddJobOrderLogic show={showAddNewOrder} setShow={setShowAddNewOrder} />}
    {showPopup&&<Popup show={showPopup} setShow={setShowPopup} orderInfo={selectedOrder} isVMSCustomer={isVMSCustomer} isVendor={isVendor} />}
    <div className='filter-group-wrapper'>
        <div className='buttons-wrapper'>
            <div className='margin-right'>
                {!isVendor&&
                <OverlayTrigger delay={500} placement="right" overlay={simplePopover('This filter will show you all orders that have a status of Pending Approval and require approval from you')}>
                    {/* div is here becuase without it the tooltip will only pop up on the check and not the label, which makes for bad user experience */}
                    <div>
                        <Form.Check
                            type="switch"
                            className='switch-label-wrapper'
                            label={<div><span>Needs Approval</span> <BsQuestionCircleFill /></div>}
                            onClick={(e)=>HandleFilterChange(e.target.checked,'NeedsApprovalByUser')}
                            />
                    </div>
                </OverlayTrigger>
                }
                <OverlayTrigger delay={500} placement="right" overlay={simplePopover('This filter will show you all orders that have candidates attached with a status of Submitted and require a status change from you')}>
                    <div>
                        <Form.Check
                            className='switch-label-wrapper'
                            type="switch"
                            label={<div><span>Needs Review</span> <BsQuestionCircleFill /></div>}
                            onClick={(e)=>HandleFilterChange(e.target.checked,'CandidatesNeedReviewByUser')}
                        />
                    </div>
                </OverlayTrigger>
            </div>
            <Nav variant="pills" activeKey={statusActiveKey} onSelect={(k)=>setStatusActiveKey(k)}>
                <Nav.Item>
                    <Nav.Link eventKey="All" onClick={()=>HandleFilterChange('All','OrderStatus')}>All Order Status</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Unfilled" onClick={()=>HandleFilterChange('Unfilled','OrderStatus')}>Unfilled <Badge bg='secondary'>{totalUnfilled}</Badge></Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Filled" onClick={()=>HandleFilterChange('Filled','OrderStatus')}>Filled <Badge bg='secondary'>{totalFilled}</Badge></Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Closed" onClick={()=>HandleFilterChange('Closed','OrderStatus')}>Closed <Badge bg='secondary'>{totalClosed}</Badge></Nav.Link>
                </Nav.Item>
                <OverlayTrigger delay={500} placement="right" overlay={simplePopover('The numbers next to each status show the total orders with that status, and do not update after filters are added')}>
                    <div className='status-tooltip-wrapper'>
                        <BsQuestionCircleFill />
                    </div>
                </OverlayTrigger>
            </Nav>

            {isVMSCustomer?
            <Nav variant="pills" activeKey={vendorActiveKey} onSelect={(k)=>setVendorActiveKey(k)} className='margin-left'>
                <Nav.Item>
                    <Nav.Link eventKey="All" onClick={()=>HandleFilterChange('All','VendorOfferStatus')}>All Vendor Status</Nav.Link>
                </Nav.Item>
                {(isVMSCustomer&&!isVendor)?
                <Nav.Item>
                    <Nav.Link eventKey="Undistributed" onClick={()=>HandleFilterChange('Undistributed','VendorOfferStatus')}>Undistributed <Badge bg='secondary'>{totalUndistributed}</Badge></Nav.Link>
                </Nav.Item>
                :
                ''
                }
                <Nav.Item>
                    <Nav.Link eventKey="Distributed" onClick={()=>HandleFilterChange('Distributed','VendorOfferStatus')}>Distributed <Badge bg='secondary'>{totalDistributed}</Badge></Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Refused" onClick={()=>HandleFilterChange('Refused','VendorOfferStatus')}>Refused <Badge bg='secondary'>{totalRefused}</Badge></Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Accepted" onClick={()=>HandleFilterChange('Accepted','VendorOfferStatus')}>Accepted <Badge bg='secondary'>{totalAccepted}</Badge></Nav.Link>
                </Nav.Item>
                <OverlayTrigger delay={500} placement="right" overlay={simplePopover('The numbers next to each status show the total orders with that status, and do not update after filters are added')}>
                    <div className='status-tooltip-wrapper'>
                        <BsQuestionCircleFill />
                    </div>
                </OverlayTrigger>
            </Nav>
            :
            ''
            }

        </div>
        <ButtonGroup className='filter-group-new-order-button'>
            {IsCustomerAdmin&&
            <Button onClick={()=>setShowAddNewOrder(true)}>Add New Order</Button>
            }
            {orders&&
            <Button variant="dark" onClick={()=>onBtnExport()} title='Download grid data'><BsDownload /></Button>
            }
            <Button variant="dark" title='Report a bug' onClick={(e) => {
            window.location.href = `mailto:${'Bug Reporting - DevMo! <01bd0080.employnet.com@amer.teams.ms>'}` 
            e.preventDefault()
            }}><BsBugFill /></Button>
        </ButtonGroup>
    </div>

    {(selectedRows&&selectedRows.length>0&&((cookies.get('customer').runAsCustomer&&cookies.get('customer').runAsCustomer.VendorId)||cookies.get('customer').VendorID))&&
        <AcceptRefuseButton orderIDs={selectedRows.map(row=>row.data.OrderID)} />
    }

    <div id="myGrid" className="ag-theme-alpine vh-60 margin-top">
        <AgGridReact
            multiSortKey={'ctrl'}
            suppressExcelExport={true}
            popupParent={document.body}
            defaultColDef={defaultColDef}
            rowData={orders}
            columnDefs={columnDefs}
            onRowDataUpdated={onRowDataUpdated}
            onFilterChanged={onFilterChanged}
            onFirstDataRendered={onFirstDataRendered}
            onCellClicked={onCellClicked}
            onRowSelected={onRowSelected}
            rowSelection='multiple'
            getRowClass={getRowClass}
            suppressRowClickSelection={true}
            suppressAggFuncInHeader={true}
            groupDisplayType='custom'
            showOpenedGroup={true}
            groupSelectsChildren={true}
            autoGroupColumnDef={{
                minWidth: 200,
                cellRendererParams: {
                    suppressCount: true,
                    checkbox: true,
                }
            }}
        ></AgGridReact>
    </div>
    </>
  )
}

export default Orders