import { AgGridReact } from 'ag-grid-react'
import React from 'react'
import { Button, Form, Row, Col, Spinner } from 'react-bootstrap'
import Select from 'react-select';
import { BsDownload, BsBugFill } from "react-icons/bs";
import ButtonGroup from 'react-bootstrap/ButtonGroup'

function FTE({ sundays, onBtnExport, defaultColDef, data, columnDefs, onGridReady, onFilterChanged, pinnedBottomRowData, startDate, endDate, setStartDate, setEndDate}) {
  return (
    <>
        <Row>
            <Col>
            <Form.Label>Include work week ending dates from:</Form.Label>
            {startDate?
            <Select options={sundays.map(sunday=>{return{value:sunday, label:sunday.DateValue}})} defaultValue={startDate} onChange={(e)=>setStartDate(e)} />
            :
            <Spinner animation='border' />
            }
            </Col>
            <Col>
            <Form.Label>To:</Form.Label>
            {endDate?
            <Select options={sundays.map(sunday=>{return{value:sunday, label:sunday.DateValue}})} defaultValue={endDate} onChange={(e)=>setEndDate(e)} />
            :
            <Spinner animation='border' />
            }
            </Col>
            <Col sm={1} style={{'alignItems': 'end','display': 'flex'}}>
            <ButtonGroup>
              <Button variant='dark' onClick={() => onBtnExport()}><BsDownload/></Button>
              <Button variant="dark" title='Report a bug' onClick={(e) => {
                window.location.href = `mailto:${'Bug Reporting - DevMo! <01bd0080.employnet.com@amer.teams.ms>'}` 
                e.preventDefault()
              }}><BsBugFill /></Button>
            </ButtonGroup>
            </Col>
        </Row>
        <div id="myGrid" className="ag-theme-alpine grid filter-grid">
            <AgGridReact
                multiSortKey={'ctrl'}
                suppressExcelExport={true}
                popupParent={document.body}
                defaultColDef={defaultColDef}
                rowData={data}
                columnDefs={columnDefs}
                onGridReady={onGridReady}
                onFilterChanged={onFilterChanged}
                pinnedBottomRowData={pinnedBottomRowData}
            ></AgGridReact>
        </div>
    </>
  )
}

export default FTE