import React, {useState} from 'react'
import AbsencePopup from './AbsencePopup'
import useFetchPost from '../../../../Fetch/useFetchPost';

function AbsencePopupLogic({ show, setShow, employee}) {
    const [start, setStart] = useState();
    const [end, setEnd] = useState();
    const [notes, setNotes] = useState();
    const [error, setError] = useState();
    const [canPost, setCanPost] = useState();
    const {REACT_APP_API_URL} = process.env;
    const { loading, status } = useFetchPost((canPost&&`${REACT_APP_API_URL}/Reports/RecordApprovedAbsence`), {EmployeeID: employee.EmployeeID,start,end,notes:(notes?notes:'')})

    const HandleSubmit = () => { 
        if(ValidateData()){
            setCanPost(true);
        }
     }
    
    const ValidateData = () => { 
        setError(null);
        if(!start){
            setError('Please select a start date')
            return false;
        }else if(!end){
            setError('Please select an end date')
            return false;
        }else if(start>end){
            setError('Start date cannot be after end date')
            return false;
        }else{
            return true;
        }
     }


  return (
    <AbsencePopup
        show={show}
        setShow={setShow}
        employee={employee}
        setStart={setStart}
        setEnd={setEnd}
        setNotes={setNotes}
        HandleSubmit={HandleSubmit}
        loading={loading}
        error={error}
        status={status}
    ></AbsencePopup>
  )
}

export default AbsencePopupLogic