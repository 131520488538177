import React from 'react'
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import useFetchPost from '../../../Fetch/useFetchPost';
import { useState } from 'react';

function ApproveOrderButton({ OrderID }) {
  const [params, setParams] = useState()
  const [postObj, setPostObj] = useState()
  const { REACT_APP_API_URL } = process.env;
  const { status, loading, error } = useFetchPost((params&&`${REACT_APP_API_URL}/Candidates/UpdateOrderStatus`),params,postObj)

  const handleClick = () => { 
    setParams({
      OrderID,
      StatusID:23,
    })
    setPostObj("Pending Web Order")
   }
  return (
    <>
    {!status?
    <Button variant='success' onClick={()=>handleClick()}>{loading?<Spinner animation='border' size='sm'/>:'Approve Order'}</Button>
    :(status===200)&&
    <Alert>                
      <p>Success!<br/></p>
      <p>Please allow up to <b>15 minutes for the changes you made to show in this application.</b> All changes require a sync to TempWorks before they can be visible in our applications.<br/></p>
      <p>Thank you for your patience</p>
    </Alert>
    }
    {error&&<Alert variant='warning'>An error occured, please try again</Alert>}
    </>
  )
}

export default ApproveOrderButton