import React, { useContext, useState } from 'react'
import Form from 'react-bootstrap/Form';    
import Select from 'react-select';
import useFetchGet from '../../../../../Fetch/useFetchGet';
import CustomerContext from '../../../../../Context/CustomerContext';
import { useEffect } from 'react';

function ContactRoles() {
    const { formData, setFormData } = useContext(CustomerContext)
    const [customerID] = useState(formData.Department.CustomerID)
    const { REACT_APP_API_URL } = process.env;
    const { data: contactRoles } = useFetchGet(`${REACT_APP_API_URL}/VMS/GetCustomerContactRolesList`,{CustomerID:customerID})
    const { data: contacts } = useFetchGet(`${REACT_APP_API_URL}/VMS/GetCustomerContactsWithParents`,{CustomerID:customerID})
    const { data: roles } = useFetchGet(`${REACT_APP_API_URL}/VMS/GetContactRoles`)
    const [contactOptions, setContactOptions] = useState();

    useEffect(()=>{
        if(contacts){
            setContactOptions(contacts.map(contact=>{
                return {value:contact, label:contact.FirstName+' '+contact.LastName}
            }))
        }
    },[contacts])

    useEffect(()=>{
        if(contactRoles&&formData.ContactRoles.length===0){
            formData.ContactRoles = contactRoles.map(role=>{
                return { 
                    ContactID:role.ContactID,
                    RoleID:role.ContactTypeID
                }
            })
            setFormData(formData)
        }
    // eslint-disable-next-line
    },[contactRoles])

    const HandleChange = (e,ContactTypeID) => { 
        let index = formData.ContactRoles.findIndex(contact=>contact.RoleID===ContactTypeID)
        if(index===-1){
            formData.ContactRoles.push({
                ContactID:e.value.ContactID,
                RoleID:ContactTypeID
            })
            setFormData(formData)
        }else{
            formData.ContactRoles[index].ContactID = e.value.ContactID
            setFormData(formData)
        }
    // eslint-disable-next-line
     }


  return (
    <div className='vh-40'>
        {(roles&&contactRoles&&contactOptions)&&
        roles.map(role=>{
            let defaultOption;
            // See if this role has a default contact associated with it
            let index = contactRoles.findIndex(contactRole=> contactRole.ContactTypeID===role.ContactTypeID)
            if(index!==-1){
                //If so, find the contact option and save it as the default
                defaultOption = contactOptions.find(contact=> contact.value.ContactID===contactRoles[index].ContactID)
                HandleChange(defaultOption, role.ContactTypeID)
            }
            return <Form.Group className='margin-bottom' controlId={role.ContactTypeDescription} key={role.ContactTypeID}>
                        <Form.Label>{role.ContactTypeDescription}:</Form.Label>
                        <Select 
                            defaultValue={defaultOption} 
                            options={contactOptions} 
                            onChange={(e)=>HandleChange(e,role.ContactTypeID)} 
                            isDisabled={role.ContactTypeIsOrderRequest}
                        />
                    </Form.Group>
        })}
        {/* <Form.Group controlId="hrCoordinator">
            <Form.Label>HR Coordinator:</Form.Label>
            <Select options={contactOptions} />
        </Form.Group>
        <Form.Group controlId="invoiceEmailContact">
            <Form.Label>Invoice Email Contact:</Form.Label>
            <Select options={contactOptions} />
        </Form.Group>
        <Form.Group controlId="orderRequestCreator">
            <Form.Label>Order Request Creator:</Form.Label>
            <Select options={contactOptions} />
        </Form.Group>
        <Form.Group controlId="orderRequestEmailContact">
            <Form.Label>Order Request Email Contact:</Form.Label>
            <Select options={contactOptions} />
        </Form.Group>
        <Form.Group controlId="orderRequestT1Approver">
            <Form.Label>Order Request T1 Approver:</Form.Label>
            <Select options={contactOptions} />
        </Form.Group>
        <Form.Group controlId="orderRequestT2Approver">
            <Form.Label>Order Request T2 Approver:</Form.Label>
            <Select options={contactOptions} />
        </Form.Group>
        <Form.Group controlId="orderRequestT3Approver">
            <Form.Label>Order Request T3 Approver:</Form.Label>
            <Select options={contactOptions} />
        </Form.Group>
        <Form.Group controlId="orderRequestor">
            <Form.Label>Order Requestor:</Form.Label>
            <Select options={contactOptions} />
        </Form.Group>
        <Form.Group controlId="orderedBy">
            <Form.Label>Ordered By:</Form.Label>
            <Select options={contactOptions} />
        </Form.Group>
        <Form.Group controlId="primaryContact">
            <Form.Label>Primary Contact:</Form.Label>
            <Select options={contactOptions} />
        </Form.Group>
        <Form.Group controlId="reportTo">
            <Form.Label>Report To:</Form.Label>
            <Select options={contactOptions} />
        </Form.Group>
        <Form.Group controlId="supervisor">
            <Form.Label>Supervisor:</Form.Label>
            <Select options={contactOptions} />
        </Form.Group>
        <Form.Group controlId="supervisorNoWeb">
            <Form.Label>Supervisor - no webCenter:</Form.Label>
            <Select options={contactOptions} />
        </Form.Group>
        <Form.Group controlId="supervisorAlt1">
            <Form.Label>Supervisor Alt 1:</Form.Label>
            <Select options={contactOptions} />
        </Form.Group>
        <Form.Group controlId="supervisorAlt2">
            <Form.Label>Supervisor Alt 2:</Form.Label>
            <Select options={contactOptions} />
        </Form.Group>
        <Form.Group controlId="supervisorAlt3">
            <Form.Label>Supervisor Alt 3:</Form.Label>
            <Select options={contactOptions} />
        </Form.Group>
        <Form.Group controlId="timecardAlt1">
            <Form.Label>Timecard Alt 1:</Form.Label>
            <Select options={contactOptions} />
        </Form.Group>
        <Form.Group controlId="timecardAlt2">
            <Form.Label>Timecard Alt 2:</Form.Label>
            <Select options={contactOptions} />
        </Form.Group>
        <Form.Group controlId="timecardAlt3">
            <Form.Label>Timecard Alt 3:</Form.Label>
            <Select options={contactOptions} />
        </Form.Group>
        <Form.Group controlId="timecardAlt4">
            <Form.Label>Timecard Alt 4:</Form.Label>
            <Select options={contactOptions} />
        </Form.Group>
        <Form.Group controlId="vendorAccountManager">
            <Form.Label>Vendor Account Manager:</Form.Label>
            <Select options={contactOptions} />
        </Form.Group> */}
    </div>
  )
}

export default ContactRoles