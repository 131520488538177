import { Button, Modal, Form } from 'react-bootstrap'
import React from 'react'

function LatePopup({ show, setShow, employee, RecordLateNotice, setMinutesLate, loading }) {
  return (
    <>
    <Modal show={show} onHide={setShow}>
        <Modal.Header closeButton><h3>Employee Late Form</h3></Modal.Header>
        <Modal.Body>
          <Form.Label>{employee.FirstName+' '+employee.Lastname} is how many minutes late?</Form.Label>
          <Form.Control type='number' onChange={(e)=>setMinutesLate(e.target.value)} placeholder='15' />
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={loading} onClick={(e)=>RecordLateNotice()}>{loading?'Submitting...':'Submit'}</Button>
          <Button variant='secondary' onClick={(e)=>setShow(false)}>Close</Button>
        </Modal.Footer>
    </Modal>
    </>
  )
}

export default LatePopup;