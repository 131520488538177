import React from 'react'
import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import useFetchGet from '../../../Fetch/useFetchGet';

function Assignments({employeeID}) {
    const {REACT_APP_API_URL} = process.env;
    const { data: assignmentList, loading: loadingAssignments, error} = useFetchGet(`${REACT_APP_API_URL}/VMS/GetEmployeeAssignments`, {EmployeeID:employeeID})
    let USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

  return (
    <div className='vh20'>
        {loadingAssignments&&
            <Spinner animation='border' />
        }
        {(assignmentList&&assignmentList.length>0)?
            assignmentList.map(assignment=>{
                return <Card className='margin-bottom'>
                            <Card.Header>
                                {assignment.JobTitle+' | '+assignment.AssignmentID}
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col><p><b>Customer:</b></p> <p>{assignment.CustomerName}</p></Col>
                                    <Col><p><b>Department:</b></p> <p>{assignment.DepartmentName}</p></Col>
                                    <Col><p><b>Pay Rate:</b></p> <p>{USDollar.format(assignment.PayRate)}</p></Col>
                                </Row>
                                <Row>
                                    <Col><p><b>Start Date:</b></p> <p>{assignment.StartDate&&assignment.StartDate.split('T')[0]}</p></Col>
                                    <Col><p><b>End Date:</b></p> <p>{assignment.EndDate&&assignment.EndDate.split('T')[0]}</p></Col>
                                    <Col><p><b>Expected End Date:</b></p> <p>{assignment.ExpectedEndDate&&assignment.ExpectedEndDate.split('T')[0]}</p></Col>
                                </Row>
                            </Card.Body>
                        </Card>
            })
        :
        error?
        <Alert variant='warning'>
            <p>There was an issue getting data, please try again</p>
            <p>{error}</p>
        </Alert>
        :
        <Alert>No assignment history</Alert>
        }
    </div>
  )
}

export default Assignments