import React, { useContext, useEffect, useState } from 'react';
import CustomerContext from '../../Context/CustomerContext'
import useFetchGet from '../../Fetch/useFetchGet';
import TimeclockPunchesFinance from './TimeclockPunchesFinance';

function TimeclockPunchesFinanceLogic() {
    const [gridApi, setGridApi] = useState();
    const [defaultColDef] = useState({
        resizable: true,
        sortable: true,
        filter: 'agSetColumnFilter', 
        wrapHeaderText: true, 
        autoHeaderHeight: true, 
        width: 125
      });

    let USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    })

    const dollarFormat = (params) =>{
      if(params.value||params.value===0){
          return USDollar.format(params.value)
      }
    }

      const [columnDefs] = useState([
        {headerName:'Customer', field:'CustomerName'},
        {headerName:'Department',field:'DepartmentName'},
        {headerName:'Work Site',field:'WorkSite'},
        {headerName:'Employee',field:'EmployeeName'},
        {headerName:'Vendor',field:'VendorName'},
        {headerName:'Weekend Date',field:'WeekendDate', filter: 'agDateColumnFilter',valueFormatter: (date)=>{return TransformDate(date)}},
        {headerName:'Work Date',field:'WorkDate', filter: 'agDateColumnFilter',valueFormatter: (date)=>{return TransformDate(date)}},
        {headerName:'Approved', field:'IsApproved', valueFormatter: ({value})=>value?'Yes':'No'},
        {headerName:'Start',field:'Start'},
        {headerName:'End',field:'End'},
        {headerName:'Hours',field:'Hours', filter: 'agNumberColumnFilter'},
        {headerName:'Reg Hours',field:'RegHours',filter: 'agNumberColumnFilter'},
        {headerName:'OT Hours',field:'OTHours',filter: 'agNumberColumnFilter'},
        {headerName:'DT Hours',field:'DTHours',filter: 'agNumberColumnFilter'},
        {headerName:'Total Week Hours',field:'TotalWeekHours',filter: 'agNumberColumnFilter'},
        {headerName:'Reg Week Hours',field:'RegWeekHours',filter: 'agNumberColumnFilter'},
        {headerName:'Alternate ID',field:'AlternateID'},
        {headerName:'Employee ID',field:'EmployeeID'},
        {headerName:'Customer ID',field:'CustomerID'},
        {headerName:'Assignment ID',field:'AssignmentID'},
        {headerName:'Missed Punch',field:'MissedPunch'},
        {field: 'OrderID'},
        {field: 'EmployeePayRate', valueFormatter: dollarFormat},
        {field: 'VendorPayRate', valueFormatter: dollarFormat},
        {field: 'CustomerPayRate', valueFormatter: dollarFormat}
      ]);
      const rowClassRules = {
        'yellow-background': function(params) { 
          return params.data.MissedPunch; 
        },
      }
      const { setCurrentPage } = useContext(CustomerContext);
      const {REACT_APP_API_URL} = process.env;
      const [pinnedBottomRowData, setPinnedBottomRowData] = useState();
      const [today] = useState(new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toJSON().split('T')[0])
      const [date, setDate] = useState(today)
      const { data } = useFetchGet(`${REACT_APP_API_URL}/reports/getfinancialhoursdetail`, {date})

      useEffect(()=>{
          setCurrentPage('Timeclock Punches')
   // eslint-disable-next-line
      })

      useEffect(()=>{
        if(data){
          let totalHours = data.reduce((last, current)=>{return last + current.Hours},0)  
          let uniqueIDCount = data.filter((element, index) => index===data.findIndex(elem => elem.EmployeeID===element.EmployeeID))
          setPinnedBottomRowData([{
            EmployeeName: `Headcount: ${uniqueIDCount.length}`,
            Hours: `${totalHours.toFixed(2)}`,
            RegHours: `${data.reduce((last,current)=>last+current.RegHours,0).toFixed(2)}`,
            OTHours: `${data.reduce((last,current)=>last+current.OTHours,0).toFixed(2)}`,
            DTHours: `${data.reduce((last,current)=>last+current.DTHours,0).toFixed(2)}`,
            TotalDayHours: `${data.reduce((last,current)=>last+current.TotalDayHours,0).toFixed(2)}`,
            TotalWeekHours: `${data.reduce((last,current)=>last+current.TotalWeekHours,0).toFixed(2)}`,
            RegWeekHours: `${data.reduce((last,current)=>last+current.RegWeekHours,0).toFixed(2)}`,
          }])
        }
        },[data])

      const onGridReady = (params) => { 
        setGridApi(params.api)
        params.columnApi.autoSizeAllColumns();
     }
  
     const onBtnExport = () => {
        gridApi.exportDataAsCsv();
    };

    function TransformDate(date){
      if(date.value){
          return new Date(date.value).toLocaleDateString();
      }else{
          return ''
      }
  }

  const onFilterChanged = (e) => { 
    let data = [];
    gridApi.forEachNodeAfterFilter(node=>{
       data.push(node.data);
    })
    let totalHours = data.reduce((last, current)=>{return last + current.TotalHours},0)        
    let uniqueIDCount = data.filter((element, index) => index===data.findIndex(elem => elem.EmployeeID===element.EmployeeID))
    setPinnedBottomRowData([{
      EmployeeName: `Headcount: ${uniqueIDCount.length}`,
      Hours: `${totalHours.toFixed(2)}`,
      RegHours: `${data.reduce((last,current)=>last+current.RegHours,0).toFixed(2)}`,
      OTHours: `${data.reduce((last,current)=>last+current.OTHours,0).toFixed(2)}`,
      DTHours: `${data.reduce((last,current)=>last+current.DTHours,0).toFixed(2)}`,
      TotalDayHours: `${data.reduce((last,current)=>last+current.TotalDayHours,0).toFixed(2)}`,
      TotalWeekHours: `${data.reduce((last,current)=>last+current.TotalWeekHours,0).toFixed(2)}`,
      RegWeekHours: `${data.reduce((last,current)=>last+current.RegWeekHours,0).toFixed(2)}`,
    }])
   }

  return (
    <TimeclockPunchesFinance
        defaultColDef={defaultColDef} 
        rowData={data}
        columnDefs={columnDefs} 
        onGridReady={onGridReady}
        onBtnExport={onBtnExport}
        date={date}
        setDate={setDate}
        rowClassRules={rowClassRules}
        pinnedBottomRowData={pinnedBottomRowData}
        onFilterChanged={onFilterChanged}
    ></TimeclockPunchesFinance>
  )
}

export default TimeclockPunchesFinanceLogic;