import React, { useContext, useState } from 'react'
import CustomerContext from '../../../../Context/CustomerContext';
import AddSickLatePopup from './LatePopup'

function LatePopupLogic({ show, setShow, employee }) {
    const [minutesLate, setMinutesLate] = useState();
    const [loading, setLoading] = useState(false);
    const {cookies} = useContext(CustomerContext)
    const {REACT_APP_API_URL} = process.env;

    const RecordLateNotice = (token) => { 
      setLoading(true);
        fetch(`${REACT_APP_API_URL}/Reports/RecordLateNotice?token=${cookies.get('customer').runAsCustomer?cookies.get('customer').runAsCustomer.Token:cookies.get('customer').Token}&employeeid=${employee.EmployeeID}&late=${minutesLate}`,{
        method: 'POST',
        headers: {
            'Content-Type':'application/json'
        }        
      })
        .then(res=>{
            if(res.status===200){
                setShow(false);
            }else if(res.status===401){
                cookies.remove('customer', {path:'/'})
                window.location.reload()
            }else if(!res.ok){
                throw Error(res.statusText)
            }
        })
        .then(data=>{
        })
        .catch(error=>{
            console.error(error)
            window.alert(error)
        })
     }

  return (
    <AddSickLatePopup
        show={show}
        setShow={setShow}
        employee={employee}
        setMinutesLate={setMinutesLate}
        RecordLateNotice={RecordLateNotice}
        loading={loading}
    ></AddSickLatePopup>
  )
}

export default LatePopupLogic;