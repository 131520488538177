import {Alert, Button, Modal, Form, Row, Col, Card } from 'react-bootstrap'
import React from 'react'
import LatePopupLogic from './LatePopup/LatePopupLogic'
import AbsencePopupLogic from './AbsencePopup/AbsencePopupLogic'

function AddSickLatePopup({ absencePopupIsOpen, setAbsencePopupIsOpen, loading, show, setShow, employee, GetEmployeeByID, HandleChange, setLatePopupIsOpen, sickRecorded, latePopupIsOpen, RecordSickNotice }) {
  return (
    <>
    {latePopupIsOpen&&<LatePopupLogic show={latePopupIsOpen} setShow={setLatePopupIsOpen} employee={employee} />}
    {absencePopupIsOpen&&<AbsencePopupLogic show={absencePopupIsOpen} setShow={setAbsencePopupIsOpen} employee={employee} />}
    <Modal show={show} onHide={setShow}>
        <Modal.Header closeButton><h3>Add Late/Absent</h3></Modal.Header>
        <Modal.Body>
            <Card className='padding margin-bottom'>
                <Form onSubmit={GetEmployeeByID}>
                    <Row>
                        <Form.Label>Find employee by ID:</Form.Label>
                        <Col><Form.Control placeholder='123456' onChange={HandleChange}></Form.Control></Col>
                        <Col><Button variant='secondary' type='submit'>Find Employee</Button></Col>
                    </Row>
                </Form>
            </Card>
            {(employee===false)&&
                <Alert>No employee found with that Employee ID, please try again.</Alert>
            }
            {employee&&
            <Card className='padding'>
                <Row> 
                    <p className='text-center'>Employee found: <b>{employee.FirstName+' '+employee.LastName}</b></p>
                    <div className='margin-bottom'><Button className='full-width' onClick={(e)=>setLatePopupIsOpen(true)}>Add Late</Button></div>
                    <Col><Button disabled={loading} className='full-width' onClick={(e)=>RecordSickNotice()}>{loading?'Adding...':'Add Sick Absence'}</Button></Col>
                    <Col><Button className='full-width' onClick={(e)=>setAbsencePopupIsOpen(true)}>Add other absence</Button></Col>
                    {sickRecorded&&<Alert>{sickRecorded}</Alert>}
                </Row>
            </Card>
            }
        </Modal.Body>
        <Modal.Footer>

        </Modal.Footer>
    </Modal>
    </>
  )
}

export default AddSickLatePopup