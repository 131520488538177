import React from 'react'
import { AgGridReact } from 'ag-grid-react'
import {Form} from 'react-bootstrap'
import {Button, ButtonGroup} from 'react-bootstrap'
import { BsDownload, BsBugFill } from "react-icons/bs";
import AddSickLatePopupLogic from './AddSickLatePopup/AddSickLatePopupLogic';

function Absence({ defaultColDef, rowData, columnDefs, onGridReady, setSelectedDate, onBtnExport, today, employeeLateSickPopupIsOpen, setEmployeeLateSickPopupIsOpen }) {
  return (
    <div>
      {employeeLateSickPopupIsOpen&&<AddSickLatePopupLogic show={employeeLateSickPopupIsOpen} setShow={setEmployeeLateSickPopupIsOpen} />}
        <div className='absence-grid-download'>
          <Form.Control onChange={(e)=>setSelectedDate(e.target.value)} defaultValue={today} max={today} className='absence-date' type='date'/>
          <Button onClick={(e)=>setEmployeeLateSickPopupIsOpen(true)}>Add Late/Absent</Button>
          <ButtonGroup>
            <Button variant='dark' onClick={() => onBtnExport()}><BsDownload/></Button>
            <Button variant="dark" title='Report a bug' onClick={(e) => {
              window.location.href = `mailto:${'Bug Reporting - DevMo! <01bd0080.employnet.com@amer.teams.ms>'}` 
              e.preventDefault()
            }}><BsBugFill /></Button>
          </ButtonGroup>
        </div>
        <div id="myGrid" className="ag-theme-alpine grid filter-grid">
            <AgGridReact
                multiSortKey={'ctrl'}
                suppressExcelExport={true}
                popupParent={document.body}
                defaultColDef={defaultColDef}
                rowData={rowData}
                columnDefs={columnDefs}
                onGridReady={onGridReady}
            ></AgGridReact>
        </div>
    </div>
  )
}

export default Absence