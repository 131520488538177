import React, { Component } from 'react';
import CacheBuster from './CacheBuster';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { CustomerProvider } from './Components/Context/CustomerContext';
import { CookiesProvider } from 'react-cookie';
import './Styles/App.css'
import 'ag-grid-enterprise';
import 'ag-grid-enterprise/styles/ag-grid.css';
import 'ag-grid-enterprise/styles/ag-theme-alpine.css';
import Layout from './Components/Layout/Layout';
import PrivateRoute from './Components/Auth/PrivateRoute';
import PublicRoute from './Components/Auth/PublicRoute'
import ErrorBoundary from './Components/ErrorBoundary/ErrorBoundary';
import { LicenseManager } from  'ag-grid-enterprise'

import LoginLogic from './Components/Login/LoginLogic';

import TimeclockPunchesLogic from './Components/TWClocks/TimeclockPunches/TimeclockPunchesLogic';
import TimeclockDailyLogic from './Components/TWClocks/TimeclockDaily/TimeclockDailyLogic';
import TimeclockWeeklyLogic from './Components/TWClocks/TimeclockWeekly/TimeclockWeeklyLogic';

import AbsenceLogic from './Components/Attendance/Absence/AbsenceLogic';
import FTELogic from './Components/Attendance/FTE/FTELogic';
import HoursWorkedLogic from './Components/Attendance/HoursWorked/HoursWorkedLogic';
import HoursWorkedDetailLogic from './Components/Attendance/HoursWorkedDetail/HoursWorkedDetailLogic';

import HeadcountDashboard from './Components/SupplierReview/HeadCount/HeadcountDashboard';
import StartsEndsDashboard from './Components/SupplierReview/StartsEnds/StartsEndsDashboard';
import AttritionDashboard from './Components/SupplierReview/Attrition/AttritionDashboard';
import LengthOfAssignment from './Components/SupplierReview/LengthOfAssignment/LengthOfAssignment';
import LengthOfAssignmentEnded from './Components/SupplierReview/LengthOfAssignmentEnded/LengthOfAssignmentEnded';
import EndOverview from './Components/SupplierReview/EndOverview/EndOverview';
import EndCategories from './Components/SupplierReview/EndCategories/EndCategories';

import Orders from './Components/VMS/Orders/Order';
import Employees from './Components/VMS/Employees/Employees';

import FourZeroFour from './Components/Auth/FourZeroFour';
import RemainingOnAssignment from './Components/SupplierReview/RemainingOnAssignment/RemainingOnAssignment';
import Privacy from './Components/Layout/Privacy/Privacy';
import RequiredDocs from './Components/SupplierReview/RequiredDocs/RequiredDocs';
import HoursWorkedFinancialLogic from './Components/Financial/HoursWorked/HoursWorkedFinancialLogic';
import HoursWorkedFinancialDetailLogic from './Components/Financial/HoursWorkedDetail/HoursWorkedFinancialDetailLogic';

import TimeclockDailyFinanceLogic from './Components/Financial/TimeclockDailyFinance/TimeclockDailyFinanceLogic';
import TimeclockPunchesFinanceLogic from './Components/Financial/TimeclockPunchesFinance/TimeclockPunchesFinanceLogic';
import TimeclockWeeklyFinanceLogic from './Components/Financial/TimeclockWeeklyFinance/TimeclockWeeklyFinanceLogic';


LicenseManager.setLicenseKey("Using_this_AG_Grid_Enterprise_key_( AG-047920 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Employnet )_is_granted_a_( Multiple Applications )_Developer_License_for_( 2 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_AG_Grid_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 28 February 2025 )____[v2]_MTc0MDcwMDgwMDAwMA==2f654c626515ad0a6bce6b253f7bd86b")

class App extends Component {

  render(){
    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
          }
            return (
              <CookiesProvider>
                <CustomerProvider>
                  <BrowserRouter>
                    <Layout>  
                      <ErrorBoundary>
                        <Routes>
                          <Route exact path='/' element={<PublicRoute><LoginLogic /></PublicRoute>} />
                          <Route path='/login' element={<PublicRoute><LoginLogic /></PublicRoute>} />

                          <Route path='/Privacy' element={<Privacy />}  />
                          {/* TW Clocks */}
                          <Route exact path='/Timeclock' element={<PrivateRoute><TimeclockPunchesLogic /></PrivateRoute>}/>
                          <Route path='/Timeclock/daily' element={<PrivateRoute><TimeclockDailyLogic /></PrivateRoute>}/>
                          <Route path='/Timeclock/Weekly' element={<PrivateRoute><TimeclockWeeklyLogic /></PrivateRoute>} />

                          {/* Attendance */}
                          <Route path='/Dashboard' element={<PrivateRoute><AbsenceLogic /></PrivateRoute>} />
                          <Route path='/FTE' element={<PrivateRoute><FTELogic /></PrivateRoute>} />
                          <Route path='/HoursWorked' element={<PrivateRoute><HoursWorkedLogic /></PrivateRoute>} />
                          <Route path='/HoursWorkedDetail' element={<PrivateRoute><HoursWorkedDetailLogic /></PrivateRoute>} /> 

                          {/* Finance */}
                          <Route path='/Finance/HoursWorkedFinancial' element={<PrivateRoute><HoursWorkedFinancialLogic /></PrivateRoute>} />
                          <Route path='/Finance/HoursWorkedDetailFinancial' element={<PrivateRoute><HoursWorkedFinancialDetailLogic /></PrivateRoute>} />
                          <Route path='/Finance/TimeclockDailyFinance' element={<PrivateRoute><TimeclockDailyFinanceLogic/></PrivateRoute>} />
                          <Route path='/Finance/TimeclockPunchesFinance' element={<PrivateRoute><TimeclockPunchesFinanceLogic/></PrivateRoute>} />
                          <Route path='/Finance/TimeclockWeeklyFinance' element={<PrivateRoute><TimeclockWeeklyFinanceLogic/></PrivateRoute>} />

                          {/* SupplierReview */}
                          <Route path='/SupplierReview/Headcount' element={<PrivateRoute><HeadcountDashboard /></PrivateRoute>} />
                          <Route path='/SupplierReview/NetStarts' element={<PrivateRoute><StartsEndsDashboard /></PrivateRoute>} />
                          <Route path='/SupplierReview/Attrition' element={<PrivateRoute><AttritionDashboard /></PrivateRoute>} />
                          <Route path='/SupplierReview/LOAEnded' element={<PrivateRoute><LengthOfAssignmentEnded /></PrivateRoute>} />
                          <Route path='/SupplierReview/LOAActive' element={<PrivateRoute><LengthOfAssignment /></PrivateRoute>} />
                          <Route path='/SupplierReview/EndOverview' element={<PrivateRoute><EndOverview /></PrivateRoute>} />
                          <Route path='/SupplierReview/EndCategories' element={<PrivateRoute><EndCategories /></PrivateRoute>} />
                          <Route path='/SupplierReview/ROAActive' element={<PrivateRoute><RemainingOnAssignment /></PrivateRoute>} />
                          <Route path='/SupplierReview/Compliance' element={<PrivateRoute><RequiredDocs /></PrivateRoute>} />

                          {/* VMS */}
                          <Route path='/Orders/Orders' element={<PrivateRoute><Orders /></PrivateRoute>} />
                          <Route path='/Employees/Employees' element={<PrivateRoute><Employees /></PrivateRoute>} />
                          
                          <Route path='*' element={<PrivateRoute><FourZeroFour /></PrivateRoute>} />
                        </Routes>
                      </ErrorBoundary>
                    </Layout>
                  </BrowserRouter>
                </CustomerProvider>
              </CookiesProvider>
            )
        }}
      </CacheBuster>
    )
  }
}

export default App;
