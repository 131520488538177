import React, { useContext, useMemo, useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import CustomerContext from '../../../Context/CustomerContext';
import useFetchPost from '../../../Fetch/useFetchPost';
import ReactSelect from 'react-select';
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Spinner from 'react-bootstrap/Spinner'
import { BsBugFill, BsDownload, BsQuestionCircle } from 'react-icons/bs'
import { createCustomRangeChart, HandleGroupByDropdownChange, buildGroupByDropdownOptions, customChartThemes, chartToolPanelsDef } from '../GraphGridTools'
import DepartmentDropdown from './Dropdowns/DepartmentDropdown';
import OrderTypesDropdown from './Dropdowns/OrderTypesDropdown';
import TooltipPopup from './TooltipPopup';

const TabTemplate = ({ 
  dates, 
  startDateIndex, 
  endDateIndex, 
  dateType,
  apiUrl,
  rangeChartSeries,
  rangeChartID,
  columnDefs,
  chartTitle,
  dataTab,
  chartType,
  seriesChartTypes,
  aggFunc,
  customThemes,
  reportDescription
}) => {
  const {cookies} = useContext(CustomerContext)
  const [groupByOptions, setGroupByOptions] = useState();
  const [orderTypeOptions, setOrderTypeOptions] = useState([]);
  const [vendorOptions, setVendorOptions] = useState([]);
  const [worksiteOptions, setWorksiteOptions] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState();
  const [selectedWorksites, setSelectedWorksites] = useState();
  const [selectedOrderTypes, setSelectedOrderTypes] = useState();
  const [selectedVendors, setSelectedVendors] = useState();
  const [selectedBranches] = useState([(cookies.get('customer').runAsCustomer?cookies.get('customer').runAsCustomer.CustomerId:cookies.get('customer').CustomerID)])
  const [subTitle, setSubTitle] = useState();
  const [gridApi, setGridApi] = useState();
  const [startDate, setStartDate] = useState(dates[startDateIndex]);
  const [endDate, setEndDate] = useState(dates[endDateIndex]);
  const [postObj, setPostObj] = useState()
  const [showFilters, setShowFilters] = useState(false);
  const [tooltipPopupOpen, setTooltipPopupOpen] = useState(false);
  const { data: weeklyData, loading } = useFetchPost(((postObj&&(selectedDepartments||selectedWorksites))&&apiUrl), null, postObj)
  const [chartThemes] = useState(['myCustomTheme', 'ag-vivid'])
  const defaultColDef = useMemo(() => {
      return {
        flex: 1,
        editable: true,
        sortable: true,
        filter: 'agMultiColumnFilter',
        floatingFilter: true,
        resizable: true,
      };
    }, []);

    useEffect(()=>{
      if(dates&&selectedDepartments){
        let baseSubTitleString = (`Date Range: ${dates[startDateIndex].value.split('T')[0]} - ${dates[endDateIndex].value.split('T')[0]} | Department: ${selectedDepartments.value.DepartmentName}`)
        if(selectedWorksites&&(selectedWorksites.length>0)){
          let siteNames = selectedWorksites.map(site=>site.value.AddressDesc)
          siteNames.join(' ')
          baseSubTitleString += (' | Worksites: '+siteNames)
        }
        if(selectedOrderTypes&&(selectedOrderTypes.length>0)){
          let orderNames = selectedOrderTypes.map(site=>site.value)
          orderNames.join(' ')
          baseSubTitleString += (' | Order Types: '+orderNames)
        }
        setSubTitle(baseSubTitleString)
        if(!postObj){
          setPostObj({
            StartDate:dates[startDateIndex].value,
            EndDate:dates[endDateIndex].value,
            DateType: dateType,
            CustomerId: selectedDepartments?[selectedDepartments.value.CustomerID]:[],
            AddrId: selectedWorksites?selectedWorksites.map(obj=>obj.value.AddrId):[],
          })
        }
      }
    // eslint-disable-next-line
    },[dates,selectedDepartments])

    const buildFilterOptions = (params, filterInstance) => { 
      // grab all the columns from the grid
      // pull out the ones that have the chart type of category
      let siteValues = params.api.getFilterInstance(filterInstance).getFilterValues().map(site=>{return{value:site.trim(),label:site}})
      return siteValues
    }

    const setSelectedItems = (params, instanceName, defaultArray) => {
      let instance = params.api.getFilterInstance(instanceName)
      if(instance.getModel()){
          let selectedOptionsFromGrid = instance.getModel().values
          let formattedOptions = selectedOptionsFromGrid.map(string=>{return{value:string,label:string}})
          return formattedOptions;
      }else{
          return defaultArray
      } 
    }

    const onFirstDataRendered = (params) => {
      createCustomRangeChart(params.api, rangeChartSeries,`#${rangeChartID}`, chartTitle, subTitle, chartType, seriesChartTypes, aggFunc)
      setGroupByOptions(buildGroupByDropdownOptions(params))

      let orderTypeOptions = buildFilterOptions(params, 'OrderType')
      setOrderTypeOptions(orderTypeOptions)
      setSelectedOrderTypes(orderTypeOptions)

      let vendorOptions = buildFilterOptions(params, 'VendorName')
      setVendorOptions(vendorOptions)
      setSelectedVendors(vendorOptions)

      let worksiteOptions = buildFilterOptions(params, 'Worksite')
      setWorksiteOptions(worksiteOptions)
      setSelectedWorksites(worksiteOptions)

      setGridApi(params.api)
      params.columnApi.autoSizeAllColumns(false);
    }

    
    const HandleDateChange = (e, dateType) => { 
      if(postObj){
        if(dateType==='start'){
          setStartDate(e)
          let newPostObj = {...postObj}
          newPostObj.StartDate = e.value;
          setPostObj(newPostObj)
        }else{
          setEndDate(e)
          let newPostObj = {...postObj}
          newPostObj.EndDate = e.value;
          setPostObj(newPostObj)
        }
      }
     }

    
    const HandleSelectChange = (e,selectName) => { 
        if(selectName==='department'){
          if(postObj){
            let newPostObj = {...postObj}
            newPostObj.CustomerId = [e.value.CustomerID]
            setPostObj(newPostObj)
          }
          setSelectedDepartments(e)

        }else if(selectName==='worksite'){
          setSelectedWorksites(e)
          updateFilterModel(e,'Worksite')

        }else if(selectName==='ordertype'){
          setSelectedOrderTypes(e)
          updateFilterModel(e,'OrderType')

        }else if(selectName==='vendor'){
          setSelectedVendors(e)
          updateFilterModel(e,'VendorName')
        }
     }

     const updateFilterModel = (event, filterInstance) => {
      let newModel = event.map(source=>source.label)
      let sourceInstance = gridApi.getFilterInstance(filterInstance)
      sourceInstance.setModel({values: newModel})
      sourceInstance.applyModel()
      gridApi.onFilterChanged();
     }

     const onBtnExport = () => {
        gridApi.exportDataAsCsv();
    };

    const onDisplayedColumnsChanged = (params) => { 
      params.columnApi.autoSizeAllColumns();
     }

     
    const onFilterChanged = (params) => {
      setSelectedOrderTypes(setSelectedItems(params, 'OrderType', orderTypeOptions))
      setSelectedVendors(setSelectedItems(params,'VendorName',vendorOptions))
      setSelectedWorksites(setSelectedItems(params,'Worksite',worksiteOptions))
    }

    return (
      <>
      {tooltipPopupOpen&&<TooltipPopup show={tooltipPopupOpen} setShow={setTooltipPopupOpen} reportTitle={chartTitle} reportDescription={reportDescription} />}
      <div>
          <div>
            <Row>
              <Col>
              {loading&&
              <Alert>Loading data <Spinner animation='border' size='sm' /></Alert>
              }
              </Col>
              <Col sm={3}>
                  <ButtonGroup className='full-width'>
                    <Button title='Show all filters related to chart' variant="dark" onClick={()=>setShowFilters(!showFilters)}>
                      {showFilters?'Hide Filters':'Show Filters'}
                    </Button>
                  {weeklyData&&
                    <Button variant="dark" onClick={()=>onBtnExport()} title='Download grid data'><BsDownload /></Button>
                  }
                    <Button variant="dark" title='What is this chart?' onClick={()=>setTooltipPopupOpen(true)}><BsQuestionCircle/></Button>
                    <Button variant="dark" title='Report a bug' onClick={(e) => {
                      window.location.href = `mailto:${'Bug Reporting - DevMo! <01bd0080.employnet.com@amer.teams.ms>'}` 
                      e.preventDefault()
                      }}><BsBugFill /></Button>
                  </ButtonGroup>
              </Col>
            </Row>
            <Row className={showFilters?'show':'hide'}>
                <Col sm={3}>
                    <Form.Label >Date Range From:</Form.Label>
                    <ReactSelect disabled={loading} options={dates} value={startDate} onChange={(e)=>HandleDateChange(e,'start')} />
                </Col>
                <Col sm={3}>
                    <Form.Label>To:</Form.Label>
                    <ReactSelect disabled={loading} options={dates} value={endDate} onChange={(e)=>HandleDateChange(e,'end')} />
                </Col>
                <Col sm={6}>
                    <Form.Label>Department:</Form.Label>
                    <DepartmentDropdown isMulti={false} selectedDepartments={selectedDepartments} onChange={(e)=>HandleSelectChange(e,'department')} branchIDs={selectedBranches}/>
                </Col>
              </Row>
              <Row className={showFilters?'show':'hide'}>
                <Col>
                    <Form.Label>Work Sites:</Form.Label>
                    <ReactSelect isMulti value={selectedWorksites} options={worksiteOptions} onChange={(e)=>HandleSelectChange(e,'worksite')} />
                </Col>
                <Col>
                    <Form.Label>Order Type:</Form.Label>
                    <OrderTypesDropdown isMulti={true} selectedOrderTypes={selectedOrderTypes} orderTypeOptions={orderTypeOptions} onChange={(e)=>HandleSelectChange(e,'ordertype')} />
                </Col>
                <Col>
                    <Form.Label>Vendor:</Form.Label>
                    <ReactSelect isMulti={true} value={selectedVendors} options={vendorOptions} onChange={(e)=>HandleSelectChange(e,'vendor')} />
                </Col>
                <Col>
                    <Form.Label>Group By:</Form.Label>
                    {groupByOptions?
                    <ReactSelect defaultValue={groupByOptions[0]} options={groupByOptions} onChange={(e)=>HandleGroupByDropdownChange(e, gridApi)} />
                    :
                    <ReactSelect options={[]} />
                    }
                </Col>


            </Row>
          </div>
        <>
        {(selectedDepartments||selectedWorksites)?
          <>
          <div id={rangeChartID} className="chart"></div>
          <div className="ag-theme-alpine grid">
            {dataTab?
            <AgGridReact
              multiSortKey={'ctrl'}
              rowData={weeklyData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              chartThemes={chartThemes}
              customChartThemes={customThemes?customThemes:customChartThemes}
              enableCharts={true}
              enableRangeSelection={true}
              suppressAggFuncInHeader={true}
              onFirstDataRendered={onFirstDataRendered}
              chartToolPanelsDef={chartToolPanelsDef}
              sideBar={'columns'}
              groupDisplayType={'singleColumn'}
              onColumnRowGroupChanged={onDisplayedColumnsChanged}
              onFilterChanged={onFilterChanged}
              ></AgGridReact>
            :
            <AgGridReact
                multiSortKey={'ctrl'}
                rowData={weeklyData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                chartThemes={chartThemes}
                customChartThemes={customThemes?customThemes:customChartThemes}
                enableCharts={true}
                enableRangeSelection={true}
                suppressAggFuncInHeader={true}
                onFirstDataRendered={onFirstDataRendered}
                chartToolPanelsDef={chartToolPanelsDef}
                onFilterChanged={onFilterChanged}
              ></AgGridReact>
            }
            </div>
          </>
        :
        <>
          {cookies.get('customer').runAsCustomer?
          <Alert className='text-center'>
            <Alert.Heading>Please select a Department or WorkSite</Alert.Heading>
          </Alert>
          :
          <>
          <Alert className='text-center'>
            <Alert.Heading>You must impersonate a customer in order to see these reports</Alert.Heading>
            <p>In order to access the impersonation menu, please click the dropdown next to your name in the header</p>
          </Alert>
          </>
          }
        </>
        }
        </>
      </div>
      </>
    );
}

export default TabTemplate;