import React, { useContext, useState, useEffect } from 'react'
import CustomerContext from '../../Context/CustomerContext'
import useFetchGet from '../../Fetch/useFetchGet';
import FTE from './FTE'

function FTELogic() {
    const { setCurrentPage} = useContext(CustomerContext);
    const {REACT_APP_API_URL} = process.env;
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [pinnedBottomRowData, setPinnedBottomRowData] = useState();
    const [gridApi, setGridApi] = useState();
    const [defaultColDef] = useState({
        resizable: true,
        sortable: true,
        filter: true
      });
    const [columnDefs] = useState([
    {headerName:'Customer',field:'CustomerName'},
    {headerName:'Department',field:'DepartmentName'},
    {field:'WeekendDate',valueFormatter: (date)=>{return TransformDate(date)}, filter: 'agDateColumnFilter'},
    {field:'EmployeeCount', filter: 'agNumberColumnFilter'},
    {field:'FTECount', filter: 'agNumberColumnFilter', valueFormatter: (count)=>{return MakeDouble(count)}, cellClass: 'align-right'},
    {field:'TotalHours', filter: 'agNumberColumnFilter', valueFormatter: (count)=>{return MakeDouble(count)}, cellClass: 'align-right'},
    {field:'RegHours', filter: 'agNumberColumnFilter', valueFormatter: (count)=>{return MakeDouble(count)}, cellClass: 'align-right'},
    {field:'OTHours', filter: 'agNumberColumnFilter', valueFormatter: (count)=>{return MakeDouble(count)}, cellClass: 'align-right'},
    {field:'DTHours', filter: 'agNumberColumnFilter', valueFormatter: (count)=>{return MakeDouble(count)}, cellClass: 'align-right'},
    ])
    const { data } = useFetchGet(((startDate&&endDate)&&`${REACT_APP_API_URL}/Reports/GetFTECount`),{startDate: (startDate&&startDate.value.TheDate), endDate: (endDate&&endDate.value.TheDate)})
    const { data: sundays } = useFetchGet(`${REACT_APP_API_URL}/Reports/Getsundays`,{weeks:52})

    useEffect(()=>{
      if(sundays){
        setStartDate({value:sundays[0], label:sundays[0].DateValue})
        setEndDate({value:sundays[0], label:sundays[0].DateValue})
      }
    // eslint-disable-next-line
    },[sundays])

    function TransformDate(date){
      if(date.value){
          return new Date(date.value).toLocaleDateString();
      }else{
          return null
      }
  }

    const MakeDouble = (int) => { 
      if(int.value||int.value===0){
        return Number.parseFloat(int.value).toFixed(2);
      }else{
        return ''
      }
     }

    useEffect(()=>{
        if(data){
          setPinnedBottomRowData([{
            TotalHours: `${Reduce(data, 'TotalHours')}`,
            RegHours: `${Reduce(data, 'RegHours')}`,
            OTHours: `${Reduce(data, 'OTHours')}`,
            DTHours: `${Reduce(data, 'DTHours')}`,
            FTECount: `${totalFTE(data, 'FTECount')}`,
            EmployeeCount: `${data.reduce((last,current)=>last+current.EmployeeCount,0)}`
          }])
        }
    // eslint-disable-next-line
    },[data])

    useEffect(()=>{
      setCurrentPage('FTE')
    },[setCurrentPage])

    const totalFTE = (data, dataName) => {
      if(startDate&&endDate){
        let weeks = (sundays.indexOf(startDate.value) - sundays.indexOf(endDate.value) + 1)
        let total = (data.reduce((last,current)=>last+current[dataName],0)/weeks).toFixed(2)
        return total;
      }else{
        let total = data.reduce((last,current)=>last+current[dataName],0).toFixed(2)
        return total;
      }
    }

    const Reduce = (data, dataName) => { 
       let total = data.reduce((last,current)=>last+current[dataName],0).toFixed(2)
       return total
     }

    const onGridReady = (params) => { 
        setGridApi(params.api)
        params.columnApi.autoSizeAllColumns();
    }

    const onFilterChanged = (e) => { 
        let data = [];
        gridApi.forEachNodeAfterFilter(node=>{
           data.push(node.data);
        })
        setPinnedBottomRowData([{
          TotalHours: `${Reduce(data, 'TotalHours')}`,
          RegHours: `${Reduce(data, 'RegHours')}`,
          OTHours: `${Reduce(data, 'OTHours')}`,
          DTHours: `${Reduce(data, 'DTHours')}`,
          FTECount: `${totalFTE(data, 'FTECount')}`,
          EmployeeCount: `${data.reduce((last,current)=>last+current.EmployeeCount,0)}`
        }])
    }

    const onBtnExport = () => {
      gridApi.exportDataAsCsv();
  };

  return (
    <FTE
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        data={data}
        onGridReady={onGridReady}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        pinnedBottomRowData={pinnedBottomRowData}
        onFilterChanged={onFilterChanged}
        startDate={startDate}
        endDate={endDate}
        sundays={sundays}
        onBtnExport={onBtnExport}
    ></FTE>
  )
}

export default FTELogic