import React, { useState, useEffect } from 'react'
import CandidateCard from './CandidateCard/CandidateCard'
import useFetchGet from '../../../Fetch/useFetchGet';

function Candidates({ candidates }){
    const [statusOptions, setStatusOptions] = useState();
    const { REACT_APP_API_URL } = process.env;
    const { data: unformattedStatusOptions } = useFetchGet(`${REACT_APP_API_URL}/VMS/GetCandidateStatus`);

    useEffect(()=>{
      if(unformattedStatusOptions){
        let formattedStatusOptions = unformattedStatusOptions.map(obj=>{return{value:obj,label:obj.status+" : "+obj.Description}})
        formattedStatusOptions = formattedStatusOptions.sort((a, b) => (a.label > b.label) ? 1 : -1)
        setStatusOptions(formattedStatusOptions)
      }
    // eslint-disable-next-line
    },[unformattedStatusOptions])

  return (
    <div className='vh-40'>
      {candidates.map(candidate=>{
        return <CandidateCard candidate={candidate} statusOptions={statusOptions} />
      })}
    </div>
  )
}

export default Candidates;