import React, { useState, useEffect } from 'react'
import ReactSelect from 'react-select';
import useFetchGet from '../../../../Fetch/useFetchGet';
import Spinner from 'react-bootstrap/Spinner'

function OrderTypesDropdown({ onChange, selectedOrderTypes, isMulti, orderTypeOptions }) {
    const [types, setTypes] = useState();
    const { REACT_APP_API_URL } = process.env
    const { data: unformattedTypes } = useFetchGet((!orderTypeOptions&&`${REACT_APP_API_URL}/Reports/GetOrderTypes`))

    useEffect(()=>{
      if(orderTypeOptions){
        setTypes(orderTypeOptions)
      }
    },[orderTypeOptions])

    useEffect(()=>{
        if(unformattedTypes){
            let formattedTypes = unformattedTypes.map(type=>{
                return {value: type, label: type.Description}
            })
            setTypes(formattedTypes)
        }
    },[unformattedTypes])
  return (
    <>
    {types?
    <ReactSelect isMulti={isMulti} options={types} value={selectedOrderTypes} onChange={(e)=>onChange(e)} />
    :
    <Spinner animation='border' size='sm' />
    }
    </>
  )
}

export default OrderTypesDropdown