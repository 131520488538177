import React, { useState, useEffect } from 'react'
import ReactSelect from 'react-select';
import Spinner from 'react-bootstrap/Spinner'
import Alert from 'react-bootstrap/Alert';
import useFetchPost from '../../../../../../Fetch/useFetchPost'
import './CustomerTreeDropdown.css'

function CustomerTreeDropdown({ 
  customerID,
  onChange, 
  selectedDepartments, 
  isMulti,
  onMenuOpen,
  defaultValue,
  closeMenuOnSelect
}) {
  const { REACT_APP_API_URL } = process.env
  const { data: unformattedDepartments, status, loading } = useFetchPost((customerID&&`${REACT_APP_API_URL}/Utilities/GetCustomersDepartments`), {CustomerID:customerID})
  const [departments, setDepartments] = useState();

    useEffect(()=>{
        if(unformattedDepartments&&(status===200)){
          // adding flag to stop the overriting of defaults
          // the cookies default is the most important, if no cookie
          // then we want the department from the login audit
          let formattedDepartments = [];
          buildDropdown(unformattedDepartments, 0, formattedDepartments)
          setDepartments(formattedDepartments)
        }else if(status===204){
          setDepartments([])
        }
    // eslint-disable-next-line
    },[unformattedDepartments])

    function buildDropdown(data, depth, optionsArray){
      if(data.CustomerID===0){
        data.customerTrees.forEach(cust=>{
          buildDropdown(cust, depth+1, optionsArray)
        })
      }else{
        let option;
        if(depth===1){
          option = {value: data, label:data.DepartmentName+' | '+data.CustomerName}
          option.value.padding = depth*10;
        }else{
          option = {value: data, label:'  ⤷  '+data.DepartmentName+' | '+data.CustomerName}
          option.value.padding = depth*20;
        }
        optionsArray.push(option)
        data.customerTrees.forEach(cust=>{
          buildDropdown(cust, depth+1, optionsArray)
        })
      }
      return;
    }

   const CustomOption = ({ innerProps, isDisabled, label, value }) =>
  !isDisabled ? (
    <div {...innerProps}  className='custom-dropdown-department-option'><p style={{paddingLeft:`${value.padding}px`}}>{label}</p></div>
  ) : null;

  return (
    <>
    {(departments&&!loading)?
    <ReactSelect closeMenuOnSelect={(closeMenuOnSelect===false)?false:true} onMenuOpen={onMenuOpen} components={{Option: CustomOption}} isMulti={isMulti} options={departments} defaultValue={defaultValue} value={selectedDepartments} onChange={(e)=>onChange(e)} />
    :
    (status>=400)?
    <Alert variant='warning'>Error, please try again</Alert>
    :
    <Spinner animation='border' size='sm' />
    }
    </>
  )
}

export default CustomerTreeDropdown