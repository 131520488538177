import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import '../../Styles/NavMenu.css';
import { CustomerContext } from '../Context/CustomerContext';
import Header from './Header'
import useFetchGet from '../Fetch/useFetchGet'
import ImpersonatePopup from './ImpersonatePopup/ImpersonatePopup';

export function NavMenu () {
  const {
    cookies,
    currentPage
  } = useContext(CustomerContext)
  const { REACT_APP_API_URL } = process.env;
  const navigate = useNavigate();
  const { data: menuOptions } = useFetchGet((cookies.get('customer')&&`${REACT_APP_API_URL}/Utilities/getmenu`),{'AppName':'Reports'})
  const [impersonatePopupIsOpen, setImpersonatePopupIsOpen] = useState(false);

  const StopImpersonating = () => { 
    let newAudit = cookies.get('customer')
    newAudit.runAsCustomer = null;
    cookies.set('customer',newAudit, {path:'/'})
    window.location.reload()
   }

  function Logout(){
    RemoveAuth().then(()=>{
      navigate('/login')
    })
  }

  const RemoveAuth = async function(){
    cookies.remove('customer', {path:'/'}, { path:'/' })
    return;
  }

    return (
      <>
      {impersonatePopupIsOpen&&<ImpersonatePopup show={impersonatePopupIsOpen} setShow={setImpersonatePopupIsOpen} />}
      <Header StopImpersonating={StopImpersonating} OpenImpersonatePopup={()=>setImpersonatePopupIsOpen(true)} appName='Customer Portal' currentPage={currentPage} loggedInUser={cookies.get('customer')} HandleLogout={Logout} MenuOptions={menuOptions&&menuOptions.Tree}  />
      </>
    )
}

export default NavMenu;