import React, { useContext, useEffect, useState } from 'react'
import CustomerContext from '../../Context/CustomerContext'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import '../CustomComponents/Tabs/Tabs.css'
import MonthlyTab from '../CustomComponents/Tabs/MonthlyTab'
import DataTab from '../CustomComponents/Tabs/DataTab'

function AttritionDashboard() {
    const { REACT_APP_API_URL } = process.env;
    const { setCurrentPage } = useContext(CustomerContext)
    const [ selectedTab, setSelectedTab ] = useState('Monthly')
    const [ tabApiUrl ] = useState(`${REACT_APP_API_URL}/Reports/GetCustomerAttrition`);
    const [ tabRangeChartSeries ] = useState(['DateLabel','Headcount','Ends','Attrition'])
    const [chartType] = useState('columnLineCombo')
    const [seriesChartTypes] = useState([
       { colId: 'Headcount', chartType: 'groupedColumn', secondaryAxis: false },
       { colId: 'Ends', chartType: 'groupedColumn', secondaryAxis: false },
       { colId: 'Attrition', chartType: 'line', secondaryAxis: true }
     ])
     const [reportDescription] = useState(<div><p>This report is displayed as a grouped column with a line overlay.</p><p> The left or 'y' axis displays the employees active, and those who have ended their assignments. The bottom or 'x' axis is the date range selected. The right or 'secondary y' axis displays the attrition percentage, indicated in the chart by the blue line.</p><p> This report shows the attrition percentage for each month. Attrition is calculated by taking the total number of ends in a month and dividing by the average weekly headcount from that month. This provides an estimated percentage of the total workforce that has been replaced over the course of the month.</p></div>)
    
    const roundedTotal = (params) =>{
        let total = 0;
        params.values.forEach(value => total += value);
        total = total.toFixed(2)
        return Number(total);
    }

    const customThemes = {
        myCustomTheme: {
            baseTheme: 'ag-pastel',
            palette: {
              fills: ['#9dc3e6', '#ed7d31', '#4472c4'],
              strokes: ['#7b7b7b'],
            },
            overrides: {
                common: {
                    title: {
                        fontSize: 22,
                        fontFamily: 'Arial, sans-serif'
                    },
                    axes: {
                        number: {
                            left: {
                                label: {
                                    format: 'c'
                                }
                            },
                            right: {
                                label: {
                                    format: '%'
                                }
                            }
                        }
                    }
                }
            }
        }
    };


    const [columnDefs] = useState([
        { field: 'DateLabel', chartDataType:'category' ,headerName:'Date', rowGroup:true },
        { field: 'Headcount',chartDataType:'series', aggFunc: roundedTotal},
        { field: 'Ends',chartDataType:'series', aggFunc: roundedTotal},
        { field: 'Attrition',chartDataType:'series', aggFunc: roundedTotal, valueFormatter: (params)=> ((params.value*100).toFixed()+'%')},
        { field: 'EmployeeName', headerName:'Employee'},
        { field: 'EmployeeId', headerName: 'EmployeeID', chartDataType: 'category', filter: 'agSetColumnFilter' },
        { field: 'AlternateID', chartDataType: 'category', filter: 'agSetColumnFilter' },
        { field: 'Status', chartDataType: 'category'},
        { field: 'Shift', chartDataType: 'category' },
        { field: 'VendorName', filter: 'agSetColumnFilter', chartDataType: 'category'},
        { field: 'Worksite',filter: 'agSetColumnFilter', chartDataType: 'category',},
        { field: 'Department',filter: 'agSetColumnFilter', chartDataType: 'category',},
        { field: 'OrderType',filter: 'agSetColumnFilter',chartDataType:'category'},
        { field: 'Supervisor',chartDataType:'category'},
    ])

    useEffect(()=>{
        setCurrentPage('Attrition')
    },[setCurrentPage])

  return (
    <div>
        <Tabs
            activeKey={selectedTab}
            onSelect={(tab)=>setSelectedTab(tab)}
        >
        <Tab eventKey="Monthly" title="Monthly">
            {selectedTab===('Monthly')&&
                <MonthlyTab customThemes={customThemes} seriesChartTypes={seriesChartTypes} chartType={chartType} columnDefs={columnDefs} apiUrl={tabApiUrl} rangeChartSeries={tabRangeChartSeries} chartTitle='Monthly Attrition' chartSubTitle='example subtitle' reportDescription={reportDescription} />
            }
        </Tab>
        <Tab eventKey="Data" title="Data">
            {selectedTab==='Data'&&
                <DataTab columnDefs={columnDefs} apiUrl={tabApiUrl} chartTitle={'Attrition'} reportDescription={reportDescription} />
            }
        </Tab>
        </Tabs>
    </div>
  )
}

export default AttritionDashboard