import React from 'react'
import { AgGridReact } from 'ag-grid-react'
import {Button, Form} from 'react-bootstrap'
import { BsDownload, BsBugFill } from "react-icons/bs";
import ButtonGroup from 'react-bootstrap/ButtonGroup'

function TimeclockPunches({ onFilterChanged, pinnedBottomRowData, rowClassRules, date, setDate, defaultColDef, rowData, columnDefs, onGridReady, onBtnExport }) {
  return (
    <div>   
      <div className='hours-worked-div'>
        <div className='hours-worked-date'>
          <Form.Control type='date' defaultValue={date} onChange={(e)=>setDate(e.target.value)} />
        </div>
        <div className='grid-download'>
        <ButtonGroup>
            <Button variant='dark' onClick={() => onBtnExport()}><BsDownload/></Button>
            <Button variant="dark" title='Report a bug' onClick={(e) => {
              window.location.href = `mailto:${'Bug Reporting - DevMo! <01bd0080.employnet.com@amer.teams.ms>'}` 
              e.preventDefault()
            }}><BsBugFill /></Button>
          </ButtonGroup>
        </div>   
      </div>
      <div id="myGrid" className="ag-theme-alpine grid filter-grid">
          <AgGridReact
              rowClassRules={rowClassRules}
              multiSortKey={'ctrl'}
              suppressExcelExport={true}
              popupParent={document.body}
              defaultColDef={defaultColDef}
              rowData={rowData}
              columnDefs={columnDefs}
              onGridReady={onGridReady}
              onFilterChanged={onFilterChanged}
              pinnedBottomRowData={pinnedBottomRowData}
          ></AgGridReact>
      </div>
</div>
  )
}

export default TimeclockPunches